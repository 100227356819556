export const sortByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.id.localeCompare(b.id));
  } else {
    return data.sort((a, b) => b.id.localeCompare(a.id));
  }
};

export const sortByAmount = (data, order) => {
  const sumValues = (obj) => {
    const { id, ...rest } = obj;
    return Object.values(rest).reduce((a, b) => a + b, 0);
  };

  if (order) {
    return data.sort((a, b) => sumValues(a) - sumValues(b));
  } else {
    return data.sort((a, b) => sumValues(b) - sumValues(a));
  }
};

export const colorsArray = [
  "#690277",
  "#188bf6",
  "#a1d1ff",
  "#7450ba",
  "#a0a6d8",
  "#191970",
  "#2f4f4f",
  "#800000",
  "#696969",
  "#00ffff",
  "#4b0082",
  "red",
  "yellow",
  "green",
  "black",
  "orange",
];

export const sortActivitiesSalesRep = (salesRep) =>
  salesRep.sort((a, b) => (a.username || "").localeCompare(b.username || ""));

export const sortActivityTypes = (data) =>
  data.sort((a, b) =>
    (a.activity_type || "").localeCompare(b.activity_type || "")
  );

import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import SalesActualVsTarget from "./SalesActualVsTarget";
import ClientGraph from "./SalesRepByClient";
import { ReactComponent as SortAlphabetically } from "../../assets/sortAlphabetically.svg";
import { ReactComponent as SortByAmount } from "../../assets/sortByAmount.svg";
import TotalSalesGraph from "./totalSalesGraph";
import TotalActualVsTargetGraph from "./TotalActualVsTargetGraph";
import {
  sortClientDataByAlpha,
  sortClientDataByAmount,
  sortSalesRepDataByAlpha,
  sortSalesRepDataByAmount,
} from "./utils";
import { useSelector } from "react-redux";
import SpinnerComponent from "../../components/spinner";

const SalesReporting = () => {
  const [salesByClientGraph, setSalesByClientGraph] = useState(true);
  const [salesData, setSalesData] = useState([]);
  const [salesRepData, setSalesRepData] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    clientAscAplha: true,
    clientAscAmount: false,
    salesRepAlpha: true,
    salesRepAmount: false,
  });

  const { graphData, fetchLoading } = useSelector(
    (state) => state.salesGraphReducer
  );

  useEffect(() => {
    if (graphData.client_sales) {
      setSalesData(graphData.client_sales);
      setSalesRepData(graphData.sales_rep);
    }
  }, [graphData]);

  const handleSortClientByAlpha = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      clientAscAplha: !prev.clientAscAplha,
    }));
    let res = sortClientDataByAlpha(salesData, sortOrder.clientAscAplha);
    setSalesData(res);
  };

  const handleSortClientByAmount = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      clientAscAmount: !prev.clientAscAmount,
    }));
    let res = sortClientDataByAmount(salesData, sortOrder.clientAscAmount);
    setSalesData(res);
  };

  const handleSortSalesRepByAlpha = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      salesRepAlpha: !prev.salesRepAlpha,
    }));
    let res = sortSalesRepDataByAlpha(salesRepData, sortOrder.salesRepAlpha);
    setSalesRepData(res);
  };

  const handleSortSalesRepByAmount = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      salesRepAmount: !prev.salesRepAmount,
    }));
    let res = sortSalesRepDataByAmount(salesRepData, sortOrder.salesRepAmount);
    setSalesRepData(res);
  };

  return (
    <Row className="sales-manager-parent-row">
      <Col span={24} className="heading-col">
        <Row>
          <Col xl={4} lg={6} md={6}>
            <span className="heading-span">Sales Reporting</span>
          </Col>
          <Col xl={20} lg={16} md={18}>
            <Button
              onClick={() => setSalesByClientGraph(true)}
              className={`custom-btn1 client-btn ${
                salesByClientGraph && "selected"
              }`}
            >
              By Client
            </Button>
            <Button
              onClick={() => setSalesByClientGraph(false)}
              className={`custom-btn1 sales-rep-btn ${
                !salesByClientGraph && "selected"
              }`}
            >
              By Sales Rep
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="graph-section">
        {fetchLoading ? (
          <div className="spinner-div">
            <SpinnerComponent fontSize={50} />
          </div>
        ) : salesByClientGraph ? (
          <Row gutter={[60, 60]}>
            <Col md={24} lg={24} xl={18} xxl={18}>
              <div className="sales-by-client-div">
                <span className="graph-text-span">Sales By Client</span>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title="Sort by Alphabetically"
                >
                  <SortAlphabetically
                    className="sort-icons"
                    onClick={handleSortClientByAlpha}
                  />
                </Tooltip>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Amount`}
                >
                  <SortByAmount
                    className="sort-icons"
                    onClick={handleSortClientByAmount}
                  />
                </Tooltip>
              </div>
              <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
                <ClientGraph data={salesData} />
              </div>
            </Col>
            <Col md={16} lg={12} xl={6} xxl={5}>
              <div style={{ textAlign: "center" }}>
                <span className="graph-text-span">Total Sales</span>
              </div>
              <TotalSalesGraph data={graphData.total_sales} />
            </Col>
          </Row>
        ) : (
          <Row gutter={[60, 60]}>
            <Col md={24} lg={24} xl={16} xxl={18}>
              <div className="sales-by-client-div">
                <span className="graph-text-span">
                  Actual vs Target By Sales Rep
                </span>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Alphabetically`}
                >
                  <SortAlphabetically
                    className="sort-icons"
                    onClick={handleSortSalesRepByAlpha}
                  />
                </Tooltip>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Amount`}
                >
                  <SortByAmount
                    className="sort-icons"
                    onClick={handleSortSalesRepByAmount}
                  />
                </Tooltip>
              </div>
              <div className="graph-label-with-text">
                <div className="actual-div">
                  <span className="blue-dot"></span>
                  Actual
                </div>
                <div className="target-div">
                  <span className="red-dot"></span>Target
                </div>
              </div>
              <SalesActualVsTarget data={salesRepData} />
            </Col>
            <Col md={24} lg={16} xl={8} xxl={6}>
              <span className="graph-text-span">Total Actual vs Target</span>
              <div
                className="graph-label-with-text"
                style={{
                  gap: "10px",
                }}
              >
                <div className="actual-div">
                  <span className="blue-dot"></span>
                  Total Actual Sales
                </div>
                <div className="target-div">
                  <span className="red-dot"></span>
                  Total Target Sales
                </div>
              </div>
              <TotalActualVsTargetGraph data={graphData.totalActualVsTarget} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SalesReporting;

import React from "react";
import { Row, Col } from "antd";
import SalesManagerTable from "./SalesManagerTable";
import GetSyncData from "../../components/getSyncDataComponent";

const SalesManager = () => {
  return (
    <Row className="sales-manager-parent-row">
      <Col span={24} className="heading-col">
        <Row justify="space-between" align="middle">
          <Col span={16}>
            <Row align="middle" gutter={[50, 0]}>
              <Col>
                <div>
                  <span className="heading-span">Sales Register</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <GetSyncData />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <SalesManagerTable />
      </Col>
    </Row>
  );
};

export default SalesManager;

import React, { useEffect, useState } from "react";
import { Col, Row, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateClientSettings } from "../redux/actions";

const SettingsSection = () => {
  const [data, setData] = useState({
    popup_notifications: false,
    email_notifications: false,
  });
  const dispatch = useDispatch();

  const { currentClientObj, popupLoading, emailLoading } = useSelector(
    (state) => state.clientManagerReducer
  );

  useEffect(() => {
    if (currentClientObj?.id > 0) {
      const { popup_notifications, email_notifications } = currentClientObj;
      setData({
        popup_notifications,
        email_notifications,
      });
    } else
      setData({
        popup_notifications: false,
        email_notifications: false,
      });
  }, [currentClientObj]);

  const onChange = (key, checked, loadingObj) => {
    setData({ ...data, [key]: checked });
    const { id } = currentClientObj;
    dispatch(
      updateClientSettings({
        data: { id, [key]: checked },
        loadingObj,
      })
    );
  };

  return (
    <Row>
      <Col span={24} className="heading-col">
        <Row align="middle">
          <Col>
            <div>
              <span className="heading-span">Settings</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="section-body settings-section">
        <Row>
          <Col md={1} lg={2} xl={2} xxl={4}></Col>
          <Col md={8} lg={8} xl={6} xxl={6}>
            <div className="heading-text">Browser Popup notifications</div>
            <div className="switch-parent-div">
              <span className={`${!data.popup_notifications && "off"}`}>
                Off
              </span>{" "}
              <Switch
                size="small"
                defaultChecked={false}
                disabled={(currentClientObj?.id ? false : true) || popupLoading}
                checked={data.popup_notifications}
                onChange={(e) =>
                  onChange("popup_notifications", e, { popupLoading: true })
                }
              />{" "}
              <span className={`${data.popup_notifications && "on"}`}>On</span>
            </div>
          </Col>
          <Col>
            <div className="heading-text">Email notifications</div>
            <div className={"switch-parent-div"}>
              <span className={`${!data.email_notifications && "off"}`}>
                Off
              </span>{" "}
              <Switch
                size="small"
                defaultChecked={false}
                disabled={(currentClientObj?.id ? false : true) || emailLoading}
                checked={data.email_notifications}
                onChange={(e) =>
                  onChange("email_notifications", e, { emailLoading: true })
                }
              />{" "}
              <span className={`${data.email_notifications && "on"}`}>On</span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SettingsSection;

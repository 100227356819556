import * as constants from "./constants";

const initialState = {
  secondLevelData: [],
  fetchLoading: false,
};

const secondLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROD_SECOND_LEVEL:
      return {
        ...state,
        fetchLoading: true,
        // secondLevelData: [],
      };
    case constants.FETCH_PROD_SECOND_LEVEL_SUCCESS:
      let updated = action.data.map((item) => ({
        ...item,
        id: item.soitemid,
        key: item.soitemid,
      }));
      return {
        ...state,
        fetchLoading: false,
        secondLevelData: updated,
      };
    case constants.FETCH_PROD_SECOND_LEVEL_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };
    case constants.CREATE_SECOND_LEVEL:
      return {
        ...state,
        fetchLoading: true,
      };
    case constants.CREATE_SECOND_LEVEL_SUCCESS:
      const { data } = action;
      const updatedSecondLevel = state.secondLevelData.map((el) =>
        el.soitemid === data.soitemid ? { ...el, ...data } : el
      );
      return {
        ...state,
        fetchLoading: false,
        secondLevelData: updatedSecondLevel,
      };
    case constants.CREATE_SECOND_LEVEL_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };

    case constants.UPDATE_SECOND_LEVEL:
      return {
        ...state,
        fetchLoading: true,
      };
    case constants.UPDATE_SECOND_LEVEL_SUCCESS:
      const updatedData = state.secondLevelData.map((el) =>
        el.soitemid === action.data.soitemid ? { ...el, ...action.data } : el
      );

      return {
        ...state,
        fetchLoading: false,
        secondLevelData: updatedData,
      };
    case constants.UPDATE_SECOND_LEVEL_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };

    default:
      return state;
  }
};

export default secondLevelReducer;

import { Row, Col, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.less";
import { ReactComponent as BackIcon } from "../../../assets/back-icon.svg";
import UserDetailsSection from "./userDetailsSection";
import DepartmentsDetailsSection from "./departmentsSection";
import SettingsSection from "./settingsSection";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, userDetailCleanupFunc } from "../redux/actions";
import UseSupAdminOrAdminWithUserManagerPermission from "../../../components/useSupAdminOrAdminWithUserManagerPermission";

const UserDetails = () => {
  const [isUserUpdate, setIsUserUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isProfileCase, setIsProfileCase] = useState();
  let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();

  const { currentUserObj, fetchUserDetailLoading } = useSelector(
    (state) => state.userManagerReducer
  );
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    setIsProfileCase(pathname === "/profile");
  }, [pathname]);

  useEffect(() => {
    if (currentUserObj?.id) {
      setIsUserUpdate(true);
      setIsProfileCase(currentUserObj.id === user.id);
    }
  }, [currentUserObj]);

  useEffect(() => {
    if (params?.id) {
      setIsUserUpdate(true);
      dispatch(fetchUser(params?.id));
    } else if (pathname === "/profile") {
      setIsUserUpdate(true);
      dispatch(fetchUser(user.id));
    }

    return () => {
      dispatch(userDetailCleanupFunc());
    };
  }, [params.id]);

  return (
    <div className="page-top-div heading-parent-div">
      <Row justify="space-between">
        <Col>
          <span className="text-span">
            {isProfileCase
              ? `User - ${user?.username}`
              : isUserUpdate
              ? "Update User"
              : "Add user"}
          </span>
        </Col>
        {!isProfileCase && (
          <Col>
            <Button
              onClick={() => navigate("/settings/users-manager")}
              icon={<BackIcon width={24} height={14} />}
              className="back-btn"
              size="middle"
            >
              BACK
            </Button>
          </Col>
        )}
      </Row>
      <Spin spinning={fetchUserDetailLoading}>
        <div className="user-manager-parent-div">
          <UserDetailsSection
            isUserUpdate={isUserUpdate}
            isProfileCase={isProfileCase}
            canEditEveryThing={canEditEveryThing}
          />
          {isUserUpdate && (
            <>
              <DepartmentsDetailsSection
                isUserUpdate={isUserUpdate}
                canEditEveryThing={canEditEveryThing}
                isProfileCase={isProfileCase}
              />
              <SettingsSection isUserUpdate={isUserUpdate} />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default UserDetails;

import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchDepartEmails({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/department_emails`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    });
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchDepartEmailSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateDepartEmail({ data }) {
  const { email, id } = data;
  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/department_emails/${id}`,
      {
        email,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDepartEmailSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDepartEmailError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDepartEmailError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

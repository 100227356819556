import React, { useEffect } from "react";
import { Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import Sale from "../Sales";
import Activities from "../Activities";
import Production from "../Production";
import SidebarComponent from "./sidebar";
import UserManager from "../UserManager";
import ClientsManager from "../ClientsManager";
import ActivityTypes from "../ActivityTypes";
import "./index.less";
import DepartmentEmails from "../DepartmentEmails";
import UserDetails from "../UserManager/userDetails";
import ClientDetails from "../ClientsManager/clientDetails";
import UseGetUserRole from "../../components/useGetUserRole";
import axios from "axios";
import { baseURL } from "../../globalUtils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../login/redux/actions";

const LayoutComponent = () => {
  const { Content } = Layout;
  const [isSuperAdmin, isAdmin, , , isCleint] = UseGetUserRole();
  const { user } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/v1/users/check_user_token`
        );
        const { success } = response.data;
        if (success) {
          dispatch(
            actions.loginUserSuccess({
              user: response.data.data,
              navigate,
              shouldNavigate: false,
            })
          );
        }
        if (!success) {
          dispatch(actions.logoutUserSuccess(response.data.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    validateUser();
  }, []);

  return (
    <Layout className="most-parent-layout">
      <Layout className="site-layout">
        <SidebarComponent />
        <Content className="layout-content site-layout-background custom-textarea">
          <Routes>
            {!isCleint && (
              <>
                <Route path="/sales" element={<Sale />} />
                <Route path="/activities" element={<Activities />} />
                <Route path="/production" element={<Production />} />

                <Route path="/profile" element={<UserDetails />} />
                <Route path="/client-profile" element={<ClientDetails />} />

                {(isSuperAdmin ||
                  (isAdmin && user?.permissions?.user_manager)) && (
                  <>
                    <Route
                      path="/settings/users-manager"
                      element={<UserManager />}
                    />
                    <Route
                      path="/settings/users-manager/create-user"
                      element={<UserDetails />}
                    />
                    <Route
                      path="/settings/users-manager/update-user/:id"
                      element={<UserDetails />}
                    />
                  </>
                )}
                {(isSuperAdmin ||
                  (isAdmin && user?.permissions?.client_manager)) && (
                  <>
                    <Route
                      path="/settings/clients-manager"
                      element={<ClientsManager />}
                    />
                    <Route
                      path="/settings/clients-manager/create-client"
                      element={<ClientDetails />}
                    />
                    <Route
                      path="/settings/clients-manager/update-client/:id"
                      element={<ClientDetails />}
                    />
                  </>
                )}
                {(isSuperAdmin ||
                  (isAdmin && user?.permissions?.activity_type)) && (
                  <Route
                    path="/settings/activity-types"
                    element={<ActivityTypes />}
                  />
                )}
                {(isSuperAdmin ||
                  (isAdmin && user?.permissions?.department_emails)) && (
                  <Route
                    path="/settings/department-emails"
                    element={<DepartmentEmails />}
                  />
                )}
              </>
            )}
            <Route path="/client-profile" element={<ClientDetails />} />
            <Route path="/production" element={<Production />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;

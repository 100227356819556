import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

const AddCommentPopup = ({ setFeedback, feedback, handleClose }) => {
  const [text, setText] = useState(feedback);

  const handleSave = () => {
    if (text?.length > 0) {
      setFeedback(text);
      handleClose();
    }
  };

  return (
    <Row justify="center" gutter={[0, 10]}>
      <Col span={24}>
        <TextArea
          rows={3}
          value={text}
          onChange={(e) => setText(e.target.value)}
          className={`${text?.length > 0 ? "valid-text" : "invalid-text"}`}
        />
      </Col>
      <Col>
        <Button
          disabled={text?.length < 1}
          onClick={handleSave}
          className={`${
            text?.length > 0 ? "valid-text-btn" : "invalid-text-btn"
          }`}
        >
          Save
        </Button>
      </Col>
    </Row>
  );
};

export default AddCommentPopup;

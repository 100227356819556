import React, { useState } from "react";
import { Table, Space, Form, Select, Tag, Popover } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReactComponent as CircularTick } from "../../assets/circular-tick-black.svg";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/crossIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createSecondLevel,
  fetchProdThirdLevel,
  fetchProdThirdLevelSuccess,
  updateSecondLevel,
} from "./redux/actions";
import { Option } from "antd/lib/mentions";
import SpinnerComponent from "../../components/spinner";
import ThirdLevelComponent from "./thirdLevel";
import UseGetUserRole from "../../components/useGetUserRole";
import AMultiSelectControlledOutside from "../../components/AMultiSelectControlledOutside";
// import { getOptions } from "../Sales/utils";
import UseClientPermission from "../../components/useClientPermissions";

const SecondLevelComponent = ({ dispatchFun, firstLevelDebounce, soid }) => {
  const [expandedSortedInfoSecondLevel, setExpandedSortedInfoSecondLevel] =
    useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [editingSecondLevelKey, setEditingSecondLevelKey] = useState("");
  const [processCheckedList, setProcessCheckedList] = useState([]);
  const [secondLevelLoading, setSecondLevelLoading] = useState(false);
  const [isSuperAdmin, isAdmin, , , isCleint] = UseGetUserRole();
  const [proceesingStepsOpen, setProceesingStepsOpen] = useState(false);
  const [, , isThirdLevel] = UseClientPermission();

  const dispatch = useDispatch();

  const secondLevelData = useSelector(
    (state) => state.secondLevelReducer.secondLevelData
  );
  const fetchLoading = useSelector(
    (state) => state.secondLevelReducer.fetchLoading
  );
  const formats = useSelector((state) => state.productionReducer.formats);
  const processing_steps = useSelector(
    (state) => state.productionReducer.processing_steps
  );

  const isEditingSecondLevel = (record) => record.id === editingSecondLevelKey;

  const handleExpandOnCloumnClick = (record) => {
    const { id, item_detail } = record;

    if (isCleint && !isThirdLevel) {
      return;
    }

    if (expandedRowKeys.includes(id)) {
      dispatch(fetchProdThirdLevelSuccess({ with_steps: [] }));
      setExpandedRowKeys([]);
    } else {
      if (item_detail === null) {
        return;
      }
      dispatch(fetchProdThirdLevel(record));
      const keys = [];
      keys.push(record.id);
      setExpandedRowKeys(keys);
    }
  };

  const cancel = (record) => {
    // setData((data) => data.filter((el) => el.id !== record.id));
    form.resetFields();
    setEditingSecondLevelKey("");
  };

  const secondLevelColumns = [
    {
      title: "Items description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder:
        expandedSortedInfoSecondLevel?.columnKey === "description" &&
        expandedSortedInfoSecondLevel?.order,
      ellipsis: true,
      width: 575,
      render: (desc, record) => (
        <div
          onClick={() => handleExpandOnCloumnClick(record)}
          style={{ whiteSpace: "normal" }}
        >
          {desc}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          format
        </div>
      ),
      dataIndex: "format_name",
      key: "format_name",
      sorter: (a, b) =>
        a.item_detail?.format_name.localeCompare(b.item_detail?.format_name),
      sortOrder:
        expandedSortedInfoSecondLevel?.columnKey === "format_name" &&
        expandedSortedInfoSecondLevel?.order,
      ellipsis: true,
      editable: true,
      width: 175,
      align: "left",
      render: (_, record) => {
        const { item_detail } = record;
        return (
          <div className="format-div">
            {item_detail?.format_name
              ? item_detail?.format_name
              : !isCleint && <span>Select Format</span>}
          </div>
        );
      },
    },
    ...(isCleint
      ? [
          {
            title: "",
            key: "",
            dataIndex: "",
            align: "center",
            width: 185,
          },
        ]
      : [
          {
            title: "Process steps",
            key: "processing_steps",
            dataIndex: "processing_steps",
            sorter: (a, b) => a.processSteps.localeCompare(b.processSteps),
            sortOrder:
              expandedSortedInfoSecondLevel?.columnKey === "processing_steps" &&
              expandedSortedInfoSecondLevel?.order,
            ellipsis: true,
            align: "center",
            editable: true,
            width: 185,
            render: (_, { item_detail }) => {
              return (
                <div className="format-div">
                  {item_detail ? (
                    <CircularTick width={16} />
                  ) : (
                    <span>Select Process Step</span>
                  )}
                </div>
              );
            },
          },
        ]),
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) =>
        a.item_detail?.status.localeCompare(b.item_detail?.status),
      sortOrder:
        expandedSortedInfoSecondLevel?.columnKey === "status" &&
        expandedSortedInfoSecondLevel?.order,
      ellipsis: true,
      width: 95,
      className: "first-level-status-td",
      render: (_, record) => {
        const { item_detail, qty } = record;
        if (item_detail === null || item_detail?.status === null) {
          return (
            <div onClick={() => handleExpandOnCloumnClick(record)}>
              {item_detail?.status}
            </div>
          );
        }

        const { status } = item_detail;
        // let color;
        // let text;
        // console.log("status", status);
        // switch (status) {
        //   case status?.length > 0:
        //     color = "#29aae180";
        //     text = status;
        //     break;
        //   // case "Delivered":
        //   //   color = "#7cd175";
        //   //   text = "Delivered";
        //   //   break;
        //   default:
        //     break;
        // }
        return (
          status && (
            <Popover
              title={<span>{status}</span>}
              overlayClassName="production-table-popover-custom"
              getPopupContainer={() =>
                document.getElementsByClassName(
                  "ant-layout-content layout-content site-layout-background custom-textarea"
                )[0]
              }
            >
              <Tag
                color={
                  Number(qty || 0) <= Number(item_detail?.completed_qty || 0)
                    ? "#7cd175"
                    : "#94d4f0"
                }
                style={{
                  color: "#383838",
                  marginRight: "0px",
                  width: "70px",
                  fontSize: "11px",
                  padding: "0px",
                  textAlign: "center",
                  overflow: "hidden",
                }}
              >
                {status}
              </Tag>
            </Popover>
          )
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            <div>Qty</div>
            <div>Ordered</div>
          </div>
        );
      },
      dataIndex: "qty",
      key: "qty",
      sorter: (a, b) => a.qty - b.qty,
      sortOrder:
        expandedSortedInfoSecondLevel?.columnKey === "qty" &&
        expandedSortedInfoSecondLevel?.order,
      ellipsis: true,
      width: 90,
      align: "right",
      render: (qty, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>{qty}</div>
      ),
    },
    {
      title: () => {
        return (
          <div>
            <div>Qty</div>
            <div>Completed</div>
          </div>
        );
      },
      dataIndex: "qtyCompleted",
      key: "qtyCompleted",
      sorter: (a, b) =>
        a.item_detail?.completed_qty.localeCompare(
          b.item_detail?.completed_qty
        ),
      sortOrder:
        expandedSortedInfoSecondLevel?.columnKey === "qtyCompleted" &&
        expandedSortedInfoSecondLevel?.order,
      ellipsis: true,
      width: 100,
      align: "right",
      render: (_, record) => {
        const { item_detail } = record;
        return (
          <div onClick={() => handleExpandOnCloumnClick(record)}>
            {item_detail?.completed_qty ? item_detail?.completed_qty : 0}
          </div>
        );
      },
    },
    {
      key: "action",
      width: 190,
      align: "right",
      render: (_, record) => {
        const editable = isEditingSecondLevel(record);
        if (isCleint) {
          return;
        }
        return (
          <Space
            style={{
              marginRight: "20px",
            }}
          >
            {editable ? (
              secondLevelLoading ? (
                <SpinnerComponent fontSize={16} />
              ) : (
                <Space size={20}>
                  <SaveIcon
                    width={18}
                    height={18}
                    fill="#eb078e"
                    className="custom-icon"
                    onClick={() => hanldeSecondLevel()}
                  />
                  <CrossIcon
                    className="custom-icon"
                    onClick={() => cancel(record)}
                  />
                </Space>
              )
            ) : (
              // (isSuperAdmin || isAdmin) && (
                <EditIcon
                  width={18}
                  className="custom-icon"
                  onClick={() => {
                    // record.item_detail === null && editSecondLevel(record);
                    editSecondLevel(record);
                  }}
                />
              // )
            )}
          </Space>
        );
      },
    },
  ];

  const handleExpendedChange = (pagination, filters, sorter) => {
    setExpandedSortedInfoSecondLevel(sorter);
  };

  const editSecondLevel = (record) => {
    if (record.item_detail === null) {
      // Create Case
      setCurrentUpdateObj(record);
      form.setFieldsValue({ ...record });
      setEditingSecondLevelKey(record.id);
    } else {
      // Update Case
      const { format_name } = record.item_detail;
      setCurrentUpdateObj(record);
      form.setFieldsValue({
        format_name,
        processing_steps: record.so_item_detail,
      });
      setProcessCheckedList(record.so_item_detail);
      setEditingSecondLevelKey(record.id);
    }
  };

  const handleSelectOnchange = (value) => {
    if (value.includes("all")) {
      setProcessCheckedList([...processing_steps.map((item) => item.value)]);
      value = processing_steps.map((item) => item.value);
    } else if (value.includes("not-all")) {
      setProcessCheckedList([]);
      value = [];
    } else {
      setProcessCheckedList(value);
    }
  };

  const fun = () => {};

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    switch (dataIndex) {
      case "format_name":
        inputNode = (
          <Select
            showSearch
            className="activities-selector"
            dropdownClassName="select-dropdown-custom"
            placeholder="Select Format"
            getPopupContainer={() =>
              document.getElementsByClassName(
                "ant-layout-content layout-content site-layout-background custom-textarea"
              )[0]
            }
            size="small"
            style={{ width: 125 }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {formats.map((format) => (
              <Option key={format} value={format}>
                {format}
              </Option>
            ))}
          </Select>
        );
        break;
      case "processing_steps":
        inputNode = (
          <AMultiSelectControlledOutside
            debouncedCallApi={fun}
            disabled={false}
            isProcessSteps={true}
            placeholder="Select Process Step"
            selectTags={processCheckedList}
            open={proceesingStepsOpen}
            setOpen={setProceesingStepsOpen}
            onSelectChange={(value) => handleSelectOnchange(value)}
            // data={getOptions(processing_steps, processCheckedList) || []}
            data={processing_steps}
            filterOption={(input, option) => {
              return (
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          />
        );
        break;
      default:
        break;
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required:
                  dataIndex === "processing_steps" &&
                  processCheckedList.length > 0
                    ? false
                    : true,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const hanldeSecondLevel = async () => {
    try {
      form.submit();
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = secondLevelColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditingSecondLevel(record),
        };
      },
    };
  });

  const handleSecondLevelSuccess = () => {
    form.resetFields();
    setCurrentUpdateObj({});
    setEditingSecondLevelKey();
    setSecondLevelLoading(false);
    setProcessCheckedList([]);
    dispatchFun();
  };

  const hanldeFinishSecondLevel = (item) => {
    const { format_name } = item;
    const { item_detail } = currentUpdateObj;
    if (item_detail === null) {
      setSecondLevelLoading(true);
      dispatch(
        createSecondLevel({
          data: {
            format_name,
            processing_steps: processCheckedList,
            soitemid: currentUpdateObj.soitemid,
          },
          handleSecondLevelSuccess,
        })
      );
    } else {
      setSecondLevelLoading(true);
      dispatch(
        updateSecondLevel({
          data: {
            format_name,
            processing_steps: processCheckedList,
            id: currentUpdateObj.item_detail.id,
          },
          handleSecondLevelSuccess,
        })
      );
    }
  };

  // const onTableRowExpand = (expanded, record) => {
  //   const { item_detail } = record;
  //   if (expanded && item_detail !== null) {
  //     dispatch(fetchProdThirdLevel(record));
  //   }
  //   const keys = [];
  //   if (expanded && item_detail !== null) {
  //     keys.push(record.id);
  //   }
  //   setExpandedRowKeys(keys);
  // };

  return (
    <Form form={form} component={false} onFinish={hanldeFinishSecondLevel}>
      <Table
        className="custom-expanded-table table-second-level without-search-header"
        dataSource={secondLevelData}
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        loading={fetchLoading}
        onChange={handleExpendedChange}
        expandable={{
          expandedRowRender: () => (
            <ThirdLevelComponent
              firstLevelDebounce={firstLevelDebounce}
              soid={soid}
              soitemid={expandedRowKeys[0] || ""}
            />
          ),
          // expandRowByClick: true,
          expandIcon: null,
          showExpandColumn: false,
        }}
        onRow={({ id }) =>
          expandedRowKeys.includes(id) && {
            className: "expand-parent third-level-expanded-row",
          }
        }
        columns={mergedColumns}
        sortUpIcon={<UpOutlined />}
        sortDownIcon={<DownOutlined />}
        expandedRowKeys={expandedRowKeys}
        // onExpand={onTableRowExpand}
      />
    </Form>
  );
};

export default SecondLevelComponent;

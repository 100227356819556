function capitalizeFirstLetterofEachWord(string) {
  let arr = string.split("_");
  return arr.map((element) => {
    return (
      element.charAt(0).toUpperCase() + element.substring(1).toLowerCase() + " "
    );
  });
}

export { capitalizeFirstLetterofEachWord };

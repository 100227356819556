import { useSelector } from "react-redux";

const UseGetUserRole = () => {
  const { user } = useSelector((state) => state.authReducer);

  const allRoles = [
    "super_admin",
    "admin",
    "production_user",
    "sales_user",
    "client",
  ];

  const roles = allRoles.map((role) => role === user.user_type);

  return roles; // [isSuperAdmin, isAdmin, isProdUser, isSalesUser, isCleint]
};

export default UseGetUserRole;

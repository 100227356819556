import * as constants from "./constants";

const initialState = {
  fourthLevelData: [],
  fetchLoading: false,
  verifyPinLoading: false,
  department_users: [],
  updateLoading: false,
  items_processing_step_id: "",
  processing_step_name: "",
};

const fourthLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROD_FOURTH_LEVEL:
      const { data } = action;
      return {
        ...state,
        fetchLoading: true,
        // fourthLevelData: [],
        // items_processing_step_id: "",
        // department_users: [],
        processing_step_name: data.processing_step_name,
      };
    case constants.FETCH_PROD_FOURTH_LEVEL_SUCCESS:
      const {
        department_users,
        processing_operators,
        items_processing_step_id,
        processing_step_name,
      } = action.data;

      const userIds = [];
      processing_operators.forEach((ele) => userIds.push(ele.user_id));

      let filteredDepartments = department_users.filter(
        (f) => !userIds.includes(f.id)
      );

      return {
        ...state,
        fetchLoading: false,
        fourthLevelData: processing_operators,
        department_users: filteredDepartments,
        items_processing_step_id: Number(items_processing_step_id),
        processing_step_name,
      };
    case constants.FETCH_PROD_FOURTH_LEVEL_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };
    case constants.VERIFY_PIN:
      return {
        ...state,
        verifyPinLoading: true,
      };
    case constants.VERIFY_PIN_SUCCESS:
      return {
        ...state,
        verifyPinLoading: false,
      };
    case constants.VERIFY_PIN_ERROR:
      return {
        ...state,
        verifyPinLoading: false,
      };
    case constants.UPDATE_OPERATOR:
      return {
        ...state,
        fetchLoading: true, // should be update loading here, will update later
      };
    case constants.UPDATE_OPERATOR_SUCCESS:
      const updatedData = state.fourthLevelData.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        fetchLoading: false, // should be update loading here, will update later
        fourthLevelData: updatedData,
      };
    case constants.UPDATE_OPERATOR_ERROR:
      return {
        ...state,
        fetchLoading: false, // should be update loading here, will update later
      };
    case constants.STOP_OPERATOR:
      return {
        ...state,
        fetchLoading: true, // should be update loading here, will update later
      };
    case constants.STOP_OPERATOR_SUCCESS:
      const updated = state.fourthLevelData.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        fetchLoading: false, // should be update loading here, will update later
        fourthLevelData: updated,
      };
    case constants.STOP_OPERATOR_ERROR:
      return {
        ...state,
        fetchLoading: false, // should be update loading here, will update later
      };

    case constants.ADD_OPERATOR:
      return {
        ...state,
        fetchLoading: true, // should be update loading here, will update later
      };
    case constants.ADD_OPERATOR_SUCCESS:
      const { user_id } = action.data;

      const filteredDepartmentUsers = state.department_users.filter(
        (el) => el.id !== user_id
      );
      return {
        ...state,
        fourthLevelData: [...state.fourthLevelData, action.data],
        // items_processing_step_id: "",
        department_users: filteredDepartmentUsers,
        fetchLoading: false, // should be update loading here, will update later
      };
    case constants.ADD_OPERATOR_ERROR:
      return {
        ...state,
        fetchLoading: false, // should be update loading here, will update later
      };
    default:
      return state;
  }
};

export default fourthLevelReducer;

import React, { useRef, useState } from "react";
import {
  CloseOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Col, Popconfirm, Popover, Row, message, Button, Spin } from "antd";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as AttachIcon } from "../../assets/attach.svg";
import { baseURL } from "../../globalUtils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { addProductionAttach, deleteProdAttach } from "./redux/actions";
import UseGetUserRole from "../../components/useGetUserRole";

const AttachmentsPopup = ({
  images,
  attachPopupVisible,
  setAttachPopupVisible,
  setCurrentUpdateObj,
  currentUpdateObj,
  record,
}) => {
  const [deleteItemKey, setDeleteItemKey] = useState([]);
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const { attachmentLoading } = useSelector((state) => state.productionReducer);
  const [isSuperAdmin, isAdmin, , isSalesUser] = UseGetUserRole();
  const { user } = useSelector((state) => state.authReducer);

  const handleDeleteImage = (imgRecord) => {
    setDeleteItemKey([...deleteItemKey, imgRecord.key]);
    dispatch(
      deleteProdAttach({
        ...imgRecord,
        soid: currentUpdateObj.soid,
        setDeleteItemKey,
        deleteItemKey,
      })
    );
  };

  const handleShowImage = (image) => {
    let url = image.isLocal
      ? URL.createObjectURL(image)
      : `${baseURL}${image.image}`;
    window.open(url, "_blank");
  };

  const hide = () => {
    setAttachPopupVisible(false);
  };

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj["key"] = Math.round(Math.random() * 1000000);
      imgObj["isLocal"] = true;
      if (imgObj) {
        const { soid } = currentUpdateObj;
        dispatch(
          addProductionAttach({
            data: {
              soid,
              images: [imgObj],
            },
            setCurrentUpdateObj,
          })
        );
      }
    } else {
      message.error("This file format is not supported.");
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  const isAllow = () => {
    let salesUserCond =
      isSalesUser && record?.cpy_staff_id == user?.quick_easy_id;
    return isSuperAdmin || isAdmin || salesUserCond;
  };

  return (
    <Popover
      placement="bottom"
      overlayClassName="activities-attach-popover"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      content={
        <>
          <Row>
            <Col span={24}>
              <div className="close-icon-div">
                <CloseOutlined onClick={hide} />
              </div>
            </Col>
            {images.map((el) => {
              const { image, key } = el;
              let name = image
                .split("/")
                [image.split("/").length - 1].split("?")[0];

              return (
                <Col span={24} key={key}>
                  <Row justify="start" align="middle">
                    <Col span={3}>
                      <AttachIcon
                        className="rotate-icon activities-attach"
                        width={16}
                        fill="#231f20"
                      />
                    </Col>
                    <Col span={18}>
                      <span
                        className="name-span"
                        onClick={() => handleShowImage(el)}
                      >
                        {name}
                      </span>
                    </Col>
                    <Col span={3}>
                      <Popconfirm
                        overlayClassName="activities-popconfirm"
                        title="Are you sure to delete this attachment?"
                        onConfirm={() => handleDeleteImage(el)}
                        okText="Yes"
                        cancelText="No"
                      >
                        {deleteItemKey.includes(key) ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          isAllow() && (
                            <DeleteIcon
                              fill="#eb078e"
                              width={18}
                              height={18}
                              className="del-icon"
                            />
                          )
                        )}
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          {isAllow() && (
            <>
              <Button
                icon={<PlusOutlined />}
                className="add-attach-btn"
                size="middle"
                loading={attachmentLoading}
                onClick={() => inputFile.current.click()}
              >
                Attachment
              </Button>
              <input
                type="file"
                id="file"
                multiple
                accept=""
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(e) => handleImage(e.target.files[0], e)}
              />
            </>
          )}
        </>
      }
      title={null}
      trigger="click"
      visible={attachPopupVisible}
      onVisibleChange={(visible) => {
        setAttachPopupVisible(visible);
      }}
    >
      <div className="attach-parent-div">
        <AttachIcon
          className="rotate-icon activities-attach"
          width={16}
          fill="#231f20"
        />
        <div>{images?.length || 0}</div>
      </div>
    </Popover>
  );
};

export default AttachmentsPopup;

import axios from "axios";
import * as constants from "./constants";

export const loginUser = (data) => {
  return {
    type: constants.LOGIN,
    data: data,
  };
};

export const loginUserSuccess = (data) => {
  const { navigate, user, shouldNavigate } = data;
  localStorage.setItem(
    "user",
    JSON.stringify({
      ...user,
      user_type: user?.user_type ? user?.user_type : "client",
    })
  );

  axios.defaults.headers.common["Authorization"] = user.auth_token;

  shouldNavigate && navigate(user?.user_type ? "/" : "/client-profile");

  return {
    type: constants.LOGIN_SUCCESS,
    data: data,
  };
};

export const loginUserError = (data) => {
  return {
    type: constants.LOGIN_ERROR,
  };
};

export const logoutUser = (data) => {
  return {
    type: constants.LOGOUT,
    data: data,
  };
};

export const logoutUserSuccess = (data) => {
  // const { navigate } = data;
  localStorage.removeItem("user");

  axios.defaults.headers.common["Authorization"] = null;

  // navigate("/");
  return {
    type: constants.LOGOUT_SUCCESS,
    data: data,
  };
};

export const logoutUserError = (data) => {
  return {
    type: constants.LOGOUT_ERROR,
  };
};

export const setUser = (data) => {
  return {
    type: constants.SET_USER,
    data,
  };
};

const FETCH_ACTIVITIES = "FETCH_ACTIVITIES";
const FETCH_ACTIVITIES_SUCCESS = "FETCH_ACTIVITIES_SUCCESS";
const FETCH_ACTIVITIES_ERROR = "FETCH_ACTIVITIES_ERROR";
const FETCH_ACTIVITIES_GRAPH_DATA = "FETCH_ACTIVITIES_GRAPH_DATA";
const FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS =
  "FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS";
const FETCH_ACTIVITIES_GRAPH_DATA_ERROR = "FETCH_ACTIVITIES_GRAPH_DATA_ERROR";
const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
const UPDATE_ACTIVITY_ERROR = "UPDATE_ACTIVITY_ERROR";
const FETCH_ACTIVITIES_SEARCH_DATA = "FETCH_ACTIVITIES_SEARCH_DATA";
const FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS =
  "FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS";
const FETCH_ACTIVITIES_SEARCH_DATA_ERROR = "FETCH_ACTIVITIES_SEARCH_DATA_ERROR";
const DELETE_ACTIVITY = "DELETE_ACTIVITY";
const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
const DELETE_ACTIVITY_ERROR = "DELETE_ACTIVITY_ERROR";
const CREATE_ACTIVITIES = "CREATE_ACTIVITIES";
const CREATE_ACTIVITIES_SUCCESS = "CREATE_ACTIVITIES_SUCCESS";
const CREATE_ACTIVITIES_ERROR = "CREATE_ACTIVITIES_ERROR";
const DELETE_ACTIVITY_ATTACH = "DELETE_ACTIVITY_ATTACH";
const DELETE_ACTIVITY_ATTACH_SUCCESS = "DELETE_ACTIVITY_ATTACH_SUCCESS";
const DELETE_ACTIVITY_ATTACH_ERROR = "DELETE_ACTIVITY_ATTACH_ERROR";
const UPDATE_ISADD_ISUPDATE = "UPDATE_ISADD_ISUPDATE";
const ADD_ACTIVITY_ATTACH = "ADD_ACTIVITY_ATTACH";

export {
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  FETCH_ACTIVITIES_GRAPH_DATA,
  FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS,
  FETCH_ACTIVITIES_GRAPH_DATA_ERROR,
  FETCH_ACTIVITIES_SEARCH_DATA,
  FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
  CREATE_ACTIVITIES,
  CREATE_ACTIVITIES_SUCCESS,
  CREATE_ACTIVITIES_ERROR,
  DELETE_ACTIVITY_ATTACH,
  DELETE_ACTIVITY_ATTACH_SUCCESS,
  DELETE_ACTIVITY_ATTACH_ERROR,
  UPDATE_ISADD_ISUPDATE,
  ADD_ACTIVITY_ATTACH,
};

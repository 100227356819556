import axios from "axios";

const errorHandler = (commenResponse) => {
  if (commenResponse?.message?.toLowerCase() === "not authorized") {
    localStorage.clear();
    localStorage.removeItem("user");
    axios.defaults.headers.common["Authorization"] = null;
    // message.error(commenResponse.data?.message || "Something went wrong!");
    window.location.replace(window.location, origin);
  }
};

export { errorHandler };

import { useSelector } from "react-redux";

const UseIsSalesManagerOrStaff = () => {
  const { user } = useSelector((state) => state.authReducer);

  let isSalesActivitiesManager = user?.departments_with_steps?.findIndex(
    (el) =>
      el.position.toLowerCase() === "manager" &&
      el.name.toLowerCase() === "sales activities"
  );

  let isSalesActivitiesStaff = user?.departments_with_steps?.findIndex(
    (el) =>
      el.position.toLowerCase() === "staff" &&
      el.name.toLowerCase() === "sales activities"
  );

  return [
    isSalesActivitiesManager !== -1 ? true : false,
    isSalesActivitiesStaff !== -1 ? true : false,
  ];
};

export default UseIsSalesManagerOrStaff;

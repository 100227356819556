// import consumer from "./consumer";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cableBaseURL } from "../../../globalUtils/axiosConfig";
import { receiveMessageinChannel } from "../redux/actions";

let Subscription = null;

const handleChannel = (id, dispatchFun) => {
  if (window.App === undefined) {
    window.App = {
      cable: window.Cable.createConsumer(cableBaseURL),
    };
  }

  Subscription = window.App.cable.subscriptions.create(
    { channel: "ChatChannel", model: "SoMain", id },
    {
      connected: function () {
        console.log("ChatChannel connected");
      },
      disconnected: function () {
        console.log("ChatChannel disconnected");
      },
      rejected: function () {
        console.log("ChatChannel rejected");
      },
      received: function (data) {
        console.log("ChatChannel received", data);
        dispatchFun(receiveMessageinChannel(data));
      },
    }
  );

  console.log("s", window.App.cable.subscriptions);
  return Subscription;
};

const MessageChannel = () => {
  //   const history = useHistory();
  const dispatch = useDispatch();
  const { currentChatRecordId } = useSelector((state) => state.chatReducer);
  const [PrevChatReqId, setPrevChatReqId] = useState();

  useEffect(() => {
    setPrevChatReqId(currentChatRecordId);

    currentChatRecordId &&
      PrevChatReqId !== currentChatRecordId &&
      handleChannel(currentChatRecordId, dispatch);
  }, [currentChatRecordId]);

  useEffect(() => {
    return () => {
      console.log("App.unsubscribe()................");
      // Subscription?.disconnected();
      Subscription?.unsubscribe();
      // dispatch({ type: SET_QUOTE_ID, payload: null });
      // window.App.cable.subscriptions.remove(window.App.subscription)
      // window.Cable.disconnect();
    };
  }, []);

  return <></>;
};

export default MessageChannel;

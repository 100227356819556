import React, { useEffect, useState } from "react";
import { Table, Input, Button, Popconfirm, Form, Space, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-icon.svg";
import SpinnerComponent from "../../components/spinner";
import CustomPagination from "../../components/customPagination/CustomPagination";
import {
  createActivityType,
  deleteActivityType,
  fetchActivityTypes,
  isAddingAction,
  updateActivityType,
} from "./redux/actions";
import _ from "lodash";

const ActivityTypeTable = () => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);

  const isEditing = (record) => record.id === editingKey;

  const {
    allActivityTypes,
    fetchingLoading,
    updateLoading,
    totalRecords,
    isAdding,
  } = useSelector((state) => state.activityTypesReducer);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivityTypes({ page: page, limit: resultPerPage, search })
      );
    }

    return () => {
      dispatch(isAddingAction(false));
    };
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivityTypes({ page: paramsPage, limit: resultPerPage, search })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setLoading(fetchingLoading);
  }, [fetchingLoading]);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey("");
    }
  }, [updateLoading]);

  useEffect(() => {
    setData(allActivityTypes);
  }, [allActivityTypes]);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `This field is required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const edit = (record) => {
    dispatch(isAddingAction(true));

    form.setFieldsValue({
      activity_type: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = (record) => {
    dispatch(isAddingAction(false));
    setData((data) => data.filter((el) => el.id !== record.id));
    form.resetFields();
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          dispatch(updateActivityType({ ...item, ...row }));
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (record) => {
    setEditingKey(record.id);
    dispatch(deleteActivityType(record));
  };

  const handleAdd = () => {
    dispatch(isAddingAction(true));
    let id = Math.floor(Math.random() * 1122123456);
    const newData = {
      id,
      activity_type: "",
      isAdd: true,
    };
    setData((data) => [newData, ...data]);
    setEditingKey(id);
    edit(newData);
  };

  const hanldeFinish = ({ activity_type }) => {
    dispatch(createActivityType({ activity_type }));
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Activity Type</div>
            <div>{searchInput("activity_type")}</div>
          </div>
        );
      },
      dataIndex: "activity_type",
      key: "activity_type",
      sorter: (a, b) => a.activity_type.localeCompare(b.activity_type),
      sortOrder: sortedInfo?.columnKey === "activity_type" && sortedInfo?.order,
      width: "50%",
      editable: true,
    },
    {
      title: "",
      key: "action",
      dataIndex: "",
      width: "50%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record.id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={15} align="center">
              <SaveIcon
                className="custom-icon"
                onClick={() => save(record.id)}
              />
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => cancel(record)}
              >
                <DeleteIcon className="custom-icon" />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={15} align="center">
            <EditIcon
              className="custom-icon"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            />
            {data.length >= 1 && (
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => handleDelete(record)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon className="custom-icon" />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Row className="production-manager-parent-row">
      <Col span={24} className="heading-col">
        <Row align="middle" gutter={[30,0]}>
          <Col xl={4} xxl={3}>
            <div>
              <span className="heading-span">Activity types</span>
            </div>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              className="add-activity-btn"
              size="middle"
              disabled={isAdding}
              onClick={handleAdd}
            >
              Activity type
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form form={form} component={false} onFinish={hanldeFinish}>
          <Table
            className="special-table activity-type-table"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            scroll={{ x: true }}
            rowClassName="editable-row"
            dataSource={data}
            loading={loading}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
          />
        </Form>
      </Col>
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </Row>
  );
};

export default ActivityTypeTable;

import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import * as authActions from "../../../login/redux/actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleCreateUser({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "permissions") {
        fd.append("permissions", JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.post(`${baseURL}/api/v1/users`, fd, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createUserSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateUser({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "permissions") {
        fd.append("permissions", JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.patch(
      `${baseURL}/api/v1/users/${data.id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateUserSuccess(response.data.data));
      let user = JSON.parse(localStorage.getItem("user"));
      const { id, user_type } = response.data.data;
      if (user?.id === id && user.user_type === user_type) {
        yield put(authActions.setUser(response.data.data));
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateUserSettings({ data }) {
  const { loadingObj } = data;

  let obj = {};
  if (loadingObj?.popupLoading) {
    obj = { popupLoading: false };
  }
  if (loadingObj?.emailLoading) {
    obj = { emailLoading: false };
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/users/${data.data.id}`,
      {
        ...data.data,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.updateUserSettingsSuccess({
          data: response.data.data,
          loadingObj: obj,
        })
      );
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      errorHandler(response.data);
      yield put(actions.updateUserSettingsError());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchUserDetailsUtils() {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/users/user_details`);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchUserDetailsUtilsSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchAllUsers({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/users`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllUsersSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    yield put(actions.fetchAllUsersError());
    AntdAlert.error("Something went wrong!")
    console.log(error);
  }
}

export function* handleDeleteUser({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/api/v1/users/${data.id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteUserSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteUserError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteUserError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleAddDepartment({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v1/departments`, {
      ...data,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.saveDepartmentSuccess(response.data.data));
      yield put(actions.setDepartDisableIndex(data.departments.length - 1));
    } else if (!success) {
      errorHandler(response.data);
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.saveDepartmentError());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchUser({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/users/${data}`);
    const { success } = response.data;

    if (success) {
      const { permissions, ...res } = response.data.data;
      yield put(actions.fetchUserSuccess({ ...res, ...permissions }));
    } else {
      yield put(actions.updateUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSendDetailsToUser({ data }) {
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/users/send_user_invite?id=${data}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      const { permissions, ...res } = response.data.data;
      yield put(actions.sendDetailsToUserSuccess({ ...res, ...permissions }));
    } else if (!success) {
      AntdAlert.error(message);
      errorHandler(response.data);
      yield put(actions.sendDetailsToUserError());
    }
  } catch ({ response }) {
    const { status } = response;
    if (status == 500) {
      AntdAlert.error("Something went wrong!");
      yield put(actions.sendDetailsToUserError());
    }
  }
}

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.less";
import ProductionManager from "./ProductionRegister";
import ProductionReporting from "./ProductionReporting";
import { fetchProdSearchUtils } from "./redux/actions";

const Production = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProdSearchUtils());
  }, []);

  return (
    <div className="page-top-div">
      <span className="text-span">Production</span>
      <ProductionReporting />
      <ProductionManager />
    </div>
  );
};

export default Production;

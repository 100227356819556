import React, { useState } from "react";
import { Modal, Button, Row, Col, Avatar, Image } from "antd";
// import OtpInput from "react-otp-input";
import { baseURL } from "../../globalUtils/axiosConfig";
import { useDispatch } from "react-redux";
import { verifyPin } from "./redux/actions";

const EnterPinModal = ({
  isVisible,
  setVisible,
  setPin,
  pin,
  record,
  setIsPinVerified,
  handelPinVerified,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setPin("");
  };

  const numArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const handleVerifySuccess = () => {
    setLoading(false);
    setVisible(false);
    setPin("");
    setIsPinVerified(true);
    handelPinVerified();
  };

  const handleVerifyFailed = () => {
    setLoading(false);
    setPin("");
    setIsPinVerified(false);
  };

  const handleDone = () => {
    setLoading(true);

    dispatch(
      verifyPin({
        data: { pin, user_id: record?.user_id },
        handleVerifySuccess,
        handleVerifyFailed,
      })
    );
  };

  return (
    <>
      <Modal
        title={
          <Row align="middle" justify="center">
            <Col span={24}>
              <span className="text-span">Enter PIN for</span>
            </Col>
            <Col>
              {record?.user_profile_picture ? (
                <Avatar
                  src={`${baseURL}${record.user_profile_picture}`}
                  size={32}
                />
              ) : (
                <Avatar size={32} />
              )}
              <span className="name-span">
                {record?.user_first_name + " " + record?.user_surname}
              </span>
            </Col>
          </Row>
        }
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="enter-pin-modal"
        maskClosable={false}
        footer={null}
        width={299}
        destroyOnClose
      >
        <Row justify="center" gutter={[0, 20]}>
          <Col span={24}>
            <Row justify="space-around">
              {[1, 2, 3, 4, 5].map((ch) => {
                return (
                  <Col className="pin-col" key={ch}>
                    <div className="pin-div">{ch <= pin.length ? "*" : ""}</div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col span={24} className="input-fields-col">
            {/* <OtpInput
              isInputNum="number"
              value={pin ? Number(pin) : null}
              onChange={null}
              numInputs={5}
              isInputSecure={true}
              separator={<span> </span>}
            /> */}
            <Row justify="center" gutter={[0, 20]}>
              <Col span={24} className="numbers-parent-col">
                <Row justify="center" gutter={[0, 20]}>
                  {numArray.map((num) => (
                    <Col span={8} className="num-div-col" key={num}>
                      <div
                        key={num}
                        className="num-div"
                        onClick={() => pin?.length < 5 && setPin(pin + num)}
                      >
                        <span>{num}</span>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col>
                <Button
                  disabled={pin.length < 5 || loading}
                  className="custom-blue-btn done-btn"
                  onClick={handleDone}
                >
                  DONE
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EnterPinModal;

import React, { useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FourthLevelComponent from "./fourthLevel";
import { fetchProdFourthLevel } from "./redux/actions";
import UseGetUserRole from "../../components/useGetUserRole";

const ThirdLevelComponent = ({ firstLevelDebounce, soid, soitemid }) => {
  const [, , , isSalesUser, isCleint] = UseGetUserRole();
  const [expandedSortedInfo, setExpandedSortedInfo] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [renderOtherCol, setRenderOtherCol] = useState();
  const dispatch = useDispatch();

  const thirdLevelData = useSelector(
    (state) => state.thirdLevelReducer.thirdLevelData
  );
  const fetchLoading = useSelector(
    (state) => state.thirdLevelReducer.fetchLoading
  );

  const columns = [
    {
      title: "Process description",
      dataIndex: "processing_step_name",
      key: "processing_step_name",
      sorter: (a, b) =>
        a?.processing_step_name.localeCompare(b?.processing_step_name),
      sortOrder:
        expandedSortedInfo?.columnKey === "processing_step_name" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      width: 400,
    },
    {
      title: "ASSIGNED",
      dataIndex: "assigned",
      key: "assigned",
      sorter: (a, b) => a?.assigned - b?.assigned,
      sortOrder:
        expandedSortedInfo?.columnKey === "assigned" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      width: 600,
    },
    {
      title: "QTY READY",
      key: "quantity_ready",
      dataIndex: "quantity_ready",
      sorter: (a, b) => a.quantity_ready - b.quantity_ready,
      sortOrder:
        expandedSortedInfo?.columnKey === "quantity_ready" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      align: "right",
      width: 90,
      render: (quantity_ready, record) => {
        const { processing_step_name } = record;
        if (
          ["layout approved", "notification of layout complete"].includes(
            processing_step_name.toLowerCase()
          )
        ) {
          return;
        }
        return <div>{quantity_ready === 0 ? null : quantity_ready}</div>;
      },
    },
    {
      title: () => {
        return (
          <div>
            <div>Qty</div>
            <div>Completed</div>
          </div>
        );
      },
      dataIndex: "quantity_completed",
      key: "quantity_completed",
      sorter: (a, b) => a.quantity_completed - b.quantity_completed,
      sortOrder:
        expandedSortedInfo?.columnKey === "quantity_completed" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      width: 90,
      align: "right",
      render: (quantity_completed, record) => {
        const { processing_step_name } = record;
        if (
          ["layout approved", "notification of layout complete"].includes(
            processing_step_name.toLowerCase()
          )
        ) {
          if (record?.assigned <= 0) {
            return;
          }
          return <div>{quantity_completed >= 0 ? "Completed" : "Pending"}</div>;
        }
        return <div>{quantity_completed}</div>;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 190,
    },
  ];

  const handleExpendedChange = (pagination, filters, sorter) => {
    setExpandedSortedInfo(sorter);
  };

  const onTableRowExpand = (expanded, record) => {
    if (isSalesUser || isCleint) {
      return;
    }

    if (expanded) {
      const { processing_step_name } = record;
      let shouldRenderOtherCol = [
        "notification of layout complete",
        "layout approved",
      ].includes(processing_step_name.toLowerCase());

      setRenderOtherCol(shouldRenderOtherCol);
      dispatch(fetchProdFourthLevel(record));
    }
    // else {
    // dispatch(
    //   fetchProdFourthLevelSuccess({
    //     data: {
    //       department_users: [],
    //       processing_operators: [],
    //       items_processing_step_id: "",
    //     },
    //   })
    // );
    // }
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <Table
      className="custom-expanded-table table-third-level"
      columns={columns}
      dataSource={thirdLevelData}
      pagination={false}
      onChange={handleExpendedChange}
      loading={fetchLoading}
      expandable={{
        expandedRowRender: () => (
          <FourthLevelComponent
            renderOtherCol={renderOtherCol}
            firstLevelDebounce={firstLevelDebounce}
            soid={soid}
            soitemid={soitemid}
          />
        ),
        expandRowByClick: true,
        expandIcon: null,
        showExpandColumn: false,
      }}
      onRow={({ id }) =>
        expandedRowKeys.includes(id) && {
          className: "expand-parent fourth-level-expanded-row",
        }
      }
      expandedRowKeys={expandedRowKeys}
      onExpand={onTableRowExpand}
    />
  );
};

export default ThirdLevelComponent;

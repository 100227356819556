import React, { useState } from "react";
import { Popover, Table } from "antd";
import { ReactComponent as TimeIcon } from "../../assets/timeIcon.svg";
import moment from "moment";

const RecordedTimesPopover = ({ activities }) => {
  const [expandedSortedInfo, setExpandedSortedInfo] = useState(null);

  const getDiff = (start_time, stop_time) => {
    const duration = moment.duration(
      moment(stop_time).diff(moment(start_time))
    );
    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  };

  const columns = [
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      sorter: (a, b) =>
        moment(a.start_time).unix() - moment(b.start_time).unix(),
      sortOrder:
        expandedSortedInfo?.columnKey === "start_time" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      render: (startTime) => {
        return startTime && moment(startTime).format("YYYY/MM/DD  hh:mm");
      },
    },
    {
      title: "End Time",
      dataIndex: "stop_time",
      key: "stop_time",
      sorter: (a, b) => moment(a.stop_time).unix() - moment(b.stop_time).unix(),
      sortOrder:
        expandedSortedInfo?.columnKey === "stop_time" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      render: (stopTime) => {
        return stopTime && moment(stopTime).format("YYYY/MM/DD  hh:mm");
      },
    },
    {
      title: "Total Time",
      key: "total_time",
      dataIndex: "total_time",
      sorter: (a, b) => moment(a.stop_time).unix() - moment(b.stop_time).unix(), // need to update
      sortOrder:
        expandedSortedInfo?.columnKey === "total_time" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      align: "center",
      render: (_, { start_time, stop_time }) => {
        return (
          start_time !== null &&
          stop_time !== null && <span>{getDiff(start_time, stop_time)}</span>
        );
      },
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder:
        expandedSortedInfo?.columnKey === "quantity" &&
        expandedSortedInfo?.order,
      ellipsis: true,
    },
    {
      title: "Comment",
      dataIndex: "feedback",
      key: "feedback",
      sorter: (a, b) => a.feedback.localeCompare(b.feedback),
      sortOrder:
        expandedSortedInfo?.columnKey === "feedback" &&
        expandedSortedInfo?.order,
      ellipsis: true,
    },
  ];

  const handleExpendedChange = (pagination, filters, sorter) => {
    setExpandedSortedInfo(sorter);
  };

  return (
    <Popover
      overlayClassName="recorded-time-popover"
      placement="bottomLeft"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      content={
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={activities}
          pagination={false}
          onChange={handleExpendedChange}
        />
      }
      title={null}
      trigger="click"
    >
      <TimeIcon width={18} height={18} />
    </Popover>
  );
};

export default RecordedTimesPopover;

import React, { useState } from "react";
import { Modal, Button, Row, Col, Form, Input, message } from "antd";
import axios from "axios";
import { baseURL } from "../../../globalUtils/axiosConfig";

const UpdatePasswordModal = ({ isVisible, setVisible, email, isClient }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    const { password, confirm_password } = values;
    handleUpdatePassword({ password, confirm_password, email });
  };

  const handleUpdatePassword = async (values) => {
    let userUrl = baseURL + `/api/v1/password/update_password`;
    let clientUrl = baseURL + `/api/v1/password/client_update_password`;
    let url = isClient ? clientUrl : userUrl;

    try {
      setLoading(true);
      const res = await axios.put(url, values);
      const { status } = res;
      if (status === 200) {
        message.success("Password has been updated successfully!");
        setVisible(false);
        form.resetFields();
      }
      setLoading(false);
    } catch ({ response: { data } }) {
      message.error(data?.status);
      setLoading(false);
    }
  };

  let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

  return (
    <>
      <Modal
        title={`Change Password`}
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="create-password-modal"
        maskClosable={false}
        footer={null}
        width={409}
      >
        <Row justify="center" gutter={[0, 20]}>
          <Col>
            <div className="text-div">
              Password must be at least six characters in length containing both
              uppercase and lowercase characters (a-z and A-Z) and must contain
              at least one number (0-9) and one special character (* ! #).
            </div>
          </Col>
          <Col span={24}>
            <Form form={form} name="update-password" onFinish={onFinish}>
              <label>Current Password</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  {
                    pattern: passwordRegex,
                    message: "Invalid!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  width="100%"
                  placeholder="Enter current password"
                />
              </Form.Item>

              <label>New Password</label>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  {
                    pattern: passwordRegex,
                    message: "Invalid!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  width="100%"
                  placeholder="Enter new password"
                />
              </Form.Item>

              <label>Confirm new password</label>
              <Form.Item
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Confirm password not matched!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  width="100%"
                  placeholder="Confirm new password"
                />
              </Form.Item>
              <Col>
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="custom-blue-btn confirm-btn"
                >
                  Confirm
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UpdatePasswordModal;

import * as constants from "./constants";

export const fetchProdSearchUtils = () => {
  return {
    type: constants.FETCH_PROD_SEARCH_UTILS,
  };
};

export const fetchProdSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_PROD_SEARCH_UTILS_SUCCESS,
    data: data,
  };
};

export const fetchProdSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_PROD_SEARCH_UTILS_ERROR,
    data: data,
  };
};

export const fetchProdFirstLevel = (data) => {
  return {
    type: constants.FETCH_PROD_FIRST_LEVEL,
    data,
  };
};

export const fetchProdFirstLevelSuccess = (data) => {
  return {
    type: constants.FETCH_PROD_FIRST_LEVEL_SUCCESS,
    data: data,
  };
};

export const fetchProdFirstLevelError = (data) => {
  return {
    type: constants.FETCH_PROD_FIRST_LEVEL_ERROR,
    data: data,
  };
};

export const firstLevelAddComment = (data) => {
  return {
    type: constants.FIRST_LEVEL_ADD_COMMENT,
    data,
  };
};

export const firstLevelAddCommentSuccess = (data) => {
  return {
    type: constants.FIRST_LEVEL_ADD_COMMENT_SUCCESS,
    data: data,
  };
};

export const firstLevelAddCommentError = (data) => {
  return {
    type: constants.FIRST_LEVEL_ADD_COMMENT_ERROR,
    data: data,
  };
};

export const fetchProdGraphData = (data) => {
  return {
    type: constants.FETCH_PROD_GRAPH_DATA,
    data,
  };
};

export const fetchProdGraphDataSuccess = (data) => {
  return {
    type: constants.FETCH_PROD_GRAPH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchProdGraphDataError = (data) => {
  return {
    type: constants.FETCH_PROD_GRAPH_DATA_ERROR,
    data: data,
  };
};

export const fetchProdSecondLevel = (data) => {
  return {
    type: constants.FETCH_PROD_SECOND_LEVEL,
    data: data,
  };
};

export const fetchProdSecondLevelSuccess = (data) => {
  return {
    type: constants.FETCH_PROD_SECOND_LEVEL_SUCCESS,
    data: data,
  };
};

export const fetchProdSecondLevelError = () => {
  return {
    type: constants.FETCH_PROD_SECOND_LEVEL_ERROR,
  };
};

export const createSecondLevel = (data) => {
  return {
    type: constants.CREATE_SECOND_LEVEL,
    data: data,
  };
};

export const createSecondLevelSuccess = (data) => {
  return {
    type: constants.CREATE_SECOND_LEVEL_SUCCESS,
    data: data,
  };
};

export const createSecondLevelError = () => {
  return {
    type: constants.CREATE_SECOND_LEVEL_ERROR,
  };
};

export const updateSecondLevel = (data) => {
  return {
    type: constants.UPDATE_SECOND_LEVEL,
    data: data,
  };
};

export const updateSecondLevelSuccess = (data) => {
  return {
    type: constants.UPDATE_SECOND_LEVEL_SUCCESS,
    data: data,
  };
};

export const updateSecondLevelError = () => {
  return {
    type: constants.UPDATE_SECOND_LEVEL_ERROR,
  };
};

export const fetchProdThirdLevel = (data) => {
  return {
    type: constants.FETCH_PROD_THIRD_LEVEL,
    data: data,
  };
};

export const fetchProdThirdLevelSuccess = (data) => {
  return {
    type: constants.FETCH_PROD_THIRD_LEVEL_SUCCESS,
    data: data,
  };
};

export const fetchProdThirdLevelError = () => {
  return {
    type: constants.FETCH_PROD_THIRD_LEVEL_ERROR,
  };
};

export const fetchProdFourthLevel = (data) => {
  return {
    type: constants.FETCH_PROD_FOURTH_LEVEL,
    data: data,
  };
};

export const fetchProdFourthLevelSuccess = (data) => {
  return {
    type: constants.FETCH_PROD_FOURTH_LEVEL_SUCCESS,
    data: data,
  };
};

export const fetchProdFourthLevelError = () => {
  return {
    type: constants.FETCH_PROD_FOURTH_LEVEL_ERROR,
  };
};

export const verifyPin = (data) => {
  return {
    type: constants.VERIFY_PIN,
    data: data,
  };
};

export const verifyPinSuccess = (data) => {
  return {
    type: constants.VERIFY_PIN_SUCCESS,
    data: data,
  };
};

export const verifyPinError = () => {
  return {
    type: constants.VERIFY_PIN_ERROR,
  };
};

export const updateOperator = (data) => {
  return {
    type: constants.UPDATE_OPERATOR,
    data: data,
  };
};

export const updateOperatorSuccess = (data) => {
  return {
    type: constants.UPDATE_OPERATOR_SUCCESS,
    data: data,
  };
};

export const updateOperatorError = () => {
  return {
    type: constants.UPDATE_OPERATOR_ERROR,
  };
};

export const stopOperator = (data) => {
  return {
    type: constants.STOP_OPERATOR,
    data: data,
  };
};

export const stopOperatorSuccess = (data) => {
  return {
    type: constants.STOP_OPERATOR_SUCCESS,
    data: data,
  };
};

export const stopOperatorError = () => {
  return {
    type: constants.STOP_OPERATOR_ERROR,
  };
};

export const addOperator = (data) => {
  return {
    type: constants.ADD_OPERATOR,
    data: data,
  };
};

export const addOperatorSuccess = (data) => {
  return {
    type: constants.ADD_OPERATOR_SUCCESS,
    data: data,
  };
};

export const addOperatorError = () => {
  return {
    type: constants.ADD_OPERATOR_ERROR,
  };
};

export const updateThirdLevelOnUpdateFourthLevel = (data) => {
  return {
    type: constants.UPDATE_THIRD_LEVEL_ON_UPDATE_FOURTH_LEVEL,
    data,
  };
};

export const addProductionAttach = (data) => {
  return {
    type: constants.ADD_PRODUCTION_ATTACH,
    data: data,
  };
};

export const addProductionAttachSuccess = (data) => {
  return {
    type: constants.ADD_PRODUCTION_ATTACH_SUCCESS,
    data: data,
  };
};
export const addProductionAttachError = (data) => {
  return {
    type: constants.ADD_PRODUCTION_ATTACH_ERROR,
    data: data,
  };
};

export const deleteProdAttach = (data) => {
  return {
    type: constants.DELETE_PROD_ATTACH,
    data: data,
  };
};

export const deleteProdAttachSuccess = (data) => {
  return {
    type: constants.DELETE_PROD_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteProdAttachError = () => {
  return {
    type: constants.DELETE_PROD_ATTACH_ERROR,
  };
};

export const setChatRecordId = (id) => {
  return {
    type: constants.SET_CHAT_RECORD_ID,
    id,
  };
};

export const fetchPreviousChat = (data) => {
  return {
    type: constants.FETCH_PREV_CHAT,
    data: data,
  };
};

export const fetchPreviousChatSuccess = (data) => {
  return {
    type: constants.FETCH_PREV_CHAT_SUCCESS,
    data: data,
  };
};

export const fetchPreviousChatError = () => {
  return {
    type: constants.FETCH_PREV_CHAT_ERROR,
  };
};

export const sendMessage = (data) => {
  return {
    type: constants.SEND_MESSAGE,
    data: data,
  };
};

export const sendMessageSuccess = (data) => {
  return {
    type: constants.SEND_MESSAGE_SUCCESS,
    data: data,
  };
};

export const sendMessageError = () => {
  return {
    type: constants.SEND_MESSAGE_ERROR,
  };
};

export const receiveMessageinChannel = (chatItem) => {
  return {
    type: constants.RECEIVE_MESSAGE,
    chatItem,
  };
};

import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const ProdQtybyClientGraph = ({ data, colorsArr, text }) => {
  const getColor = (bar) =>
    colorsArr.filter((el) => el.key === bar?.id)[0]?.color;

  const ValueOutside = ({ bars }) => {
    return bars.map((bar) => {
      const {
        key,
        width,
        height,
        x,
        y,
        data: { value },
      } = bar;
      return (
        <g key={key} transform={`translate(${x}, ${y})`}>
          <text
            transform={`translate(${width + 23}, ${height / 3 + 6})`}
            textAnchor="middle"
            fontSize="11px"
            fill="#757575"
          >
            {value && value > 0 ? value : null}
          </text>
        </g>
      );
    });
  };

  const CustomBottomLabel = (data) => {
    const { x, value } = data;

    return (
      <g>
        {/* <rect x={x + 15 * 3} y={0} width={3} height="10" fill="#B1B1B1" /> */}
        {/* <rect x={x - 80 / 2} y={4} width={80} height="3" fill="#B1B1B1" /> */}

        <text x={x - 20 / 2} y={20} fontSize="12px" fill="#757575">
          {value && value > 1000 ? `${(value / 1000).toFixed(2)}K` : value}
        </text>
      </g>
    );
  };

  const getHeight = (length) => {
    if (length < 6) {
      return "500px";
    }
    return length * 90;
  };

  return (
    <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
      <div style={{ height: getHeight(data.length) }}>
        <ResponsiveBar
          data={data}
          keys={colorsArr.map((el) => el.key)}
          indexBy="id"
          margin={{
            top: 20,
            right: 100,
            bottom: 100,
            left: 270,
          }}
          colors={getColor}
          padding={0.3}
          groupMode="grouped"
          layout="horizontal"
          borderColor="inherit:darker(1.6)"
          enableLabel={false}
          enableGridX={true}
          enableGridY={false}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: null,
                },
              },
              domain: {
                line: {
                  stroke: "",
                  strokeWidth: 2,
                },
              },
              legend: {
                text: {
                  fontWeight: "bold",
                  letterSpacing: "1px",
                },
              },
            },
            grid: {
              line: {
                stroke: `rgb(231 232 236 / 80%)`,
                strokeWidth: 2,
                strokeDasharray: "2, 4",
              },
            },
          }}
          labelTextColor="white"
          layers={["grid", "axes", "bars", "markers", ValueOutside]}
          legends={[
            {
              dataFrom: "keys",
              // data: keys.map((id, index) => ({
              //   color: colors[index],
              //   id,
              //   //   label: id === "id" ? 1 : 2,
              //   label: 1,
              // })),
              anchor: "bottom",
              direction: "row",
              translateY: 63,
              itemWidth: 50,
              itemHeight: 20,
              itemDirection: "left-to-right",
              symbolSize: 20,
            },
          ]}
          axisLeft={{
            format: (v) => {
              return v.length > 35 ? (
                <tspan>
                  {v.substring(0, 35) + "..."}
                  <title>{v}</title>
                </tspan>
              ) : (
                v
              );
            },
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: text,
            legendPosition: "middle",
            legendOffset: -260,
            // renderTick: CustomLeftLabel,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Production Qty",
            legendPosition: "middle",
            legendOffset: 50,
            renderTick: CustomBottomLabel,
            tickValues: 6,
          }}
        />
      </div>
    </div>
  );
};

export default ProdQtybyClientGraph;

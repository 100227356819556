import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useSelector } from "react-redux";

const HoursBySalesRepAndActivityGraph = ({ data }) => {
  const { colorsObjArr } = useSelector((state) => state.activitiesReducer);

  const getColor = (bar) =>
    colorsObjArr.filter((el) => el.key === bar.id)[0].color;

  const ValueOutside = ({ bars }) => {
    return bars.map((bar) => {
      const {
        key,
        width,
        height,
        x,
        y,
        data: { value },
      } = bar;
      return (
        <g key={key} transform={`translate(${x + 10}, ${y})`}>
          <text
            transform={`translate(${width + 15}, ${height / 3 + 5})`}
            textAnchor="middle"
            fontSize="11px"
            fill="#757575"
          >
            {value && value > 0
              ? `${Math.trunc(value)}h${Math.trunc((value % 1) * 60)}m`
              : null}
          </text>
        </g>
      );
    });
  };

  const CustomBottomLabel = (data) => {
    const { x, value } = data;
    return (
      <g>
        <text x={x - 6 / 2} y={20} fontSize="12px" fill="#757575">
          {value}
        </text>
      </g>
    );
  };

  const getHeight = (length) => {
    if (length < 6) {
      return "500px";
    }
    return length * 100;
  };

  return (
    <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
      <div style={{ height: getHeight(data.length) }}>
        <ResponsiveBar
          data={data}
          keys={colorsObjArr.map((el) => el.key)}
          indexBy="id"
          margin={{
            top: 20,
            right: 100,
            bottom: 100,
            left: 160,
          }}
          colors={getColor}
          padding={0.3}
          groupMode="grouped"
          layout="horizontal"
          borderColor="inherit:darker(1.6)"
          enableLabel={false}
          enableGridX={true}
          enableGridY={false}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: null,
                },
              },
              domain: {
                line: {
                  stroke: "",
                  strokeWidth: 2,
                },
              },
              legend: {
                text: {
                  fontWeight: "bold",
                  letterSpacing: "2px",
                },
              },
            },

            grid: {
              line: {
                stroke: `rgb(231 232 236 / 80%)`,
                strokeWidth: 2,
                strokeDasharray: "2, 4",
              },
            },
          }}
          labelTextColor="white"
          layers={["grid", "axes", "bars", "markers", ValueOutside]}
          legends={[
            {
              dataFrom: "keys",
              // data: keys.map((id, index) => ({
              //   color: colorsArray[index],
              //   id,
              //   //   label: id === "id" ? 1 : 2,
              //   label: 1,
              // })),
              anchor: "bottom",
              direction: "row",
              translateY: 80,
              itemWidth: 50,
              itemHeight: 20,
              itemDirection: "left-to-right",
              symbolSize: 20,
            },
          ]}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Sales Rep",
            legendPosition: "middle",
            legendOffset: -150,
            // renderTick: CustomLeftLabel,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Hours",
            legendPosition: "middle",
            legendOffset: 50,
            renderTick: CustomBottomLabel,
            tickValues: 6,
          }}
        />
      </div>
    </div>
  );
};

export default HoursBySalesRepAndActivityGraph;

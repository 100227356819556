import * as constants from "./constants";

export const setUserDetailsIsEdit = (data) => {
  return {
    type: constants.USER_DETAILS_IS_EDIT,
    data: data,
  };
};

export const createUser = (data) => {
  return {
    type: constants.CREATE_USER,
    data: data,
  };
};

export const createUserSuccess = (data) => {
  return {
    type: constants.CREATE_USER_SUCCESS,
    data: data,
  };
};

export const createUserError = (data) => {
  return {
    type: constants.CREATE_USER_ERROR,
  };
};

export const fetchUser = (data) => {
  return {
    type: constants.FETCH_USER,
    data: data,
  };
};

export const fetchUserSuccess = (data) => {
  return {
    type: constants.FETCH_USER_SUCCESS,
    data,
  };
};

export const fetchUserError = (data) => {
  return {
    type: constants.FETCH_USER_ERROR,
  };
};

export const updateUser = (data) => {
  return {
    type: constants.UPDATE_USER,
    data: data,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: constants.UPDATE_USER_SUCCESS,
    data: data,
  };
};

export const updateUserError = (data) => {
  return {
    type: constants.UPDATE_USER_ERROR,
  };
};

export const updateUserSettings = (data) => {
  return {
    type: constants.UPDATE_USER_SETTINGS,
    data: data,
  };
};

export const updateUserSettingsSuccess = (data) => {
  return {
    type: constants.UPDATE_USER_SETTINGS_SUCCESS,
    data: data,
  };
};

export const updateUserSettingsError = (data) => {
  return {
    type: constants.UPDATE_USER_SETTINGS_ERROR,
  };
};

export const fetchUserDetailsUtils = () => {
  return {
    type: constants.FETCH_USERS_UTIL_DATA,
  };
};

export const fetchUserDetailsUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_USERS_UTIL_DATA_SUCCESS,
    data,
  };
};

export const fetchAllUsers = (data) => {
  return {
    type: constants.FETCH_ALL_USERS,
    data,
  };
};

export const fetchAllUsersSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_USERS_SUCCESS,
    data,
  };
};

export const fetchAllUsersError = (data) => {
  return {
    type: constants.FETCH_ALL_USERS_ERROR,
    data,
  };
};

export const deleteUser = (data) => {
  return {
    type: constants.DELETE_USER,
    data,
  };
};

export const deleteUserSuccess = (data) => {
  return {
    type: constants.DELETE_USER_SUCCESS,
    data,
  };
};

export const deleteUserError = (data) => {
  return {
    type: constants.DELETE_USER_ERROR,
    data,
  };
};

export const saveDepartment = (data) => {
  return {
    type: constants.SAVE_DEPARTMENT,
    data,
  };
};

export const saveDepartmentSuccess = (data) => {
  return {
    type: constants.SAVE_DEPARTMENT_SUCCESS,
    data,
  };
};

export const saveDepartmentError = (data) => {
  return {
    type: constants.SAVE_DEPARTMENT_ERROR,
    data,
  };
};

export const userDetailCleanupFunc = () => {
  return {
    type: constants.USER_DETAIL_CLEANUP_FUNC,
  };
};

export const sendDetailsToUser = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_USER,
    data: data,
  };
};

export const sendDetailsToUserSuccess = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_USER_SUCCESS,
  };
};

export const sendDetailsToUserError = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_USER_ERROR,
  };
};

export const setDepartDisableIndex = (data) => {
  return {
    type: constants.SET_DEPART_DISABLE_INDEX,
    data,
  };
};

export const sortClientDataByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.id.localeCompare(b.id));
  } else {
    return data.sort((a, b) => b.id.localeCompare(a.id));
  }
};

export const sortClientDataByAmount = (data, order) => {
  const sumValues = (obj) => {
    const { id, ...rest } = obj;
    return Object.values(rest).reduce((a, b) => a + b, 0);
  };

  if (order) {
    return data.sort((a, b) => sumValues(a) - sumValues(b));
  } else {
    return data.sort((a, b) => sumValues(b) - sumValues(a));
  }
};

export const sortSalesRepDataByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.id.localeCompare(b.id));
  } else {
    return data.sort((a, b) => b.id.localeCompare(a.id));
  }
};

export const sortSalesRepDataByAmount = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.Actual - b.Actual);
  } else {
    return data.sort((a, b) => b.Actual - a.Actual);
  }
};

export const ClientGraphData = [
  {
    id: "Zeal Wheels",
    Customers: 140000,
  },
  {
    id: "Vortex",
    Customers: 150000,
  },
  {
    id: "Strat Security",
    Customers: 235000,
  },
  {
    id: "Sanguine Skincare",
    Customers: 50000,
  },
  {
    id: "Quad Goals",
    Customers: 20000,
  },
  {
    id: "Obelus Concepts",
    Customers: 125000,
  },
  {
    id: "Moxie Marketing",
    Customers: 235000,
  },
  {
    id: "Lambent Illumination",
    Customers: 235000,
  },
  {
    id: "Inspire Fitness Go",
    Customers: 140000,
  },
  {
    id: "Innovation Arch",
    Customers: 20000,
  },
  {
    id: "Flux Water Gear",
    Customers: 40000,
  },
  {
    id: "Erudite Learning",
    Customers: 145000,
  },
  {
    id: "Epic Adventure",
    Customers: 145000,
  },
  {
    id: "eco Focus",
    Customers: 150000,
  },
  {
    id: "Cogent Data",
    Customers: 20000,
  },
  {
    id: "Cipher Publishing",
    Customers: 135000,
  },
  {
    id: "Candor Corp",
    Customers: 125000,
  },
  {
    id: "Bravura Inc",
    Customers: 135000,
  },
  {
    id: "Bonefete Fun",
    Customers: 40000,
  },
  {
    id: "Admire Arts",
    Customers: 20000,
  },
];

export const SalesRepdata = [
  {
    id: "Shamar Beltran",
    Target: 25000,
    Actual: 20000,
  },
  {
    id: "Izabella Adkins",
    Target: 20000,
    Actual: 23000,
  },
  {
    id: "Jayda Steveson",
    Target: 20000,
    Actual: 23000,
  },
  {
    id: "Bruna Williams",
    Target: 40000,
    Actual: 24000,
  },
  {
    id: "Eddie Green",
    Target: 35000,
    Actual: 32000,
  },
  {
    id: "Moshe Blanchard",
    Target: 20000,
    Actual: 33000,
  },
  {
    id: "Jalen Schultz",
    Target: 40000,
    Actual: 34000,
  },
  {
    id: "Summer Coleman",
    Target: 35000,
    Actual: 37000,
  },
  {
    id: "Chris Grimes",
    Target: 45000,
    Actual: 42000,
  },
  {
    id: "Derick Esparza",
    Target: 50000,
    Actual: 45000,
  },
  {
    id: "Frances Dodson",
    Target: 50000,
    Actual: 49000,
  },
  {
    id: "Audrey Stanton",
    Target: 50000,
    Actual: 52000,
  },
];

// production data

export const ProdQtydata = [
  {
    id: "Zeal Wheels",
    small: 55,
    large: 0,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Vortex",
    small: 0,
    large: 60,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Strat Security",
    small: 0,
    large: 0,
    ais: 100,
    promotional: 0,
  },
  {
    id: "Sanguine Skincare",
    small: 0,
    large: 5,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Quad Goals",
    small: 0,
    large: 55,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Obelus Concepts",
    small: 45,
    large: 0,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Moxie Marketing",
    small: 60,
    large: 0,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Lambent Illumination",
    small: 0,
    large: 0,
    ais: 30,
    promotional: 0,
  },
  {
    id: "Inspire Fitness Go",
    small: 0,
    large: 25,
    ais: 125,
    promotional: 50,
  },
  {
    id: "Innovation Arch",
    small: 0,
    large: 30,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Flux Water Gear",
    small: 0,
    large: 68,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Erudite Learning",
    small: 0,
    large: 0,
    ais: 45,
    promotional: 0,
  },
  {
    id: "Epic Adventure",
    small: 0,
    large: 10,
    ais: 0,
    promotional: 0,
  },
  {
    id: "eco Focus",
    small: 50,
    large: 20,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Cogent Data",
    small: 35,
    large: 75,
    ais: 75,
    promotional: 0,
  },
  {
    id: "Cipher Publishing",
    small: 30,
    large: 0,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Candor Corp",
    small: 0,
    large: 50,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Bravura Inc",
    small: 15,
    large: 0,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Bonefete Fun",
    small: 0,
    large: 40,
    ais: 90,
    promotional: 0,
  },
  {
    id: "Admire Arts",
    small: 0,
    large: 70,
    ais: 0,
    promotional: 0,
  },
];

export const sortProdDataByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.id.localeCompare(b.id));
  } else {
    return data.sort((a, b) => b.id.localeCompare(a.id));
  }
};

export const ProdQtyByOperatordata = [
  {
    id: "Matilda Sheitan",
    small: 95,
    large: 0,
    ais: 145,
    promotional: 0,
  },
  {
    id: "Katelyn",
    small: 0,
    large: 60,
    ais: 155,
    promotional: 0,
  },
  {
    id: "Evie Richards",
    small: 0,
    large: 125,
    ais: 75,
    promotional: 0,
  },
  {
    id: "Chanice Campbell",
    small: 105,
    large: 50,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Roshan Mcfadden",
    small: 45,
    large: 95,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Lennox Justice",
    small: 0,
    large: 98,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Ffion Farrell",
    small: 0,
    large: 0,
    ais: 90,
    promotional: 0,
  },
  {
    id: "Karl Simmons",
    small: 30,
    large: 10,
    ais: 0,
    promotional: 50,
  },
  {
    id: "Esmay Woodcock",
    small: 0,
    large: 70,
    ais: 0,
    promotional: 0,
  },
  {
    id: "Alanah Daly",
    small: 15,
    large: 0,
    ais: 0,
    promotional: 0,
  },
];

export const sortOperatorDataByAmount = (data, order) => {
  const sumValues = (obj) => {
    const { id, ...rest } = obj;
    return Object.values(rest).reduce((a, b) => a + b, 0);
  };

  if (order) {
    return data.sort((a, b) => sumValues(a) - sumValues(b));
  } else {
    return data.sort((a, b) => sumValues(b) - sumValues(a));
  }
};

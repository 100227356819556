import React from "react";
import { Col, Row } from "antd";
import DepartmentEmailsTable from "./departTable";
import "./index.less";

const DepartmentEmails = () => {
  return (
    <div className="page-top-div">
      <span className="text-span">Department emails</span>
      <Row className="production-manager-parent-row">
        <Col span={24} className="heading-col">
          <Row align="middle">
            <Col>
              <div>
                <span className="heading-span">Department emails</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <DepartmentEmailsTable />
        </Col>
      </Row>
    </div>
  );
};

export default DepartmentEmails;

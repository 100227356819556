import {
  colorsArray,
  sortActivitiesSalesRep,
  sortActivityTypes,
} from "../utils";
import * as constants from "./constants";

const initialState = {
  allActivities: [],
  fetchingLoading: true,
  updateLoading: false,
  calculate_activity_time: [],
  sales_user_activities: [],
  total_hours: 0,
  totalRecords: 0,
  deleteAttachLoading: false,
  salesRepOptions: [],
  activityTypesOptions: [],
  fetchUtilsLoading: false,
  isAddActivity: false,
  isUpdateActivity: false,
  isCopyActivity: false,
  colorsObjArr: [],
  deletingLoading: false,
};

const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_ACTIVITIES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allActivities: [action.data, ...state.allActivities],
        isAddActivity: false,
        isUpdateActivity: false,
        isCopyActivity: false,
      };
    case constants.CREATE_ACTIVITIES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddActivity: false,
        isUpdateActivity: false,
        isCopyActivity: false,
      };
    case constants.FETCH_ACTIVITIES_SEARCH_DATA:
      return {
        ...state,
        fetchUtilsLoading: true,
      };
    case constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS:
      const { activity_types, sales_reps } = action.data;

      const sortedActivityTypes = sortActivityTypes(activity_types);
      const sortedSalesRep = sortActivitiesSalesRep(sales_reps);

      let activityTypesOptions = sortedActivityTypes.map((el) => {
        const { activity_type, id } = el;
        return { value: id, label: activity_type };
      });

      let salesRepOptions = sortedSalesRep.map((el) => {
        const { username, id } = el;
        return { value: id, label: username };
      });

      return {
        ...state,
        fetchUtilsLoading: false,
        salesRepOptions,
        activityTypesOptions,
      };
    case constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR:
      return {
        ...state,
        fetchUtilsLoading: false,
      };

    case constants.FETCH_ACTIVITIES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_ACTIVITIES_SUCCESS:
      const {
        activities,
        calculate_activity_time,
        sales_user_activities,
        total_hours,
        total_sales_activities,
      } = action.data;

      const updatedData = [];

      const colorsObjArr = [];
      sales_user_activities.length > 0 &&
        sales_user_activities[0].values.map((value, i) =>
          colorsObjArr.push({
            key: value.key,
            color: colorsArray[i],
          })
        );

      sales_user_activities.map((el) => {
        const { user, values } = el;
        let obj = { id: user };
        values.map((value) => {
          const { key, hour } = value;
          return (obj[key] = hour > 0 ? hour / 60 : 0);
        });
        updatedData.push(obj);
      });

      return {
        ...state,
        fetchingLoading: false,
        allActivities: activities,
        total_hours: total_hours,
        calculate_activity_time,
        sales_user_activities: updatedData,
        totalRecords: total_sales_activities,
        colorsObjArr,
      };
    case constants.FETCH_ACTIVITIES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };
    case constants.UPDATE_ACTIVITY:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_ACTIVITY_SUCCESS:
      const updated = state.allActivities.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allActivities: updated,
        updateLoading: false,
        isAddActivity: false,
        isUpdateActivity: false,
        isCopyActivity: false,
      };
    case constants.UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_ACTIVITY:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_ACTIVITY_SUCCESS:
      const updatedAct = state.allActivities.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allActivities: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_ACTIVITY_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_ACTIVITY_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedActivities = [];

      state.allActivities.map((el) => {
        if (el.id === recordId) {
          let fetch_images = el.fetch_images.filter((img) => img.key !== id);
          updatedActivities.push({ ...el, fetch_images });
        } else {
          updatedActivities.push(el);
        }
      });

      return {
        ...state,
        allActivities: updatedActivities,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_ISADD_ISUPDATE:
      const { isAddActivity, isUpdateActivity, isCopyActivity } = action.data;
      return {
        ...state,
        isAddActivity,
        isUpdateActivity,
        isCopyActivity,
      };
    case constants.DELETE_ACTIVITY_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };
    default:
      return state;
  }
};

export default activitiesReducer;

const FETCH_ACTIVITY_TYPES = "FETCH_ACTIVITY_TYPES";
const FETCH_ACTIVITY_TYPES_SUCCESS = "FETCH_ACTIVITY_TYPES_SUCCESS";
const FETCH_ACTIVITY_TYPES_ERROR = "FETCH_ACTIVITY_TYPES_ERROR";
const UPDATE_ACTIVITY_TYPE = "UPDATE_ACTIVITY_TYPE";
const UPDATE_ACTIVITY_TYPE_SUCCESS = "UPDATE_ACTIVITY_TYPE_SUCCESS";
const UPDATE_ACTIVITY_TYPE_ERROR = "UPDATE_ACTIVITY_TYPE_ERROR";
const DELETE_ACTIVITY_TYPE = "DELETE_ACTIVITY_TYPE";
const DELETE_ACTIVITY_TYPE_SUCCESS = "DELETE_ACTIVITY_TYPE_SUCCESS";
const DELETE_ACTIVITY_TYPE_ERROR = "DELETE_ACTIVITY_TYPE_ERROR";
const CREATE_ACTIVITY_TYPE = "CREATE_ACTIVITY_TYPE";
const CREATE_ACTIVITY_TYPE_SUCCESS = "CREATE_ACTIVITY_TYPE_SUCCESS";
const CREATE_ACTIVITY_TYPE_ERROR = "CREATE_ACTIVITY_TYPE_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_ACTIVITY_TYPES,
  FETCH_ACTIVITY_TYPES_SUCCESS,
  FETCH_ACTIVITY_TYPES_ERROR,
  UPDATE_ACTIVITY_TYPE,
  UPDATE_ACTIVITY_TYPE_SUCCESS,
  UPDATE_ACTIVITY_TYPE_ERROR,
  DELETE_ACTIVITY_TYPE,
  DELETE_ACTIVITY_TYPE_SUCCESS,
  DELETE_ACTIVITY_TYPE_ERROR,
  CREATE_ACTIVITY_TYPE,
  CREATE_ACTIVITY_TYPE_SUCCESS,
  CREATE_ACTIVITY_TYPE_ERROR,
  IS_ADDING_UPDATE,
};

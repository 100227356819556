import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Button, Select, message } from "antd";
import { ReactComponent as SaveIcon } from "../../../assets/save-icon.svg";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as DeleteIcon } from "../../../assets/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { saveDepartment, setDepartDisableIndex } from "../redux/actions";
import SpinnerComponent from "../../../components/spinner";

const DepartmentsDetailsSection = ({ canEditEveryThing, isProfileCase }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [currentItemsIndex, setCurrentItemsIndex] = useState();
  const [deletedItemsIdsArray, setdeletedItemsIdsArray] = useState([]);
  const [allDeparts, setallDeparts] = useState([]);
  const {
    utilsData,
    currentUserObj,
    departmentLoading,
    departmentIsEdit,
    departItemsIndex,
  } = useSelector((state) => state.userManagerReducer);

  const userManagerReducer = useSelector((state) => state.userManagerReducer);
  console.log("userManagerReducer", userManagerReducer);

  useEffect(() => {
    setCurrentItemsIndex(departItemsIndex);
  }, [departItemsIndex]);

  const handleIndex = (index) => {
    if (index <= currentItemsIndex) {
      // setCurrentItemsIndex(currentItemsIndex - 1)
      dispatch(setDepartDisableIndex(currentItemsIndex - 1));
      let deletedItem = allDeparts[index];
      const { id } = deletedItem;
      // console.log('currentItemsIndex, index, id', currentItemsIndex, index, id)
      const remainings = allDeparts.filter((el) => el.id !== id);
      setallDeparts(remainings);
      // setundeletedDepartArray(remainings)
      setdeletedItemsIdsArray([...deletedItemsIdsArray, id]);
    }
  };

  // const hanldePosition = (item) => {
  //   return utilsData.positions.filter((el) => {
  //     return (el.value === item)[0]?.key
  //   })
  // };

  // const hanldePosition = (item) => {
  //   let res = utilsData.positions.filter((el) => el.value === item || el.key === item)
  //   return res[0].key
  // };

  useEffect(() => {
    setLoading(departmentLoading);
  }, [departmentLoading]);

  useEffect(() => {
    setIsEdit(departmentIsEdit);
  }, [departmentIsEdit]);

  useEffect(() => {
    if (
      utilsData?.positions &&
      currentUserObj?.departments_with_steps?.length > 0
    ) {
      setallDeparts(currentUserObj?.departments_with_steps);
      form.setFieldsValue(currentUserObj.departments_with_steps || []);
      currentUserObj?.departments_with_steps?.map((el, index) => {
        const { email } = el;
        return form.setFields([
          {
            name: ["departments", index, "email"],
            value: email,
          },
          {
            name: ["departments", index, "position"],
            // value: hanldePosition(el.position),
            value: el.position,
          },
          {
            name: ["departments", index, "processing_step_id"],
            value: el?.processing_step?.name,
            // value: el?.processing_steps
          },
          // {
          //   name: ["departments", index, "id"],
          //   value: index,
          // },
        ]);
      });
      // setCurrentItemsIndex(currentUserObj?.departments_with_steps?.length - 1)
      dispatch(
        setDepartDisableIndex(
          currentUserObj?.departments_with_steps?.length - 1
        )
      );
    } else {
      setallDeparts(currentUserObj?.departments_with_steps);
      form.resetFields();
    }
  }, [currentUserObj, utilsData?.positions]);

  const onFinish = (values) => {
    // console.log('values.departments', values.departments)
    // console.log('deletedItemsIdsArray', deletedItemsIdsArray)

    const { departments } = values;

    let emailsArr = [];

    departments.forEach((el) => emailsArr.push(el.email));

    const toFindDuplicates = (arry) =>
      arry.filter((item, index) => arry.indexOf(item) !== index);
    const duplicateElements = toFindDuplicates(emailsArr);

    if (duplicateElements.length > 0) {
      return message.error("Departments should not be duplicate");
    }

    dispatch(
      saveDepartment({
        departments,
        ids: deletedItemsIdsArray,
        user_id: currentUserObj.id,
      })
    );
  };

  function onChange(index) {
    let item = form.getFieldsValue().departments[index];

    if (item?.processing_step_id > 0 && item.position?.length > 1) {
      let department = utilsData.department.filter(
        (el) => el.id === item.processing_step_id
      )[0];

      let filteredEmail = utilsData.department_emails.filter(
        (el) =>
          el.name.toLowerCase() === department.name.toLowerCase() &&
          el.position.toLowerCase() === item.position.toLowerCase()
      );

      if (filteredEmail.length > 0) {
        form.setFields([
          {
            name: ["departments", index, "email"],
            value: filteredEmail[0].email,
          },
        ]);
      }
    }
  }

  function onSearch(val) {
    // console.log("search:", val);
  }

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    scrollToFirstError: true,
  };

  const capitalizeFirstLetter = (str) =>
    str && str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <Row>
        <Col span={24} className="heading-col">
          <Row align="middle" justify="space-between">
            <Col>
              <div>
                <span className="heading-span">Departments</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon
                    width={16}
                    height={16}
                    fill="#eb078e"
                    onClick={() => {
                      form.submit();
                      // setIsEdit(false);
                    }}
                  />
                )
              ) : (
                canEditEveryThing && (
                  <EditIcon
                    width={16}
                    fill="#eb078e"
                    height={16}
                    onClick={() => currentUserObj?.id > 0 && setIsEdit(true)}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col xl={2} xxl={4}></Col>
        <Col md={24} lg={24} xl={22} xxl={18}>
          <Form
            name="add-department"
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            {...formItemLayout}
            layout="vertical"
            requiredMark={false}
          >
            <Form.List name="departments">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row gutter={[30, 0]} align="middle" key={name}>
                        <Col md={7} lg={7} xl={7} xxl={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "processing_step_id"]}
                            rules={[
                              { required: true, message: "Missing Department" },
                            ]}
                            label={`Department ${index + 1}`}
                          >
                            <Select
                              disabled={
                                index <= currentItemsIndex &&
                                currentItemsIndex !== null
                              }
                              style={{
                                width: "100%",
                              }}
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  "ant-layout-content layout-content site-layout-background custom-textarea"
                                )[0]
                              }
                              className="department-select"
                              dropdownClassName="select-dropdown-custom"
                              showSearch
                              placeholder=""
                              optionFilterProp="children"
                              onChange={() => onChange(index)}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilsData?.department?.map(({ name, id }) => (
                                <Option key={name} value={id}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={7} lg={7} xl={7} xxl={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "position"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Department Position",
                              },
                            ]}
                            label="Department Position"
                          >
                            <Select
                              disabled={
                                index <= currentItemsIndex &&
                                currentItemsIndex !== null
                              }
                              showSearch
                              dropdownClassName="select-dropdown-custom"
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  "ant-layout-content layout-content site-layout-background custom-textarea"
                                )[0]
                              }
                              placeholder=""
                              optionFilterProp="children"
                              onChange={() => onChange(index)}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {utilsData?.positions?.map(({ key }) => (
                                <Option key={key} value={key}>
                                  {capitalizeFirstLetter(key)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={9} lg={9} xl={9} xxl={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "email"]}
                            label="Department Email"
                          >
                            <Input placeholder="" disabled />
                          </Form.Item>
                        </Col>
                        {isEdit && (
                          <DeleteIcon
                            height={17}
                            width={17}
                            fill="#eb078e"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleIndex(index);
                              remove(name);
                            }}
                          />
                        )}
                      </Row>
                    ))}
                    {isEdit && (
                      <Button
                        className="add-department-btn"
                        onClick={() => {
                          add();
                        }}
                        disabled={!isEdit}
                        block
                        icon={<PlusOutlined />}
                      >
                        Department
                      </Button>
                    )}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </Col>
      </Row>
      <div className="empty-div"></div>
    </>
  );
};

export default DepartmentsDetailsSection;

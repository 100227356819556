import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "./notificationActions";
import { baseURL } from "../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../components/unauthorizeHandler";

export function* handleFetchAllNotifications({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/notifications`);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchAllNotificationsSuccess(response.data.data));
    } else {
      yield put(actions.fetchAllNotificationsError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchAllNotificationsError());
    console.log(error);
  }
}

export function* handleFetchUnreadNotificationCount({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/notifications/fetch_user_notifications`
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchUnreadNotificationsSuccess(response.data.data));
    } else {
      yield put(actions.fetchUnreadNotificationsError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchUnreadNotificationsError());
    console.log(error);
  }
}

export function* handleUpdateNotification({ data }) {
  const { id } = data;
  try {
    const response = yield axios.put(`${baseURL}/api/v1/notifications/${id}`);
    const { success, message } = response.data;

    if (success) {
      // AntdAlert.success(message);
      yield put(actions.updateNotificationSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateNotificationError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateNotificationError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Space, Popconfirm } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import SpinnerComponent from "../../components/spinner";
import {
  deleteClient,
  fetchAllClients,
  setClientDetailsIsEdit,
} from "./redux/actions";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../components/customPagination/CustomPagination";
import _ from "lodash";

const ClientManagerTable = () => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [firstTimeFetching, setfirstTimeFetching] = useState(true);

  const { allClients, fetchClientLoading, deleteClientLoading, totalRecords } =
    useSelector((state) => state.clientManagerReducer);

  useEffect(() => {
    if (
      page &&
      resultPerPage &&
      page > 0 &&
      resultPerPage > 0 &&
      !firstTimeFetching
    ) {
      dispatch(
        fetchAllClients({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
    }
    setfirstTimeFetching(false);
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllClients({ page: paramsPage, limit: resultPerPage, search })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setData(allClients);
  }, [allClients]);

  useEffect(() => {
    setLoading(fetchClientLoading);
  }, [fetchClientLoading]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteClient(record));
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleNavigate = (id) =>
    navigate(`/settings/clients-manager/update-client/${id}`);

  const columns = [
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">Company name</div>
            <div>{searchInput("company_name")}</div>
          </div>
        );
      },
      dataIndex: "company_name",
      key: "company_name",
      // sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "company_name" && sortedInfo?.order,
      ellipsis: true,
      width: "20%",
      render: (company_name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{company_name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">Contact Person</div>
            <div>{searchInput("listname")}</div>
          </div>
        );
      },
      dataIndex: "listname",
      key: "listname",
      // sorter: (a, b) => a.listname.localeCompare(b.listname),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "listname" && sortedInfo?.order,
      ellipsis: true,
      width: "25%",
      render: (listname, { id }) => (
        <div onClick={() => handleNavigate(id)}>{listname}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Contact Number</div>
            <div>{searchInput("contact_no")}</div>
          </div>
        );
      },
      dataIndex: "contact_no",
      key: "contact_no",
      // sorter: (a, b) => a.contact_no.localeCompare(b.contact_no),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "contact_no" && sortedInfo?.order,
      ellipsis: true,
      width: "15%",
      render: (contact_no, { id }) => (
        <div onClick={() => handleNavigate(id)}>{contact_no}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Email Address</div>
            <div>{searchInput("email")}</div>
          </div>
        );
      },
      dataIndex: "email",
      key: "email",
      // sorter: (a, b) => a.email.localeCompare(b.email),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "email" && sortedInfo?.order,
      ellipsis: true,
      width: "20%",
      render: (email, { id }) => (
        <div onClick={() => handleNavigate(id)}>{email}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">status</div>
            <div>{searchInput("status")}</div>
          </div>
        );
      },
      dataIndex: "status",
      key: "status",
      // sorter: (a, b) => a.status.localeCompare(b.status),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "status" && sortedInfo?.order,
      ellipsis: true,
      width: "10%",
      align: "center",
      render: (status, { id }) => {
        let color;
        let text;
        switch (status) {
          case "active":
            color = "#7cd175";
            text = "Active";
            break;
          case "inactive":
            color = "#fa7470";
            text = "Inactive";
            break;
          default:
            break;
        }
        return (
          <Tag
            onClick={() => handleNavigate(id)}
            color={color}
            style={{
              color: "#231f20",
              width: "70px",
              height: "20px",
              textAlign: "center",
            }}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: null,
      dataIndex: "",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return deleteClientLoading && record.id === currentDelRecord.id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align="baseline">
            <EditIcon
              width={18}
              height={18}
              className="custom-icon"
              onClick={() => {
                handleNavigate(record.id);
                dispatch(setClientDetailsIsEdit(true));
              }}
            />
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon width={18} height={18} className="custom-icon" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className="special-table clients-table"
        columns={columns}
        scroll={{ x: true }}
        onChange={handleChange}
        loading={loading}
        dataSource={data}
        pagination={false}
        sortUpIcon={<UpOutlined />}
        sortDownIcon={<DownOutlined />}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        resultPerPage={resultPerPage}
        setResultPerPage={setResultPerPage}
        debouncedCallApi={debouncedCallApi}
        totalRecord={totalRecords}
      />
    </>
  );
};

export default ClientManagerTable;

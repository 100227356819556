const FETCH_PROD_FIRST_LEVEL = "FETCH_PROD_FIRST_LEVEL";
const FETCH_PROD_FIRST_LEVEL_SUCCESS = "FETCH_PROD_FIRST_LEVEL_SUCCESS";
const FETCH_PROD_FIRST_LEVEL_ERROR = "FETCH_PROD_FIRST_LEVEL_ERROR";
const FIRST_LEVEL_ADD_COMMENT = "FIRST_LEVEL_ADD_COMMENT";
const FIRST_LEVEL_ADD_COMMENT_SUCCESS = "FIRST_LEVEL_ADD_COMMENT_SUCCESS";
const FIRST_LEVEL_ADD_COMMENT_ERROR = "FIRST_LEVEL_ADD_COMMENT_ERROR";
const FETCH_PROD_GRAPH_DATA = "FETCH_PROD_GRAPH_DATA";
const FETCH_PROD_GRAPH_DATA_SUCCESS = "FETCH_PROD_GRAPH_DATA_SUCCESS";
const FETCH_PROD_GRAPH_DATA_ERROR = "FETCH_PROD_GRAPH_DATA_ERROR";
const FETCH_PROD_SECOND_LEVEL = "FETCH_PROD_SECOND_LEVEL";
const FETCH_PROD_SECOND_LEVEL_SUCCESS = "FETCH_PROD_SECOND_LEVEL_SUCCESS";
const FETCH_PROD_SECOND_LEVEL_ERROR = "FETCH_PROD_SECOND_LEVEL_ERROR";
const CREATE_SECOND_LEVEL = "CREATE_SECOND_LEVEL";
const CREATE_SECOND_LEVEL_SUCCESS = "CREATE_SECOND_LEVEL_SUCCESS";
const CREATE_SECOND_LEVEL_ERROR = "CREATE_SECOND_LEVEL_ERROR";
const UPDATE_SECOND_LEVEL = "UPDATE_SECOND_LEVEL";
const UPDATE_SECOND_LEVEL_SUCCESS = "UPDATE_SECOND_LEVEL_SUCCESS";
const UPDATE_SECOND_LEVEL_ERROR = "UPDATE_SECOND_LEVEL_ERROR";
const FETCH_PROD_THIRD_LEVEL = "FETCH_PROD_THIRD_LEVEL";
const FETCH_PROD_THIRD_LEVEL_SUCCESS = "FETCH_PROD_THIRD_LEVEL_SUCCESS";
const FETCH_PROD_THIRD_LEVEL_ERROR = "FETCH_PROD_THIRD_LEVEL_ERROR";
const FETCH_PROD_FOURTH_LEVEL = "FETCH_PROD_FOURTH_LEVEL";
const FETCH_PROD_FOURTH_LEVEL_SUCCESS = "FETCH_PROD_FOURTH_LEVEL_SUCCESS";
const FETCH_PROD_FOURTH_LEVEL_ERROR = "FETCH_PROD_FOURTH_LEVEL_ERROR";
const VERIFY_PIN = "VERIFY_PIN";
const VERIFY_PIN_SUCCESS = "VERIFY_PIN_SUCCESS";
const VERIFY_PIN_ERROR = "VERIFY_PIN_ERROR";
const UPDATE_OPERATOR = "UPDATE_OPERATOR";
const UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS";
const UPDATE_OPERATOR_ERROR = "UPDATE_OPERATOR_ERROR";
const STOP_OPERATOR = "STOP_OPERATOR";
const STOP_OPERATOR_SUCCESS = "STOP_OPERATOR_SUCCESS";
const STOP_OPERATOR_ERROR = "STOP_OPERATOR_ERROR";
const ADD_OPERATOR = "ADD_OPERATOR";
const ADD_OPERATOR_SUCCESS = "ADD_OPERATOR_SUCCESS";
const ADD_OPERATOR_ERROR = "ADD_OPERATOR_ERROR";
const FETCH_PROD_SEARCH_UTILS = "FETCH_PROD_SEARCH_UTILS";
const FETCH_PROD_SEARCH_UTILS_SUCCESS = "FETCH_PROD_SEARCH_UTILS_SUCCESS";
const FETCH_PROD_SEARCH_UTILS_ERROR = "FETCH_PROD_SEARCH_UTILS_ERROR";
const UPDATE_THIRD_LEVEL_ON_UPDATE_FOURTH_LEVEL =
  "UPDATE_THIRD_LEVEL_ON_UPDATE_FOURTH_LEVEL";
const ADD_PRODUCTION_ATTACH = "ADD_PRODUCTION_ATTACH";
const ADD_PRODUCTION_ATTACH_SUCCESS = "ADD_PRODUCTION_ATTACH_SUCCESS";
const ADD_PRODUCTION_ATTACH_ERROR = "ADD_PRODUCTION_ATTACH_ERROR";
const DELETE_PROD_ATTACH = "DELETE_PROD_ATTACH";
const DELETE_PROD_ATTACH_SUCCESS = "DELETE_PROD_ATTACH_SUCCESS";
const DELETE_PROD_ATTACH_ERROR = "DELETE_PROD_ATTACH_ERROR";
const SET_CHAT_RECORD_ID = "SET_CHAT_RECORD_ID";
const FETCH_PREV_CHAT = "FETCH_PREV_CHAT";
const FETCH_PREV_CHAT_SUCCESS = "FETCH_PREV_CHAT_SUCCESS";
const FETCH_PREV_CHAT_ERROR = "FETCH_PREV_CHAT_ERROR";
const SEND_MESSAGE = "SEND_MESSAGE";
const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export {
  FETCH_PROD_FIRST_LEVEL,
  FETCH_PROD_FIRST_LEVEL_SUCCESS,
  FETCH_PROD_FIRST_LEVEL_ERROR,
  FIRST_LEVEL_ADD_COMMENT,
  FIRST_LEVEL_ADD_COMMENT_SUCCESS,
  FIRST_LEVEL_ADD_COMMENT_ERROR,
  FETCH_PROD_GRAPH_DATA,
  FETCH_PROD_GRAPH_DATA_SUCCESS,
  FETCH_PROD_GRAPH_DATA_ERROR,
  FETCH_PROD_SECOND_LEVEL,
  FETCH_PROD_SECOND_LEVEL_SUCCESS,
  FETCH_PROD_SECOND_LEVEL_ERROR,
  CREATE_SECOND_LEVEL,
  CREATE_SECOND_LEVEL_SUCCESS,
  CREATE_SECOND_LEVEL_ERROR,
  UPDATE_SECOND_LEVEL,
  UPDATE_SECOND_LEVEL_SUCCESS,
  UPDATE_SECOND_LEVEL_ERROR,
  FETCH_PROD_THIRD_LEVEL,
  FETCH_PROD_THIRD_LEVEL_SUCCESS,
  FETCH_PROD_THIRD_LEVEL_ERROR,
  FETCH_PROD_FOURTH_LEVEL,
  FETCH_PROD_FOURTH_LEVEL_SUCCESS,
  FETCH_PROD_FOURTH_LEVEL_ERROR,
  VERIFY_PIN,
  VERIFY_PIN_SUCCESS,
  VERIFY_PIN_ERROR,
  UPDATE_OPERATOR,
  UPDATE_OPERATOR_SUCCESS,
  UPDATE_OPERATOR_ERROR,
  STOP_OPERATOR,
  STOP_OPERATOR_SUCCESS,
  STOP_OPERATOR_ERROR,
  ADD_OPERATOR,
  ADD_OPERATOR_SUCCESS,
  ADD_OPERATOR_ERROR,
  FETCH_PROD_SEARCH_UTILS,
  FETCH_PROD_SEARCH_UTILS_SUCCESS,
  FETCH_PROD_SEARCH_UTILS_ERROR,
  UPDATE_THIRD_LEVEL_ON_UPDATE_FOURTH_LEVEL,
  ADD_PRODUCTION_ATTACH,
  ADD_PRODUCTION_ATTACH_SUCCESS,
  ADD_PRODUCTION_ATTACH_ERROR,
  DELETE_PROD_ATTACH,
  DELETE_PROD_ATTACH_SUCCESS,
  DELETE_PROD_ATTACH_ERROR,
  SET_CHAT_RECORD_ID,
  FETCH_PREV_CHAT,
  FETCH_PREV_CHAT_SUCCESS,
  FETCH_PREV_CHAT_ERROR,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  RECEIVE_MESSAGE
};

const CREATE_CLIENT = "CREATE_CLIENT";
const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
const CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
const UPDATE_CLIENT_ERROR = "UPDATE_CLIENT_ERROR";
const CLIENT_DETAILS_IS_EDIT = "CLIENT_DETAILS_IS_EDIT";
const ADD_CLIENT_IN_MANAGER = "ADD_CLIENT_IN_MANAGER";
const FETCH_CLIENT_UTIL_DATA = "FETCH_CLIENT_UTIL_DATA";
const FETCH_CLIENT_UTIL_DATA_SUCCESS = "FETCH_CLIENT_UTIL_DATA_SUCCESS";
const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";
const FETCH_ALL_CLIENTS_SUCCESS = "FETCH_ALL_CLIENTS_SUCCESS";
const FETCH_ALL_CLIENTS_ERROR = "FETCH_ALL_CLIENTS_ERROR";
const DELETE_CLIENT = "DELETE_CLIENT";
const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";
const FETCH_CLIENT = "FETCH_CLIENT";
const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
const FETCH_CLIENT_ERROR = "FETCH_CLIENT_ERROR";
const SEND_DETAILS_TO_CLIENT = "SEND_DETAILS_TO_CLIENT";
const SEND_DETAILS_TO_CLIENT_SUCCESS = "SEND_DETAILS_TO_CLIENT_SUCCESS";
const SEND_DETAILS_TO_CLIENT_ERROR = "SEND_DETAILS_TO_CLIENT_ERROR";
const UPDATE_CLIENT_SETTINGS = "UPDATE_CLIENT_SETTINGS";
const UPDATE_CLIENT_SETTINGS_SUCCESS = "UPDATE_CLIENT_SETTINGS_SUCCESS";
const UPDATE_CLIENT_SETTINGS_ERROR = "UPDATE_CLIENT_SETTINGS_ERROR";
const CLIENT_CLEANUP_FUNC = "CLIENT_CLEANUP_FUNC";

export {
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR,
  ADD_CLIENT_IN_MANAGER,
  CLIENT_DETAILS_IS_EDIT,
  FETCH_CLIENT_UTIL_DATA,
  FETCH_CLIENT_UTIL_DATA_SUCCESS,
  FETCH_ALL_CLIENTS,
  FETCH_ALL_CLIENTS_SUCCESS,
  FETCH_ALL_CLIENTS_ERROR,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
  FETCH_CLIENT,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_ERROR,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_ERROR,
  SEND_DETAILS_TO_CLIENT,
  SEND_DETAILS_TO_CLIENT_SUCCESS,
  SEND_DETAILS_TO_CLIENT_ERROR,
  UPDATE_CLIENT_SETTINGS,
  UPDATE_CLIENT_SETTINGS_SUCCESS,
  UPDATE_CLIENT_SETTINGS_ERROR,
  CLIENT_CLEANUP_FUNC,
};

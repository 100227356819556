import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "./redux/actions";
import { useNavigate } from "react-router";

const UseAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector(state => state.authReducer);

  const validateFunc = (email, password) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      message.error("Email is invalid.");
      return false;
    } else if (password.length < 6) {
      message.error("Password should contain at least 6 characters.");
      return false;
    } else return true;
  };

  const onFinish = (values) => {
    let { email, password } = values;
    email = email.trim();
    if (validateFunc(email, password)) {
      return dispatch(loginUser({ email, password, navigate }));
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return [onFinish, onFinishFailed, loading];
};

export default UseAuth;

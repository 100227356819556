export const handleChangeLevel = (value, name, form) => {
  const { level1, level2, level3, ...rest } = form.getFieldsValue();

  if (name === "level3") {
    if (value) {
      form.setFieldsValue({
        ...rest,
        level1: true,
        level2: true,
        level3: true,
      });
    } else
      form.setFieldsValue({
        ...rest,
        level3: false,
      });
  }
  if (name === "level2") {
    if (value) {
      form.setFieldsValue({
        ...rest,
        level1: true,
        level2: true,
      });
    } else
      form.setFieldsValue({
        ...rest,
        level2: false,
        level3: false,
      });
  }

  if (name === "level1") {
    if (value) {
      form.setFieldsValue({
        ...rest,
        level1: true,
      });
    } else
      form.setFieldsValue({
        ...rest,
        level1: false,
        level2: false,
        level3: false,
      });
  }
};

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_ERROR = "LOGIN_ERROR";
const LOGOUT = "LOGOUT";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const LOGOUT_ERROR = "LOGOUT_ERROR";
const SET_USER = "SET_USER";

export {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_USER,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
};

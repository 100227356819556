import moment from "moment";
import React, { useEffect, useState } from "react";

const UseTimer = ({ date }) => {
  const [startTime, setStartTime] = useState(date);
  const [timeString, setTimeString] = useState("");

  useEffect(() => {
    setStartTime(date);
  }, []);

  const handleTimer = () => {
    var a = moment(startTime);
    var now = moment();

    let delta = Math.abs(a - now) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = Math.floor(delta % 60);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeString(handleTimer());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <span> {timeString} </span>;
};

export default UseTimer;

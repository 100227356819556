import React, { useEffect, useState } from "react";
import { Col, Input, Row, message, Select, Checkbox, Form, Spin } from "antd";
import { ReactComponent as SaveIcon } from "../../../assets/save-icon.svg";
import sampleImg from "../../../assets/user-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  fetchUserDetailsUtils,
  sendDetailsToUser,
  setUserDetailsIsEdit,
  updateUser,
} from "../redux/actions";
import CreatePasswordModal from "./createPasswordModal";
import SpinnerComponent from "../../../components/spinner";
import CreatePinModal from "./createPinModal";
import { baseURL, isValidNumber } from "../../../globalUtils/axiosConfig";
import { capitalizeFirstLetterofEachWord } from "../../../globalUtils/capitalizeFirstLetterofEachWord";
import UpdatePasswordModal from "./updatePasswordModal";
import UseGetUserRole from "../../../components/useGetUserRole";

const UserDetailsSection = ({
  isUserUpdate,
  isProfileCase,
  canEditEveryThing,
}) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [userProfileImg, setUserProfileImg] = useState(null);
  const [isUserImgSelected, setIsUserImgSelected] = useState(false);
  const [userType, setUserType] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [utils, setUtils] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [pin, setPin] = useState("");

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;

  const {
    createUserLoading,
    currentUserObj,
    userDetailsIsEdit,
    utilsData,
    sendDetailLoading,
  } = useSelector((state) => state.userManagerReducer);
  const [isSuperAdmin] = UseGetUserRole();

  useEffect(() => {
    dispatch(fetchUserDetailsUtils());
  }, []);

  useEffect(() => {
    // setUserType(currentUserObj.user_type);
    // form.setFieldsValue(currentUserObj);
    // setCurrentUser(currentUserObj);
    if (
      typeof currentUserObj?.user_type === "number" &&
      utilsData?.user_types?.length > 0
    ) {
      let item = utilsData.user_types.filter(
        (el) => el.value === currentUserObj?.user_type
      )[0];
      setUserType(item.key);
      let statusItem = utilsData.status.filter(
        (el) => el.value === currentUserObj?.status
      )[0];

      setCurrentUser({
        ...currentUserObj,
        user_type: item.key,
        status: statusItem.key,
      });

      form.setFieldsValue({
        ...currentUserObj,
        user_type: item.key,
        status: statusItem.key,
      });
    } else {
      setCurrentUser(currentUserObj);
      form.setFieldsValue(currentUserObj);
      setUserType(currentUserObj.user_type);
    }
    setUtils(utilsData);
  }, [currentUserObj, utilsData]);

  useEffect(() => {
    setLoading(createUserLoading);
    // if (createUserLoading) {
    //   setIsUserImgSelected(false);
    // }
  }, [createUserLoading]);

  useEffect(() => {
    setIsEdit(userDetailsIsEdit);
  }, [userDetailsIsEdit]);

  const changeHandler = (event) => {
    const image = event.target.files[0];
    if (!image) {
      return false;
    }
    if (!image.name.match(/\.(jpg|png)$/)) {
      message.error("Photo should be png or jpg format.");
      return false;
    }
    if (image.size > 5120000) {
      message.error("Photo size should be less than 5MB.");
      return false;
    }
    setUserProfileImg(event.target.files[0]);
    // handleChange("profile_pic", event.target.files[0]);
    setIsUserImgSelected(true);
  };

  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    if (password?.length < 6 && !isUserUpdate) {
      return message.error("Password is required");
    }

    let updatedPin = "";
    if (userType === "production_user" && pin?.length !== 5 && !isUserUpdate) {
      return message.error("Pin is required");
    } else if (userType === "production_user" && isUserUpdate) {
      updatedPin = pin?.length === 5 ? pin : null;
    } else {
      updatedPin = pin;
    }

    switch (userType) {
      case "admin":
        const {
          user_manager,
          client_manager,
          activity_type,
          department_emails,
          ...res
        } = values;

        let permissions = {
          user_manager,
          client_manager,
          activity_type,
          department_emails,
        };

        let hasPermissions = false;
        let permissionValuesArr = Object.values(permissions);

        permissionValuesArr.forEach((element) => {
          if (element !== undefined) {
            hasPermissions = true;
          }
        });

        if (!hasPermissions) {
          return message.error("Permissions required!");
        }

        isUserUpdate
          ? dispatch(
              updateUser({
                ...res,
                ...(password.length > 5 && { password }),
                ...(userProfileImg && { profile_pic: userProfileImg }),
                permissions,
                id: currentUserObj.id,
              })
            )
          : dispatch(
              createUser({
                ...res,
                password,
                profile_pic: userProfileImg,
                permissions,
              })
            );
        break;
      default:
        isUserUpdate
          ? dispatch(
              updateUser({
                ...values,
                ...(password.length > 5 && { password }),
                ...(userProfileImg && { profile_pic: userProfileImg }),
                ...(userType === "production_user" && { pin: updatedPin }), //  {pin}
                id: currentUserObj.id,
              })
            )
          : dispatch(
              createUser({
                ...values,
                password,
                profile_pic: userProfileImg,
                ...(userType === "production_user" && { pin: updatedPin }), //  {pin}
              })
            );
        break;
    }
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleSave = () => {
    form.submit();
  };

  const capitalizeFirstLetter = (str) =>
    str && str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      {isUserUpdate ? (
        <UpdatePasswordModal
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          email={currentUserObj.email}
          isClient={false}
        />
      ) : (
        <CreatePasswordModal
          setPassword={setPassword}
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          isUpdate={isUserUpdate}
        />
      )}
      {(userType === "production_user" || isSuperAdmin) && (
        <CreatePinModal
          isVisible={pinModalOpen}
          setVisible={setPinModalOpen}
          setPin={setPin}
          isUserUpdate={isUserUpdate}
        />
      )}
      <Row>
        <Col span={24} className="heading-col">
          <Row align="middle" justify="space-between">
            <Col>
              <div>
                <span className="heading-span">Users Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                <EditIcon
                  width={16}
                  fill="#eb078e"
                  height={16}
                  onClick={() => dispatch(setUserDetailsIsEdit(true))}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section ${!isEdit && "is-edit"}`}
        >
          <Row justify="start">
            <Col md={5} lg={5} xl={5} xxl={4} className="photo-col">
              <div>
                <span>Photo</span>
                <div className={`img-parent-div ${isEdit && "edit-image"}`}>
                  <div className="img-nested-div">
                    <img
                      width="100%"
                      height="100%"
                      src={
                        isUserImgSelected
                          ? URL.createObjectURL(userProfileImg)
                          : currentUser?.profile_picture
                          ? `${baseURL}${currentUserObj?.profile_picture}`
                          : sampleImg
                      }
                      alt="user-profile"
                    />
                  </div>
                  {isEdit && (
                    <div className="edit-icon-div">
                      <input
                        readOnly={loading}
                        disabled={!isEdit}
                        type="file"
                        name="profileImg"
                        id="profileImg"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={changeHandler}
                      />
                      <label htmlFor="profileImg">
                        <EditIcon height={14} width={14} fill="#eb078e" />
                      </label>
                    </div>
                  )}
                </div>
                {isEdit && (
                  <div className="text-div">
                    Upload a JPG or PNG file. Recommended image size 800 × 800:
                    Max 5 MB
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={isEdit ? 19 : 15}
              lg={isEdit ? 19 : 15}
              xl={isEdit ? 19 : 15}
              xxl={16}
            >
              <Form
                {...formItemLayout}
                form={form}
                name="user-details"
                onFinish={onFinish}
                initialValues={currentUser}
                scrollToFirstError
                labelWrap={true}
                layout="horizontal"
                className="user-detail-form"
                requiredMark={false}
              >
                <Row gutter={[30, 0]}>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input First Name!",
                        },
                      ]}
                      name="first_name"
                      label="First Name"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Last Name!",
                        },
                      ]}
                      name="surname"
                      label="last name"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select status!",
                        },
                      ]}
                      name="status"
                      label="status"
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit || !canEditEveryThing}
                        // readOnly={true}
                        className="department-select"
                        dropdownClassName="select-dropdown-custom"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        showSearch
                        placeholder="Status"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {utils?.status?.map(({ key }) => {
                          return (
                            <Option key={key} value={key}>
                              {capitalizeFirstLetter(key || "")}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Username!",
                        },
                      ]}
                      name="username"
                      label="username"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="Username"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <label>password</label>
                    <div
                      className="password-div"
                      style={{
                        cursor: !isEdit && "text",
                      }}
                    >
                      <span
                        onClick={() => isEdit && setPasswordModalOpen(true)}
                      >
                        {`${isUserUpdate ? "Change" : "Create"} Password`}
                      </span>
                    </div>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      name="email"
                      label="Email Address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid Email!",
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isUserUpdate
                        placeholder="Email Address"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input ID Number!",
                        },
                        { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="id_number"
                      label="ID Number"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="ID Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Employee Number!",
                        },
                        // { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="employee_number"
                      label="Employee Number"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="Employee Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Contact Number!",
                        },
                        { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="telephone"
                      label="Contact Number"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="Contact Number"
                      />
                    </Form.Item>
                  </Col>
                  {/* {!isProfileCase && ( */}
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select User Type!",
                        },
                      ]}
                      name="user_type"
                      label="user type"
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit || !canEditEveryThing}
                        className="department-select"
                        dropdownClassName="select-dropdown-custom"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        showSearch
                        placeholder="User Type"
                        optionFilterProp="children"
                        onChange={(e) => setUserType(e)}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.some((item) =>
                            item
                              .toLocaleLowerCase()
                              .includes(input.toLocaleLowerCase())
                          )
                        }
                      >
                        {utils?.user_types?.map(({ key }) => {
                          return (
                            <Option key={key} value={key}>
                              {capitalizeFirstLetterofEachWord(key || "")}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* )} */}
                  {userType === "production_user" && (
                    <Col md={12} lg={12} xl={12} xxl={8}>
                      <label>Production user pin</label>
                      <div
                        className="password-div"
                        onClick={() => setPinModalOpen(true)}
                      >
                        <span>
                          {`${isUserUpdate ? "Change" : "Create"} PIN`}
                        </span>
                      </div>
                    </Col>
                  )}
                  {userType === "sales_user" && (
                    <>
                      <Col md={12} lg={12} xl={12} xxl={8}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input target!",
                            },
                            { pattern: isValidNumber, message: "Invalid" },
                          ]}
                          name="target"
                          label="target"
                        >
                          <Input
                            readOnly={loading}
                            disabled={!isEdit || !canEditEveryThing}
                            placeholder="Insert Target for Sales Rep"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={12} xl={12} xxl={8}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input Quick Easy user ID!",
                            },
                          ]}
                          name="quick_easy_id"
                          label="Quick Easy user ID"
                        >
                          <Select
                            style={{
                              width: "100%",
                            }}
                            disabled={!isEdit || !canEditEveryThing}
                            // readOnly={true}
                            className="department-select"
                            dropdownClassName="select-dropdown-custom"
                            getPopupContainer={() =>
                              document.getElementsByClassName(
                                "ant-layout-content layout-content site-layout-background custom-textarea"
                              )[0]
                            }
                            showSearch
                            placeholder="Quick Easy User ID For Sales Rep"
                            optionFilterProp="children"
                            // onChange={onChange}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {utils?.sales_reps?.map(({ listname, staffid }) => {
                              return (
                                listname && (
                                  <Option key={staffid} value={staffid}>
                                    {listname === "Unselect"
                                      ? "Unselect"
                                      : `${staffid} - ${listname}`}
                                  </Option>
                                )
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {userType === "admin" && (
                    <Col span={24}>
                      <Row gutter={[30, 0]} className="permissions-div">
                        <Col span={24}>
                          <label>Permissions</label>
                        </Col>
                        <Col md={12} lg={12} xl={12} xxl={8}>
                          <Form.Item
                            name="user_manager"
                            valuePropName="checked"
                          >
                            <Checkbox
                              disabled={!isEdit || !canEditEveryThing}
                              onChange={onChange}
                            >
                              User Manager
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xl={12} xxl={8}>
                          <Form.Item
                            name="client_manager"
                            valuePropName="checked"
                          >
                            <Checkbox
                              disabled={!isEdit || !canEditEveryThing}
                              onChange={onChange}
                            >
                              Clients Manager
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        {/* <Col md={12} lg={12} xl={12} xxl={8}>
                          <Form.Item name="user_types" valuePropName="checked">
                            <Checkbox
                              disabled={!isEdit || !canEditEveryThing}
                              onChange={onChange}
                            >
                              User Types
                            </Checkbox>
                          </Form.Item>
                        </Col> */}
                        <Col md={12} lg={12} xl={12} xxl={8}>
                          <Form.Item
                            name="activity_type"
                            valuePropName="checked"
                          >
                            <Checkbox
                              disabled={!isEdit || !canEditEveryThing}
                              onChange={onChange}
                            >
                              Activity Types
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xl={12} xxl={8}>
                          <Form.Item
                            name="department_emails"
                            valuePropName="checked"
                          >
                            <Checkbox
                              disabled={!isEdit || !canEditEveryThing}
                              onChange={onChange}
                            >
                              Department Emails
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        {/* <Col md={12} lg={12} xl={12} xxl={8}>
                          <Form.Item
                            name="production_statuses"
                            valuePropName="checked"
                          >
                            <Checkbox
                              disabled={!isEdit || !canEditEveryThing}
                              onChange={onChange}
                            >
                              Production Statuses
                            </Checkbox>
                          </Form.Item>
                        </Col> */}
                      </Row>
                    </Col>
                  )}
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Job Title!",
                        },
                      ]}
                      name="job_title"
                      label="Job Title"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canEditEveryThing}
                        placeholder="Job Title"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>

            {currentUserObj?.id && !isProfileCase && !isEdit && (
              <Col span={4}>
                <div className="send-details-parent-div">
                  <Spin
                    spinning={sendDetailLoading}
                    className={`send-details-div`}
                  >
                    <div
                      className={`send-details-div ${
                        sendDetailLoading && "disabled-onclick"
                      }`}
                      onClick={() =>
                        dispatch(sendDetailsToUser(currentUserObj.id))
                      }
                    >
                      <span>Send Login</span>
                      <span>Details to User</span>
                    </div>
                  </Spin>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <div className="empty-div"></div>
    </>
  );
};

export default UserDetailsSection;

import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import * as authActions from "../../../login/redux/actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleCreateClient({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "permissions") {
        fd.append("permissions", JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.post(`${baseURL}/api/v1/clients`, fd, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createClientSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createClientError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateClient({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "permissions") {
        fd.append("permissions", JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.patch(
      `${baseURL}/api/v1/clients/${data.id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateClientSuccess(response.data.data));
      let user = JSON.parse(localStorage.getItem("user"));
      const { id } = response.data.data;
      if (user?.id === id && user?.user_type === "client") {
        let updatedUser = { ...user, ...response.data.data };
        yield put(authActions.setUser(updatedUser));
        localStorage.setItem("user", JSON.stringify(updatedUser));
      }
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateClientError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetcClientDetailsUtils({ data }) {
  const { search, ...rest } = data;
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/clients/fetch_client_details_v2`,
      {
        ...search,
      }
    );
    const { success } = response.data;

    if (success) {
      yield put(
        actions.fetchCLientDetailsUtilsSuccess({
          ...response.data.data,
          rest: { [Object.keys(rest)[0]]: false },
        })
      );
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchAllClients({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/clients`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllClientsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
      yield put(actions.fetchAllClientsError({}));
    }
  } catch (error) {
    AntdAlert.error("Something went wrong!");
    yield put(actions.fetchAllClientsError({}));
    console.log(error);
  }
}

export function* handleDeleteClient({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/api/v1/clients/${data.id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);

      yield put(actions.deleteClientSuccess(response.data.data));
    } else if (!success) {
      yield put(actions.deleteClientError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteClientError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleFetchClient({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/clients/${data}`);
    const { success } = response.data;

    if (success) {
      const { permissions, ...res } = response.data.data;
      yield put(actions.fetchClientSuccess({ ...res, ...permissions }));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSendDetailsToClient({ data }) {
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/clients/send_client_invite?id=${data}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.sendDetailsToClientSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.sendDetailsToClientError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    const { status } = response;
    if (status == 500) {
      AntdAlert.error("Something went wrong!");
      yield put(actions.sendDetailsToClientError());
    }
  }
}

export function* handleUpdateClientSettings({ data }) {
  const { loadingObj } = data;

  let obj = {};
  if (loadingObj?.popupLoading) {
    obj = { popupLoading: false };
  }
  if (loadingObj?.emailLoading) {
    obj = { emailLoading: false };
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/clients/${data.data.id}`,
      {
        ...data.data,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.updateClientSettingsSuccess({
          data: response.data.data,
          loadingObj: obj,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(
        actions.updateClientSettingsError(response.data?.errors?.client)
      );
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

// function* watcherSaga() {
//   yield takeLatest(constants.FETCH_ALL_CLIENTS, handleFetchAllClients);
//   yield takeLatest(constants.DELETE_CLIENT, handleDeleteClient);
//   yield takeLatest(
//     constants.FETCH_CLIENT_UTIL_DATA,
//     handleFetcClientDetailsUtils
//   );
// }

// export function* rootSaga() {
//   yield all([watcherSaga()]);
// }

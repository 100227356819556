import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { priceFormatTwoDecimal } from "../../globalUtils/convertStrToMinutes";

const keys = ["Actual", "Target"];
const colors = ["#118DFF", "#eb078e"];

const ValueOutside = ({ bars, yScale }) => {
  return bars.map((bar) => {
    const { key, width, x, data } = bar;
    const { value } = data;
    const labelMargin = 20;

    return (
      <g
        key={key}
        transform={`translate(${x}, ${yScale(value) - labelMargin})`}
        fill="#757575"
      >
        <text
          fontSize="11px"
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {`R${priceFormatTwoDecimal((value / 1000000).toFixed(2))}M`}
        </text>
      </g>
    );
  });
};

const CustomLeftLabel = (data) => {
  const { x, y, value } = data;

  return (
    <g>
      {/* <rect x={x + 15 * 3} y={0} width={3} height="10" fill="#B1B1B1" /> */}
      {/* <rect x={x - 80 / 2} y={4} width={80} height="3" fill="#B1B1B1" /> */}

      <text x={-30} y={y + 4} fontSize="12px" fill="#757575">
        {`${value / 1000000}M`}
      </text>
    </g>
  );
};

const TotalActualVsTargetGraph = ({ data }) => {
  return (
    <div style={{ height: "400px" }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="id"
        margin={{
          top: 50,
          right: 0,
          bottom: 185,
          left: 70,
        }}
        groupMode="grouped"
        colors={colors}
        padding={0.3}
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={false}
        enableGridY={true}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: null,
              },
            },
            domain: {
              line: {
                stroke: "",
                strokeWidth: 2,
              },
            },
            legend: {
              text: {
                fontWeight: "bold",
                letterSpacing: "1px",
              },
            },
          },
          grid: {
            line: {
              stroke: `rgb(231 232 236 / 80%)`,
              strokeWidth: 2,
              strokeDasharray: "2, 4",
            },
          },
        }}
        layers={["grid", "axes", "bars", "markers", ValueOutside]}
        legends={[
          {
            dataFrom: "keys",
            data: keys.map((id, index) => ({
              color: colors[index],
              id,
              label: id === "Actual" ? 1 : 2,
            })),
            anchor: "bottom",
            direction: "row",
            translateY: 63,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: "left-to-right",
            symbolSize: 20,
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "SumTargetSales and SumA...",
          legendPosition: "middle",
          legendOffset: -60,
          renderTick: CustomLeftLabel,
          tickValues: 3,
        }}
      />
    </div>
  );
};

export default TotalActualVsTargetGraph;

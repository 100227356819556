import React, { useEffect, useState } from 'react';
import { Col, Row, message } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import syncImg from '../../assets/sync.png';
import SpinnerComponent from '../spinner';

let url = `${baseURL}/api/v1/firebird/`;

const GetSyncData = () => {
  const [loading, setLoading] = useState(false);
  const [lastSynced, setLastSynced] = useState(null);

  const handleSyncData = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${url}sync_data`);
      const { success } = res.data;
      if (success) {
        const { last_sync_time } = res.data.data;
        setLastSynced(last_sync_time);
        message.success(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      message.error('Something went wrong!');
      setLoading(false);
    }
  };

  const getLastSyncData = async () => {
    setLoading(true);

    try {
      const res = await axios.get(`${url}last_sync`);
      const { success, data } = res.data;
      if (success) {
        setLastSynced(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong!');
      console.log('error', error);
    }
  };

  useEffect(() => {
    getLastSyncData();

    return () => {};
  }, []);

  let dateStr = lastSynced
    ? moment(lastSynced).format('DD MMM YYYY [,] HH:mm')
    : null;

  return (
    <>
      <Row align='bottom'>
        <Col className='sync-col'>
          Last Synced: {dateStr}
          {loading ? (
            <SpinnerComponent fontSize={12} />
          ) : (
            <img
              title='Sync Now'
              onClick={handleSyncData}
              src={syncImg}
              alt='sync-icon'
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default GetSyncData;

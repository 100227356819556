// import consumer from "./consumer";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cableBaseURL } from "../../globalUtils/axiosConfig";
import { newNotificationAdded } from "./notificationsRedux/notificationActions";

let Subscription = null;

const handleChannel = (id, dispatchFun) => {
  if (window.App === undefined) {
    window.App = {
      cable: window.Cable.createConsumer(cableBaseURL),
    };
  }

  Subscription = window.App.cable.subscriptions.create(
    {
      channel: "NotificationChannel",
      user_id: id,
    },
    {
      connected: function () {
        console.log(`NotificationChannel connected with user id ${id}`);
      },
      disconnected: function () {
        console.log("NotificationChannel disconnected");
      },
      rejected: function () {
        console.log("NotificationChannel rejected");
      },
      received: function (data) {
        console.log("NotificationChannel received", data);
        dispatchFun(newNotificationAdded(data.data));
      },
    }
  );

  return Subscription;
};

const NotificationChannel = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    handleChannel(user?.id, dispatch);
  }, [user]);

  useEffect(() => {
    return () => {
      console.log("App.unsubscribe()................");
      // Subscription?.disconnected();
      Subscription?.unsubscribe();
      // dispatch({ type: SET_QUOTE_ID, payload: null });
      // window.App.cable.subscriptions.remove(window.App.subscription)
      // window.Cable.disconnect();
    };
  }, []);

  return <></>;
};

export default NotificationChannel;

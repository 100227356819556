import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Tooltip } from "antd";
import HoursByActivityGraph from "./HoursByActivityGraph";
import { sortByAmount, sortByAlpha } from "./utils";
import SalesRepAndActivityGraph from "./salesRepAndActivityGraph";
import TotalHours from "./totalHours";
import { ReactComponent as SortAlphabetically } from "../../assets/sortAlphabetically.svg";
import { ReactComponent as SortByAmount } from "../../assets/sortByAmount.svg";
import SpinnerComponent from "../../components/spinner";

const ActivitiesReporting = () => {
  const [salesUserActivitiesdata, setSalesUserActivitiesData] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    alphaSort: false,
    sortByAmount: false,
  });
  const { sales_user_activities, fetchingLoading, colorsObjArr } = useSelector(
    (state) => state.activitiesReducer
  );

  useEffect(() => {
    setSalesUserActivitiesData(sales_user_activities);
  }, [sales_user_activities]);

  const handleSortClientByAlpha = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      alphaSort: !prev.alphaSort,
    }));
    let res = sortByAlpha(salesUserActivitiesdata, sortOrder.alphaSort);
    setSalesUserActivitiesData(res);
  };

  const handleSortClientByAmount = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      sortByAmount: !prev.sortByAmount,
    }));
    let res = sortByAmount(salesUserActivitiesdata, sortOrder.sortByAmount);
    setSalesUserActivitiesData(res);
  };

  return (
    <Row className="production-manager-parent-row">
      <Col span={24} className="heading-col">
        <Row>
          <Col>
            <span className="heading-span">Activities Reporting</span>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="graph-section">
        {fetchingLoading ? (
          <div className="spinner-div">
            <SpinnerComponent fontSize={50} />
          </div>
        ) : (
          <Row gutter={[40, 40]}>
            <Col md={24} lg={24} xl={24} xxl={12}>
              <div className="production-by-client-div">
                <span className="graph-text-span">
                  Hours by Sales Rep and Activity
                </span>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title="Sort by Alphabetically"
                >
                  <SortAlphabetically
                    className="sort-icons"
                    onClick={handleSortClientByAlpha}
                  />
                </Tooltip>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Amount`}
                >
                  <SortByAmount
                    className="sort-icons"
                    onClick={handleSortClientByAmount}
                  />
                </Tooltip>
              </div>
              <div className="graph-label-with-text">
                <div
                  className="actual-div"
                  style={{ display: "block", fontWeight: 600 }}
                >
                  Format:
                </div>
                <Row gutter={[10, 5]} justify="start" align="top">
                  {colorsObjArr.map((el) => {
                    const { key, color } = el;
                    return (
                      <Col key={key}>
                        <div className="actual-div">
                          <span
                            className="dot"
                            style={{
                              backgroundColor: color,
                            }}
                          ></span>
                          {key}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <SalesRepAndActivityGraph data={salesUserActivitiesdata} />
            </Col>
            <Col md={17} lg={18} xl={18} xxl={9}>
              <span className="graph-text-span">Hours by Activity</span>
              <HoursByActivityGraph />
            </Col>
            <Col md={7} lg={6} xl={6} xxl={3}>
              <div style={{ textAlign: "center" }}>
                <span className="graph-text-span">Total Hours</span>
              </div>
              <TotalHours />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ActivitiesReporting;

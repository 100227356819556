import { Tooltip } from "antd";
import moment from "moment";
import * as constants from "./constants";

const initialState = {
  chatData: [],
  fetchChatLoading: false,
  currentChatRecordId: null,
  sendMsgLoading: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CHAT_RECORD_ID:
      const { id } = action.data;
      return {
        ...state,
        fetchChatLoading: id,
        currentChatRecordId: id,
      };
    case constants.FETCH_PREV_CHAT:
      return {
        ...state,
        fetchChatLoading: true,
        chatData: [],
        currentChatRecordId: action.data.parent.id,
      };
    case constants.FETCH_PREV_CHAT_SUCCESS:
      let updateData = [];
      action.data.map((el) => {
        const { content, created_at } = el;

        updateData.push({
          ...el,
          actions: [<span key="comment-list-reply-to-0">Reply to</span>],
          author: "John McClane",
          avatar: "https://joeschmoe.io/api/v1/random",
          content: <p>{content}</p>,
          datetime: (
            <Tooltip
              title={moment(created_at)
                .subtract(1, "days")
                .format("YYYY-MM-DD HH:mm:ss")}
            >
              <span>{moment(created_at).subtract(1, "days").fromNow()}</span>
            </Tooltip>
          ),
        });
      });

      return {
        ...state,
        chatData: updateData,
        fetchChatLoading: false,
      };
    case constants.FETCH_PREV_CHAT_ERROR:
      return {
        ...state,
        fetchChatLoading: false,
      };

    case constants.SEND_MESSAGE:
      return {
        ...state,
        sendMsgLoading: true,
      };
    case constants.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMsgLoading: false,
      };
    case constants.SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMsgLoading: false,
      };
    case constants.RECEIVE_MESSAGE:
      const { chatItem } = action;

      const newObj = {
        ...chatItem,
        actions: [<span key="comment-list-reply-to-0">Reply to</span>],
        author: "John McClane",
        avatar: "https://joeschmoe.io/api/v1/random",
        content: <p>{chatItem?.content}</p>,
        datetime: (
          <Tooltip
            title={moment(chatItem?.created_at)
              .subtract(1, "days")
              .format("YYYY-MM-DD HH:mm:ss")}
          >
            <span>
              {moment(chatItem?.created_at).subtract(1, "days").fromNow()}
            </span>
          </Tooltip>
        ),
      };

      return {
        ...state,
        sendMsgLoading: false,
        chatData: [...state.chatData, newObj],
      };

    default:
      return state;
  }
};

export default chatReducer;

import * as constants from "./constants";

const initialState = {
  graphData: {},
  fetchLoading: false,
};

const salesGraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_SALES_GRAPH:
      return {
        ...state,
        fetchLoading: true,
      };
    case constants.FETCH_SALES_GRAPH_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        graphData: action.data,
      };
    case constants.FETCH_SALES_GRAPH_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };
    default:
      return state;
  }
};

export default salesGraphReducer;

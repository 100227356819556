import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Input, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AMultiSelect from "../../components/AMultiSelect";
import CustomCalendar from "../../components/calendar";
import CustomPagination from "../../components/customPagination/CustomPagination";
import { priceFormatTwoDecimal } from "../../globalUtils/convertStrToMinutes";
import {
  fetchSalesData,
  fetchSalesGraphData,
  fetchSalesSecondLevelData,
  fetchSalesUtilsData,
} from "./redux/actions";
import { getOptions } from "./utils";

const SalesManagerTable = () => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [expandedSortedInfo, setExpandedSortedInfo] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [data, setdata] = useState([]);
  const [salesRepCheckedList, setSalesRepCheckedList] = useState([]);
  const [clientCheckedList, setClientCheckedList] = useState([]);
  const [contactPersonCheckedList, setContactPersonCheckedList] = useState([]);
  const [soNumCheckedList, setSoNumCheckedList] = useState([]);
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [search, setSearch] = useState({});

  const dispatch = useDispatch();

  const {
    salesDataObj,
    fetchLoading,
    totalRecords,
    clientOptions,
    salesRepsOptions,
    soNumbersOptions,
    fetchUtilsLoading,
    contactPersonsOptions,
  } = useSelector((state) => state.salesReducer);

  const { secondLevelData, loading } = useSelector(
    (state) => state.salesSecondLevelReducer
  );

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchSalesData({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
      dispatch(fetchSalesGraphData({ search }));
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchSalesData({ page: paramsPage, limit: resultPerPage, search })
      );
      dispatch(fetchSalesGraphData({ search }));
      setPage(1);
    }
  }, [defaultPageOneReq]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    if (salesDataObj?.orders) {
      setdata(salesDataObj?.orders);
    }
  }, [salesDataObj]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        let { value } = e.target;
        setSearch({
          ...search,
          [key]: value > 0 ? Number(Number(value).toFixed(2)) : value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        sodate_start: "",
        sodate_end: "",
      });
    } else {
      setSearch({
        ...search,
        sodate_start: value[0],
        sodate_end: value[1],
      });
    }
    let params =
      value === null
        ? {}
        : {
            sodate_start: value[0],
            sodate_end: value[1],
          };
    dispatch(fetchSalesUtilsData(params));

    debouncedDefaultPageOneCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedDefaultPageOneCallApi();
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handlePrice = (value) => {
    return `R${value && !isNaN(value) && priceFormatTwoDecimal(value)}`;
  };

  const expandedRowRender = () => {
    const columns = [
      {
        title: "Items description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder:
          expandedSortedInfo?.columnKey === "description" &&
          expandedSortedInfo?.order,
        ellipsis: true,
        width: 550,
        render: (text) => <div style={{ whiteSpace: "normal" }}>{text}</div>,
      },
      {
        title: "unit price ex vat",
        dataIndex: "totalunit",
        key: "totalunit",
        sorter: (a, b) => Number(a.totalunit) - Number(b.totalunit),
        sortOrder:
          expandedSortedInfo?.columnKey === "totalunit" &&
          expandedSortedInfo?.order,
        ellipsis: true,
        width: 120,
        align: "right",
        render: (totalunit) => handlePrice(totalunit),
      },
      {
        title: "quantity",
        key: "qty",
        dataIndex: "qty",
        sorter: (a, b) => b.qty - a.qty,
        sortOrder:
          expandedSortedInfo?.columnKey === "qty" && expandedSortedInfo?.order,
        ellipsis: true,
        align: "right",
        width: 120,
      },
      {
        title: "Total ex vat",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => Number(a.total) - Number(b.total),
        sortOrder:
          expandedSortedInfo?.columnKey === "total" &&
          expandedSortedInfo?.order,
        ellipsis: true,
        width: 120,
        align: "right",
        render: (total) => handlePrice(total),
      },
      {
        title: "vat",
        dataIndex: "vatamt",
        key: "vatamt",
        sorter: (a, b) => Number(a.vatamt) - Number(b.vatamt),
        sortOrder:
          expandedSortedInfo?.columnKey === "vatamt" &&
          expandedSortedInfo?.order,
        ellipsis: true,
        width: 120,
        align: "right",
        render: (vatamt) => handlePrice(vatamt),
      },
      {
        title: "Total Incl Vat",
        dataIndex: "totalvat",
        key: "totalvat",
        sorter: (a, b) => Number(a.totalvat) - Number(b.totalvat),
        sortOrder:
          expandedSortedInfo?.columnKey === "totalvat" &&
          expandedSortedInfo?.order,
        ellipsis: true,
        width: 120,
        align: "right",
        render: (totalvat) => handlePrice(totalvat),
      },
    ];

    const handleExpendedChange = (pagination, filters, sorter) => {
      setExpandedSortedInfo(sorter);
    };

    return (
      <Table
        // scroll={{ x: 1125 }}
        className="custom-expanded-table table-second-level without-search-header"
        columns={columns}
        dataSource={secondLevelData}
        pagination={false}
        loading={loading}
        onChange={handleExpendedChange}
      />
    );
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes("all")) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes("not-all")) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const columns = [
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={"D MMM YYYY"}
                handleDateInput={handleDateInput}
              />
            </div>
          </div>
        );
      },
      dataIndex: "sodate",
      key: "sodate",
      // sorter: (a, b) => moment(a.sodate).unix() - moment(b.sodate).unix(),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "sodate" && sortedInfo?.order,
      ellipsis: true,
      width: 225,
      render: (date) => {
        return moment(date).format("DD MMMM YYYY");
      },
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div>
                    <div style={{ color: "transparent" }}>_</div>
                    <div>Client</div>
                  </div>
                  <div>
                    <AMultiSelect
                      debouncedCallApi={debouncedDefaultPageOneCallApi}
                      disabled={false}
                      placeholder=""
                      selectTags={clientCheckedList}
                      onSelectChange={(value) =>
                        handleSelectOnchange(
                          value,
                          clientOptions,
                          "cusid",
                          setClientCheckedList
                        )
                      }
                      data={getOptions(clientOptions, clientCheckedList) || []}
                      filterOption={(input, option) => {
                        // return (
                        //   option.props?.children.props?.children.props?.children.props?.children.props?.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // );
                        return (
                          option?.label
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{ color: "transparent" }}>_</div>
                    <div>(Contact Person)</div>
                  </div>
                  <div>
                    <AMultiSelect
                      debouncedCallApi={debouncedDefaultPageOneCallApi}
                      disabled={false}
                      placeholder=""
                      selectTags={contactPersonCheckedList}
                      onSelectChange={(value) =>
                        handleSelectOnchange(
                          value,
                          contactPersonsOptions,
                          "cuscontactid",
                          setContactPersonCheckedList
                        )
                      }
                      data={
                        getOptions(
                          contactPersonsOptions,
                          contactPersonCheckedList
                        ) || []
                      }
                      filterOption={(input, option) => {
                        return (
                          option?.label
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
      dataIndex: "client_name",
      key: "client_name",
      // sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "client_name" && sortedInfo?.order,
      ellipsis: true,
      width: 200,
      render: (_, { client_name, contact_person_name }) => {
        return (
          <div>
            <div>{client_name}</div>
            <div>({contact_person_name})</div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">So Number</div>
            <div style={{ width: "100%" }}>
              {/* <CustomSelect
                placeholder=""
                options={soNumbersOptions}
                checkedList={soNumCheckedList}
                setCheckedList={setSoNumCheckedList}
                handleMultiSelect={(e) => handleMultiSelect(e, "soid")}
              /> */}

              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={soNumCheckedList}
                // onSelectChange={(value) => {
                //   setSoNumCheckedList(value);
                //   handleMultiSelect(value, "soid");
                // }}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    soNumbersOptions,
                    "soid",
                    setSoNumCheckedList
                  )
                }
                data={getOptions(soNumbersOptions, soNumCheckedList) || []}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "soid",
      key: "soid",
      // sorter: (a, b) => a.soid - b.soid,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "soid" && sortedInfo?.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Sales Rep</div>
            <div style={{ width: "100%" }}>
              {/* <CustomSelect
                placeholder=""
                options={salesRepsOptions}
                checkedList={salesRepCheckedList}
                setCheckedList={setSalesRepCheckedList}
                handleMultiSelect={(e) => handleMultiSelect(e, "staffidrep")}
              /> */}

              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={salesRepCheckedList}
                // onSelectChange={(value) => {
                //   setSalesRepCheckedList(value);
                //   handleMultiSelect(value, "staffidrep");
                // }}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    salesRepsOptions,
                    "staffidrep",
                    setSalesRepCheckedList
                  )
                }
                data={getOptions(salesRepsOptions, salesRepCheckedList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "sales_rep_name",
      key: "sales_rep_name",
      // sorter: (a, b) => a.sales_rep_name.localeCompare(b.sales_rep_name),
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === "sales_rep_name" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">Items Total</div>
            <div>{searchInput("total_quantity")}</div>
          </div>
        );
      },
      dataIndex: "total_quantity",
      key: "total_quantity",
      // sorter: (a, b) => a.total_quantity - b.total_quantity,
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === "total_quantity" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
      align: "right",
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">Value Ex Vat</div>
            <div>{searchInput("total")}</div>
          </div>
        );
      },
      dataIndex: "total",
      key: "total",
      // sorter: (a, b) => a.total - b.total,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "total" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
      render: (total) => handlePrice(total),
      align: "right",
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">Vat</div>
            <div>{searchInput("vatamt")}</div>
          </div>
        );
      },
      dataIndex: "vatamt",
      key: "vatamt",
      // sorter: (a, b) => a.vatamt - b.vatamt,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "vatamt" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
      align: "right",
      render: (vatamt) => handlePrice(vatamt),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">Value Incl Vat</div>
            <div>{searchInput("totalvat")}</div>
          </div>
        );
      },
      dataIndex: "totalvat",
      key: "totalvat",
      // sorter: (a, b) => a.totalvat - b.totalvat,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "totalvat" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
      align: "right",
      render: (totalvat) => handlePrice(totalvat),
    },
  ];

  const onTableRowExpand = (expanded, record) => {
    if (expanded) {
      const { soid } = record;
      dispatch(fetchSalesSecondLevelData(soid));
    }
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <>
      <Table
        className="special-table"
        columns={columns}
        scroll={{ x: 1200 }}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          expandIcon: null,
          showExpandColumn: false,
        }}
        onRow={({ id }) =>
          expandedRowKeys.includes(id) && { className: "expand-parent" }
        }
        loading={fetchLoading || fetchUtilsLoading}
        onChange={handleChange}
        dataSource={data}
        pagination={false}
        sortUpIcon={<UpOutlined />}
        sortDownIcon={<DownOutlined />}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
      />
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </>
  );
};

export default SalesManagerTable;

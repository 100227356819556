import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { priceFormatTwoDecimal } from "../../globalUtils/convertStrToMinutes";

const keys = ["TotalSales"];
const colors = ["#eb078e"];

const ValueOutside = ({ bars, yScale }) => {
  return bars.map((bar) => {
    const { key, width, x, data } = bar;
    const { value } = data;
    const labelMargin = 20;

    return (
      <g
        key={key}
        transform={`translate(${x}, ${yScale(value) - labelMargin})`}
        fill="#757575"
      >
        <text
          fontSize="11px"
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {`R${priceFormatTwoDecimal((value / 1000).toFixed(2))}K`}
        </text>
      </g>
    );
  });
};

const CustomLeftLabel = (data) => {
  const { x, y, value } = data;
  // console.log("x, value", x, value);

  return (
    <g>
      {/* <rect x={x + 15 * 3} y={0} width={3} height="10" fill="#B1B1B1" /> */}
      {/* <rect x={x - 80 / 2} y={4} width={80} height="3" fill="#B1B1B1" /> */}

      <text x={-35} y={y + 4} fontSize="12px" fill="#757575">
        {`${value / 1000000}M`}
      </text>
    </g>
  );
};

const TotalSalesGraph = ({ data }) => {
  return (
    <div style={{ height: "400px" }}>
      <ResponsiveBar
        data={data || []}
        keys={keys}
        indexBy="id"
        margin={{
          top: 50,
          right: 0,
          bottom: 185,
          left: 70,
        }}
        colors={colors}
        padding={0.4}
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={false}
        enableGridY={true}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: null,
              },
            },
            domain: {
              line: {
                stroke: "", // verticle line
                strokeWidth: 2,
              },
            },
            legend: {
              text: {
                fontWeight: "bold",
                letterSpacing: "1px",
              },
            },
          },
          grid: {
            line: {
              stroke: `rgb(231 232 236 / 80%)`,
              strokeWidth: 2,
              strokeDasharray: "2, 4",
            },
          },
        }}
        layers={["grid", "axes", "bars", "markers", ValueOutside]}
        legends={[
          {
            dataFrom: "keys",
            data: keys.map((id, index) => ({
              color: colors[index],
              id,
              label: id === "TotalSales" ? 1 : 2,
            })),
            anchor: "bottom",
            direction: "row",
            translateY: 63,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: "left-to-right",
            symbolSize: 20,
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "SumSales",
          legendPosition: "middle",
          legendOffset: -60,
          renderTick: CustomLeftLabel,
          tickValues: 3,
        }}
        //   axisBottom={{
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: "SumSales",
        //     legendPosition: "middle",
        //     legendOffset: 60,
        //     renderTick: CustomBottomLabel,
        //   }}
      />
    </div>
  );
};

export default TotalSalesGraph;

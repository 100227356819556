import { useSelector } from "react-redux";

const UseSupAdminOrAdminWithUserManagerPermission = () => {
  const { user } = useSelector((state) => state.authReducer);

  const allRoles = ["super_admin", "admin"];

  const [isSuperAdmin, isAdmin] = allRoles.map(
    (role) => role === user.user_type
  );

  const { permissions } = user;

  let isAllow = isSuperAdmin || (isAdmin && permissions?.user_manager);

  return isAllow;
};

export default UseSupAdminOrAdminWithUserManagerPermission;

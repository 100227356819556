import React, { useState } from "react";
import { Modal, Button, Row, Col, Form, Space, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import OtpInput from "react-otp-input";

const CreatePinModal = ({ isVisible, setVisible, setPin, isUserUpdate }) => {
  const [form] = Form.useForm();
  const [pinObj, setPinObj] = useState({
    pin: "",
    pinVisble: false,
    confirmPin: "",
    confirmPinVisble: false,
  });

  const handleChange = (pin, name) => setPinObj({ ...pinObj, [name]: pin });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    setPin(pinObj.pin);
    setVisible(false);
    if (!isUserUpdate) {
      return message.success("Pin has been saved!");
    }
  };

  const handlePinVisiblity = () => {
    setPinObj({
      ...pinObj,
      pinVisble: !pinObj.pinVisble,
    });
  };

  const handleConfirmPinVisiblity = () => {
    setPinObj({
      ...pinObj,
      confirmPinVisble: !pinObj.confirmPinVisble,
    });
  };

  return (
    <>
      <Modal
        title={`${isUserUpdate ? "Change" : "Create"} PIN`}
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="create-password-modal pin-modal"
        maskClosable={false}
        footer={null}
        width={299}
      >
        <Row justify="center" gutter={[0, 20]}>
          <Col span={24}>
            <div className="text-div">
              Please create a five digit numerical PIN.
            </div>
          </Col>
          <Col span={24}>
            <Form form={form} name="pin-form" onFinish={onFinish}>
              <label>Create pin</label>
              <Form.Item
                name="pin"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Space>
                  <OtpInput
                    isInputNum="number"
                    value={pinObj.pin}
                    onChange={(e) => handleChange(e, "pin")}
                    numInputs={5}
                    isInputSecure={!pinObj.pinVisble}
                    separator={<span> </span>}
                  />
                  {pinObj.pinVisble ? (
                    <EyeTwoTone onClick={handlePinVisiblity} />
                  ) : (
                    <EyeInvisibleOutlined onClick={handlePinVisiblity} />
                  )}
                </Space>
              </Form.Item>
              <label>Confirm Pin</label>
              <Form.Item
                name="ConfirmPin"
                dependencies={["pin"]}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        pinObj.pin.length === 5 &&
                        pinObj.pin === pinObj.confirmPin
                      ) {
                        return Promise.resolve();
                      }

                      if (pinObj?.confirmPin?.length > 0) {
                        return Promise.reject(
                          new Error("Confirm Pin not matched!")
                        );
                      } else return Promise.reject(new Error("Required!"));
                    },
                  }),
                ]}
              >
                <Space>
                  <OtpInput
                    isInputNum="number"
                    value={pinObj.confirmPin}
                    onChange={(e) => handleChange(e, "confirmPin")}
                    numInputs={5}
                    separator={<span> </span>}
                    isInputSecure={!pinObj.confirmPinVisble}
                  />
                  {pinObj.confirmPinVisble ? (
                    <EyeTwoTone onClick={handleConfirmPinVisiblity} />
                  ) : (
                    <EyeInvisibleOutlined onClick={handleConfirmPinVisiblity} />
                  )}
                </Space>
              </Form.Item>
              <Col>
                <Button
                  htmlType="submit"
                  className="custom-blue-btn confirm-btn"
                >
                  Confirm
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreatePinModal;

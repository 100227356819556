import * as constants from "./constants";

const initialState = {
  allUsers: [],
  currentUserObj: {},
  utilsData: {},
  departmentsList: [],
  createUserLoading: false,
  userDetailsIsEdit: false,
  departmentIsEdit: false,
  fetchUsersLoading: false,
  deleteUserLoading: false,
  departmentLoading: false,
  settingsLoading: false,
  sendDetailLoading: false,
  fetchUserDetailLoading: false,
  settingsUpdatingKey: {
    popup_notifications: false,
    email_notifications: false,
  },
  departItemsIndex: null,
  totalRecords: 0,
  popupLoading: false,
  emailLoading: false,
};

const userManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_DEPART_DISABLE_INDEX:
      return {
        ...state,
        departItemsIndex: action.data,
      };
    case constants.FETCH_USERS_UTIL_DATA_SUCCESS:
      const { sales_reps } = action.data;
      const sortedSalesrep = sales_reps.sort((a, b) => a.staffid - b.staffid);
      return {
        ...state,
        utilsData: {
          ...action.data,
          sales_reps: [
            { staffid: " ", listname: "Unselect" },
            ...sortedSalesrep,
          ],
        },
      };
    case constants.USER_DETAILS_IS_EDIT:
      return {
        ...state,
        userDetailsIsEdit: action.data,
      };
    case constants.CREATE_USER:
      return {
        ...state,
        createUserLoading: true,
      };
    case constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        currentUserObj: action.data,
        userDetailsIsEdit: false,
        departmentIsEdit: true,
      };
    case constants.CREATE_USER_ERROR:
      return {
        ...state,
        createUserLoading: false,
      };
    case constants.UPDATE_USER:
      return {
        ...state,
        createUserLoading: true,
      };
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        currentUserObj: action.data,
        userDetailsIsEdit: false,
      };
    case constants.UPDATE_USER_ERROR:
      return {
        ...state,
        createUserLoading: false,
      };
    case constants.ADD_USER_IN_MANAGER:
      return {
        ...state,
        createUserLoading: true,
      };
    case constants.FETCH_ALL_USERS:
      return {
        ...state,
        fetchUsersLoading: true,
      };
    case constants.FETCH_ALL_USERS_SUCCESS:
      const { total_users, users } = action.data;
      return {
        ...state,
        allUsers: users,
        fetchUsersLoading: false,
        totalRecords: total_users,
      };
    case constants.FETCH_ALL_USERS_ERROR:
      return {
        ...state,
        fetchUsersLoading: false,
      };
    case constants.DELETE_USER:
      return {
        ...state,
        deleteUserLoading: true,
      };
    case constants.DELETE_USER_SUCCESS:
      let updatedUsers = state.allUsers.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        deleteUserLoading: false,
        allUsers: updatedUsers,
      };
    case constants.DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserLoading: false,
      };
    case constants.SAVE_DEPARTMENT:
      return {
        ...state,
        departmentLoading: true,
        departmentIsEdit: true,
      };
    case constants.SAVE_DEPARTMENT_SUCCESS:
      // let updatedUsers = state.allUsers.filter(
      //   (el) => el.id !== action.data.id
      // );
      const { departments } = action.data;
      return {
        ...state,
        departmentLoading: false,
        departmentIsEdit: false,
        currentUserObj: {
          ...state.currentUserObj,
          departments,
          departments_with_steps: departments,
        },
        // allUsers: updatedUsers,
      };
    case constants.SAVE_DEPARTMENT_ERROR:
      return {
        ...state,
        departmentLoading: false,
        departmentIsEdit: false,
      };
    case constants.FETCH_USER:
      return {
        ...state,
        // departmentLoading: true,
        fetchUserDetailLoading: true,
      };
    case constants.FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUserObj: action.data,
        fetchUserDetailLoading: false,
      };
    case constants.FETCH_USER_ERROR:
      return {
        ...state,
        fetchUserDetailLoading: false,
      };
    case constants.SEND_DETAILS_TO_USER:
      return {
        ...state,
        sendDetailLoading: true,
      };
    case constants.SEND_DETAILS_TO_USER_SUCCESS:
      return {
        ...state,
        sendDetailLoading: false,
      };
    case constants.SEND_DETAILS_TO_USER_ERROR:
      return {
        ...state,
        sendDetailLoading: false,
      };
    case constants.UPDATE_USER_SETTINGS:
      const { data, loadingObj } = action.data;
      const { id, ...res } = data;
      return {
        ...state,
        settingsLoading: true,
        settingsUpdatingKey: { ...state.settingsUpdatingKey, ...res },
        ...loadingObj,
      };
    case constants.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        currentUserObj: { ...state.currentUserObj, ...action.data.data },
        // settingsUpdatingKey: { ...state.settingsUpdatingKey, ...res },
        settingsLoading: false,
        ...action.data.loadingObj,
      };
    case constants.UPDATE_USER_SETTINGS_ERROR:
      return {
        ...state,
        settingsLoading: false,
        currentClientObj: {
          ...state.currentClientObj,
        },
      };
    case constants.USER_DETAIL_CLEANUP_FUNC:
      return {
        ...state,
        allUsers: [],
        createUserLoading: false,
        currentUserObj: {},
        userDetailsIsEdit: false,
        fetchUsersLoading: false,
        deleteUserLoading: false,
        departmentLoading: false,
        departmentIsEdit: false,
        departmentsList: [],
        departItemsIndex: null,
      };
    default:
      return state;
  }
};

export default userManagerReducer;

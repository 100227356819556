import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  DatePicker,
  Select,
  TimePicker,
  Col,
  Row,
  Space,
} from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { ReactComponent as AttachIcon } from "../../assets/attach.svg";
import {
  createActivities,
  deleteActivity,
  fetchActivities,
  updateActivity,
  updateIsAddIsUpdate,
} from "./redux/actions";
import SpinnerComponent from "../../components/spinner";
import _ from "lodash";
import CustomPagination from "../../components/customPagination/CustomPagination";
import ImageComponent from "./useAttachments";
import NonEditRowAttach from "./nonEditRowAttachView";
import CustomCalendar from "../../components/calendar";
import AMultiSelect from "../../components/AMultiSelect";
import {
  convertMinutesToStr,
  convertStrToMinutes,
} from "../../globalUtils/convertStrToMinutes";
import { getOptions } from "../Sales/utils";
import UseIsSalesManagerOrStaff from "../../components/useIsSalesManagerOrStaff";
import UseGetUserRole from "../../components/useGetUserRole.jsx";

const { Option } = Select;
const { TextArea } = Input;

function ActivityTable() {
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [sortedInfo, setSortedInfo] = useState(null);
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [images, setImages] = useState([]);
  // images contains all attachments of item
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [search, setSearch] = useState({});
  const [date, setDate] = useState(null);
  const [activitiesCheckedList, setActivitiesCheckedList] = useState([]);
  const [salesRepCheckedList, setSalesRepCheckedList] = useState([]);
  const [isSalesActivitiesManager, isSalesActivitiesStaff] =
    UseIsSalesManagerOrStaff();
  const [isSuperAdmin] = UseGetUserRole();

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivities({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivities({ page: paramsPage, limit: resultPerPage, search })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const [deletingActivityId, setDeleteActivityId] = useState();

  const isEditing = (record) =>
    record.key === editingKey || record.id === editingKey;

  const {
    allActivities,
    fetchingLoading,
    updateLoading,
    totalRecords,
    fetchUtilsLoading,
    salesRepOptions,
    activityTypesOptions,
    isAddActivity,
    isUpdateActivity,
    isCopyActivity,
    deletingLoading,
  } = useSelector((state) => state.activitiesReducer);

  const { allActivityTypes } = useSelector(
    (state) => state.activityTypesReducer
  );
  const { user } = useSelector((state) => state.authReducer);

  const handleUpdateStatus = (isAdd, isUpdate, isCopy) => {
    dispatch(
      updateIsAddIsUpdate({
        isAddActivity: isAdd,
        isUpdateActivity: isUpdate,
        isCopyActivity: isCopy,
      })
    );
  };

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey("");
    }

    return () => {
      handleUpdateStatus(false, false, false);
    };
  }, [updateLoading]);

  useEffect(() => {
    if (allActivities.length === 0) {
      return setData(allActivities);
    } else if (allActivities.length > 0) {
      let updatedData = allActivities.map((el) => {
        const { fetch_images, ...rest } = el;

        let updatedImages;
        if (fetch_images?.length > 0) {
          updatedImages = fetch_images.map((img) => {
            const { image, key } = img;
            let name = image
              .split("/")
              [image.split("/").length - 1].split("?")[0];

            return { key, image, isLocal: false, name };
          });
        }

        return { ...rest, images: updatedImages };
      });
      setData(updatedData);
    }
  }, [allActivities]);

  const edit = (record) => {
    setCurrentUpdateObj(record);
    let filteredItems = data.filter((el) => el.id !== editingKey);
    let final = filteredItems.filter((el) => el.key !== editingKey);
    setData(final);
    setImages(record?.images || []);
    const { date_time, start_time, stop_time, time_spent } = record;
    form.setFieldsValue({
      ...record,
      date_time: moment(date_time),
      start_time: moment(start_time),
      stop_time: moment(stop_time),
      time_spent: convertMinutesToStr(time_spent),
      salesRep: record?.user?.username,
    });
    handleUpdateStatus(false, true, false);
    setEditingKey(record.id);
  };

  function getTimeDiff(start, end) {
    // return moment.duration(moment(end, "HH:mm").diff(moment(start, "HH:mm")));
    let diff = moment
      .duration(moment(end, "HH:mm").diff(moment(start, "HH:mm")))
      .asMinutes();
    return Math.round(diff);
  }

  const shouldEndTimeDisabled = () => {
    let formValues = form.getFieldsValue();

    if (formValues.start_time && formValues.stop_time) {
      let diff = getTimeDiff(formValues.start_time, formValues.stop_time);
      // let time_spent = `${diff.hours()}h ${diff.minutes()}m`
      if (diff < 0) {
        form.setFieldsValue({
          ...formValues,
          time_spent: "",
          stop_time: null,
        });
      } else if (diff === 0) {
        form.setFieldsValue({
          ...formValues,
          time_spent: convertMinutesToStr(diff),
        });
      } else if (diff > 0) {
        form.setFieldsValue({
          ...formValues,
          time_spent: convertMinutesToStr(diff),
        });
      }
    }
    let isDisabled =
      formValues.start_time === undefined || formValues.start_time === null;
    return isDisabled;
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    let formValues = form.getFieldsValue();

    let startTimePicker = (
      <TimePicker
        popupClassName="activities-time-picker"
        className="activities-timePicker"
        placeholder="00:00"
        format="HH:mm"
        size="large"
        onChange={(e) =>
          e === null &&
          form.setFieldsValue({
            ...formValues,
            time_spent: null,
            stop_time: null,
            start_time: null,
          })
        }
        suffixIcon={null}
      />
    );

    let endTimePicker = (
      <TimePicker
        disabled={shouldEndTimeDisabled()}
        popupClassName="activities-time-picker"
        className="activities-timePicker"
        placeholder="00:00"
        format="HH:mm"
        size="large"
        onChange={(e) => {
          e === null
            ? form.setFieldsValue({
                ...formValues,
                time_spent: "",
                stop_time: null,
              })
            : form.setFieldsValue({
                ...formValues,
                stop_time: e,
              });
        }}
        suffixIcon={null}
      />
    );

    switch (dataIndex) {
      case "date_time":
        inputNode = (
          <DatePicker size="large" suffixIcon={null} allowClear={false} />
        );
        break;
      case "client_name":
        inputNode = <Input placeholder="Insert Client Name" size="large" />;
        break;
      case "salesRep":
        inputNode = <Input readOnly className="border-less-input" />;
        break;
      case "activity_type_id":
        inputNode = (
          <Select
            // disabled={index <= currentItemsIndex}
            showSearch
            className="activities-selector"
            getPopupContainer={() =>
              document.getElementsByClassName(
                "ant-layout-content layout-content site-layout-background custom-textarea"
              )[0]
            }
            dropdownClassName="select-dropdown-custom"
            placeholder="Select Activity Type"
            size="middle"
            optionFilterProp="children"
            // onChange={(e, option) =>
            //   onChange(e, "position", option, index)
            // }
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allActivityTypes.map(({ id, activity_type }) => (
              <Option key={id} value={id}>
                {activity_type}
              </Option>
            ))}
          </Select>
        );
        break;
      case "start_time":
        inputNode = startTimePicker;
        break;
      case "stop_time":
        inputNode = endTimePicker;
        break;
      case "time_spent":
        inputNode = (
          <Input readOnly className="border-less-input" placeholder="00h 00m" />
        );
        break;
      case "feedback":
        inputNode = <TextArea rows={2} placeholder="Insert Comment" />;
        break;
      case "images":
        inputNode = (
          <ImageComponent
            images={images}
            setImages={setImages}
            attachPopupVisible={attachPopupVisible}
            setAttachPopupVisible={setAttachPopupVisible}
            currentUpdateObj={currentUpdateObj}
          />
        );
        break;
      default:
        break;
    }
    return (
      <td {...restProps}>
        {editing ? (
          dataIndex === "stop_time" ? (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: ``, // Required!
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    let startTime = getFieldValue("start_time");
                    const isBefore = moment(startTime).isBefore(
                      value,
                      "milliseconds"
                    );
                    if (isBefore) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("")); // Invalid!
                  },
                }),
              ]}
            >
              {inputNode}
            </Form.Item>
          ) : (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              rules={[
                {
                  required: dataIndex === "images" ? false : true,
                  message: ``, // Required!
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const cancel = () => {
    if (isUpdateActivity) {
      setData(data);
    } else {
      // if (editingKey) {
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      // }
    }
    handleUpdateStatus(false, false, false);
    setEditingKey("");
  };

  const save = async (key) => {
    setEditingKey(key);
    try {
      // const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(
        (item) => key === item.key || key === item.id
      );
      if (index > -1) {
        form.submit();
      } else {
        console.log("b");
        // newData.push(row);
        // setData(newData);
        // setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleCopy = (record) => {
    setSortedInfo(null);
    let filteredItems = data.filter((el) => el.id !== editingKey);
    let final = filteredItems.filter((el) => el.key !== editingKey);
    setData(final);

    if (record?.images?.length > 0) {
      setImages(
        record.images.map((el) => ({ ...el, isLocal: true, isCopy: true }))
      );
    } else {
      setImages([]);
    }

    let id = Math.ceil(Math.random() * 23456789);
    const { date_time, start_time, stop_time, time_spent } = record;
    let item = {
      ...record,
      id,
      isCopy: true,
      salesRep: user.username,
      date_time: moment(date_time),
      start_time: moment(start_time),
      stop_time: moment(stop_time),
      time_spent: convertMinutesToStr(time_spent),
      images: undefined,
    };
    setData((data) => [item, ...data]);
    setCount(count + 1);
    handleUpdateStatus(false, false, true);
    // edit(newData);
    form.setFieldsValue({
      ...item,
    });
    setEditingKey(id);
    setCurrentUpdateObj({});
  };

  const searchInput = (key, placeholder) => (
    <Input
      placeholder={""}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: "",
        end_date: "",
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleTimepicker = (key) => {
    return (
      <TimePicker
        className="activities-time-picker"
        popupClassName="activities-time-picker"
        onClick={(e) => e.stopPropagation()}
        placeholder={""} // key.split("_").join(" ")
        format={"HH:mm"}
        suffixIcon={null}
        size="small"
        onChange={(e) => {
          setSearch({
            ...search,
            [key]: e,
          });
          debouncedDefaultPageOneCallApi();
        }}
      />
    );
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedDefaultPageOneCallApi();
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes("all")) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes("not-all")) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const onDeleteSuccess = () => {
    debouncedCallApi();
    setDeleteActivityId();
    debouncedDefaultPageOneCallApi();
  };

  const deleteActivityHandler = (record) => {
    setDeleteActivityId(record.id);
    dispatch(deleteActivity({ ...record, onDeleteSuccess }));
  };

  const columns = [
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={"D MMM YYYY"}
                handleDateInput={handleDateInput}
              />
            </div>
          </div>
        );
      },
      dataIndex: "date_time",
      key: "date_time",
      // sorter: (a, b) => moment(a.date_time).unix() - moment(b.date_time).unix(),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "date_time" && sortedInfo?.order,
      width: 240, // "8%"
      editable: true,
      render: (date) => {
        return moment(date).format("DD MMMM YYYY");
      },
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">client</div>
            <div>{searchInput("client_name", "Client")}</div>
          </div>
        );
      },
      dataIndex: "client_name",
      key: "client_name",
      // sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "client_name" && sortedInfo?.order,
      width: 230, // "16%"
      editable: true,
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">sales Rep</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={salesRepCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    salesRepOptions,
                    "user_id",
                    setSalesRepCheckedList
                  )
                }
                data={getOptions(salesRepOptions, salesRepCheckedList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "salesRep",
      key: "salesRep",
      // sorter: (a, b) => a.user.username.localeCompare(b.user.username),
      sorter: false, // true
      sortOrder: sortedInfo?.columnKey === "salesRep" && sortedInfo?.order,
      width: 220, // "17%"
      editable: true,
      render: (salesRep, record) =>
        record?.user?.username ? record?.user?.username : salesRep,
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Activity Type</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={activitiesCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    activityTypesOptions,
                    "activity_type_id",
                    setActivitiesCheckedList
                  )
                }
                data={
                  getOptions(activityTypesOptions, activitiesCheckedList) || []
                }
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "activity_type_id",
      key: "activity_type_id",
      // sorter: (a, b) =>
      //   a?.activity_type?.activity_type.localeCompare(
      //     b?.activity_type?.activity_type
      //   ),
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === "activity_type_id" && sortedInfo?.order,
      width: 220, // "17%"
      editable: true,
      render: (id, record) =>
        record?.activity_type?.activity_type
          ? record?.activity_type?.activity_type
          : id,
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Start time</div>
            <div>{handleTimepicker("start_time")}</div>
          </div>
        );
      },
      dataIndex: "start_time",
      key: "start_time",
      shouldCellUpdate: () => true,
      // sorter: (a, b) =>
      //   moment(a.start_time).unix() - moment(b.start_time).unix(),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "start_time" && sortedInfo?.order,
      width: 95, // "7%"
      editable: true,
      render: (start_time) => {
        return moment(start_time).format("HH:mm");
      },
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">End time</div>
            <div>{handleTimepicker("stop_time")}</div>
          </div>
        );
      },
      dataIndex: "stop_time",
      shouldCellUpdate: () => true,
      key: "stop_time",
      // sorter: (a, b) => moment(a.stop_time).unix() - moment(b.stop_time).unix(),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "stop_time" && sortedInfo?.order,
      width: 95, // "7%"
      editable: true,
      render: (end_time) => {
        return moment(end_time).format("HH:mm");
      },
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Total time</div>
            <div>{searchInput("time_spent", "Total time")}</div>
          </div>
        );
      },
      dataIndex: "time_spent",
      key: "time_spent",
      shouldCellUpdate: () => true,
      // sorter: (a, b) => Number(a.time_spent) - Number(b.time_spent),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "time_spent" && sortedInfo?.order,
      width: 100, // "8%"
      align: "left",
      editable: true,
      render: (total_time) => `${total_time}m`,
      // convertMinutesToStr(total_time),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Comment</div>
            <div>{searchInput("feedback", "")}</div>
          </div>
        );
      },
      dataIndex: "feedback",
      key: "feedback",
      // sorter: (a, b) => a.feedback.localeCompare(b.feedback),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "feedback" && sortedInfo?.order,
      width: 150, // "16%"
      editable: true,
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">Attach</div>
            <div>{searchInput("images", "Attach")}</div>
          </div>
        );
      },
      dataIndex: "images",
      key: "images",
      // sorter: (a, b) => (a.images?.length || 0) - (b.images?.length || 0),
      sorter: false, // true
      sortOrder: sortedInfo?.columnKey === "images" && sortedInfo?.order,
      width: 70, // "4%"
      editable: true,
      align: "center",
      render: (_, record) => {
        if (currentUpdateObj.id !== record.id) {
          return (
            <div
              className={`attach-parent-div  ${
                (isAddActivity || isCopyActivity || isUpdateActivity) &&
                "disable-icon"
              }`}
              onClick={() => {
                if (isAddActivity || isCopyActivity || isUpdateActivity) {
                  return;
                } else {
                  setCurrentUpdateObj(record);
                  setAttachPopupVisible(true);
                }
              }}
            >
              <AttachIcon
                className="rotate-icon activities-attach"
                width={16}
                fill="#231f20"
              />
              <div>{record?.images?.length || 0}</div>
            </div>
          );
        }

        return (
          <NonEditRowAttach
            images={record?.images || []}
            setImages={setImages}
            attachPopupVisible={attachPopupVisible}
            setAttachPopupVisible={setAttachPopupVisible}
            record={record}
            setCurrentUpdateObj={setCurrentUpdateObj}
            currentUpdateObj={currentUpdateObj}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "operation",
      align: "center",
      width: 80, // "6%"
      render: (_, record) => {
        const editable = isEditing(record);
        let key = record?.key || record?.id;
        return editable ? (
          editingLoading && editingKey === key ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align="center">
              {/* {(!isUpdateActivity) && (
                <CopyIcon
                  width={18}
                  height={18}
                  className="custom-icon"
                  fill="#eb078e"
                  disabled={editingKey !== ""}
                  onClick={() => handleCopy(record)}
                />
              )} */}
              <Popconfirm
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "ant-layout-content layout-content site-layout-background custom-textarea"
                  )[0]
                }
                overlayClassName="activities-popconfirm"
                title="Are you sure you want to save this Activity?"
                onConfirm={() => save(record.key || record.id)}
                okText="Yes"
                cancelText="No"
              >
                <SaveIcon className="custom-icon" />
              </Popconfirm>
              {/* {!isUpdateActivity && ( */}
              <Popconfirm
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "ant-layout-content layout-content site-layout-background custom-textarea"
                  )[0]
                }
                title="Sure to cancel?"
                onConfirm={cancel}
              >
                <DeleteIcon className="custom-icon" />
              </Popconfirm>
              {/* // )} */}
            </Space>
          )
        ) : (
          <div>
            <Space size={20} align="center">
              {deletingLoading && record.id === deletingActivityId ? (
                <SpinnerComponent fontSize={16} />
              ) : (
                <>
                  {(isSalesActivitiesManager ||
                    isSalesActivitiesStaff ||
                    isSuperAdmin) && (
                    <CopyIcon
                      className={`custom-icon ${
                        (isAddActivity || isCopyActivity || isUpdateActivity) &&
                        "disable-icon"
                      }`}
                      disabled={editingKey !== ""}
                      onClick={() =>
                        !isAddActivity &&
                        !isCopyActivity &&
                        !isUpdateActivity &&
                        handleCopy(record)
                      }
                    />
                  )}
                  {(isSalesActivitiesManager || isSuperAdmin) && (
                    <>
                      <EditIcon
                        className={`custom-icon ${
                          (isAddActivity ||
                            isCopyActivity ||
                            isUpdateActivity) &&
                          "disable-icon"
                        }`}
                        disabled={editingKey !== ""}
                        onClick={() =>
                          !isAddActivity &&
                          !isCopyActivity &&
                          !isUpdateActivity &&
                          edit(record)
                        }
                      />
                      {!isAddActivity &&
                      !isCopyActivity &&
                      !isUpdateActivity ? (
                        <Popconfirm
                          getPopupContainer={() =>
                            document.getElementsByClassName(
                              "ant-layout-content layout-content site-layout-background custom-textarea"
                            )[0]
                          }
                          placement="top"
                          title="Are you sure you want to delete this Activity?"
                          onConfirm={() => deleteActivityHandler(record)}
                        >
                          <DeleteIcon className={`custom-icon`} />
                        </Popconfirm>
                      ) : (
                        <DeleteIcon
                          className={`custom-icon ${
                            (isAddActivity ||
                              isCopyActivity ||
                              isUpdateActivity) &&
                            "disable-icon"
                          }`}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {/* {data.length >= 1 && (
                <Popconfirm
                  title="Are you sure to delete?"
                  onConfirm={() => handleDelete(record.key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon
                    fill="#eb078e"
                    width={18}
                    height={18}
                    className="custom-icon"
                  />
                </Popconfirm>
              )} */}
            </Space>
          </div>
        );
      },
    },
  ];

  // const handleDelete = (key) => {
  //   setData(data.filter((item) => item.key !== key));
  // };

  // function onChange(date, dateString) {
  //   console.log(date, dateString);
  // }

  const handleAdd = () => {
    setSortedInfo(null);
    handleUpdateStatus(true, false, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      date_time: moment(),
      salesRep: user.username,
      client_name: "",
      start_time: null,
      stop_time: null,
      feedback: "",
      activity_type_id: null,
      time_spent: "",
    };
    setData((data) => [newData, ...data]);
    setCount(1);
    // edit(newData);
    setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const hanldeFinish = (item) => {
    const { date_time, start_time, stop_time, time_spent } = item;
    if (isUpdateActivity) {
      dispatch(
        updateActivity({
          ...item,
          date_time: moment(date_time),
          start_time: moment(start_time).toDate(),
          stop_time: moment(stop_time).toDate(),
          time_spent: convertStrToMinutes(time_spent),
          user_id: user.id,
          id: currentUpdateObj.id,
          ...(images.length > 0 && { images }),
          debouncedDefaultPageOneCallApi,
        })
      );
    } else {
      dispatch(
        createActivities({
          ...item,
          date_time: moment(date_time),
          start_time: moment(start_time).toDate(),
          stop_time: moment(stop_time).toDate(),
          time_spent: convertStrToMinutes(time_spent),
          user_id: user.id,
          ...(images.length > 0 && { images }),
          debouncedDefaultPageOneCallApi,
        })
      );
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  return (
    <Row className="production-manager-parent-row">
      <Col span={24} className="heading-col">
      <Row align='middle' gutter={[50, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Activities Register</span>
            </div>
          </Col>
          <Col>
            {(isSalesActivitiesManager ||
              isSalesActivitiesStaff ||
              isSuperAdmin) && (
              <Button
                icon={<PlusOutlined />}
                className="add-activity-btn"
                size="middle"
                onClick={handleAdd}
                disabled={
                  isAddActivity ||
                  fetchingLoading ||
                  fetchUtilsLoading ||
                  isCopyActivity ||
                  isUpdateActivity
                }
              >
                ACTIVITY
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form form={form} component={false} onFinish={hanldeFinish}>
          <Table
            scroll={{ x: 1500 }}
            className="special-table activity-type-table"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowKey={(record) => record.id}
            loading={fetchingLoading || fetchUtilsLoading}
            rowClassName="editable-row"
            dataSource={data}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
          />
        </Form>
      </Col>
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </Row>
  );
}

export default ActivityTable;

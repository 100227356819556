import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchProdFirstLevel({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/production/register`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProdFirstLevelSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.fetchProdFirstLevelError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleFetchProdGraphData({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/production/graph_data`,
      {
        params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
      }
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProdGraphDataSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.fetchProdGraphDataError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleFetchProdSearchUtils() {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/production/search_lists`
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProdSearchUtilsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleAddFirstLevelComment(data) {
  const { soid, body, handleSuccess } = data.data;
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/comments?soid=${soid}`,
      {
        body,
      }
    );
    const { success, message } = response.data;
    if (success) {
      yield put(actions.firstLevelAddCommentSuccess(response.data.data));
      handleSuccess();
      AntdAlert.success(message);
    } else {
      errorHandler(response.data);
      yield put(actions.firstLevelAddCommentError());
      AntdAlert.error(message);
    }
  } catch (error) {
    console.log(error);
    yield put(actions.firstLevelAddCommentError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleAddProdAttach({ data }) {
  const { soid, images } = data.data;

  const fd = new FormData();
  Array.from(images).forEach((image) => {
    const { isLocal } = image;
    if (isLocal) {
      fd.append(`images[]`, image);
    }
  });

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/production/${soid}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.addProductionAttachSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.addProductionAttachError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.addProductionAttachError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteProdAttach({ data }) {
  const { key, soid, setDeleteItemKey, deleteItemKey } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v1/images/${key}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      let fileteredKeys = deleteItemKey.filter(
        (el) => el.key !== response.data.data.id
      );
      setDeleteItemKey(fileteredKeys);
      yield put(
        actions.deleteProdAttachSuccess({
          soid,
          imgItem: response.data.data,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteProdAttachError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteProdAttachError(response.data.data));
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleFetchProdSecondLevel({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/production/items`, {
      params: { soid: data.soid },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProdSecondLevelSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleCreateProdSecondLevel({ data }) {
  const { handleSecondLevelSuccess } = data;
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/production/save_format_and_steps`,
      data.data
    );
    const { success, message } = response.data;
    if (success) {
      yield put(actions.createSecondLevelSuccess(response.data.data));
      AntdAlert.success(message);
      handleSecondLevelSuccess();
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateProdSecondLevel({ data }) {
  const { handleSecondLevelSuccess } = data;
  const { id, ...rest } = data.data;
  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/item_details/${id}`,
      rest
    );
    const { success, message } = response.data;
    if (success) {
      yield put(actions.updateSecondLevelSuccess(response.data.data));
      AntdAlert.success(message);
      handleSecondLevelSuccess();
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateSecondLevelError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleFetchProdThirdLevel({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/production/item_steps`,
      {
        params: { soitemid: data.soitemid },
      }
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProdThirdLevelSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchProdFourthLevel({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/processing_operators`, {
      params: { items_processing_step_id: data.id },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProdFourthLevelSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleVerifyPin({ data }) {
  const { handleVerifySuccess, handleVerifyFailed } = data;
  try {
    const response = yield axios.get(`${baseURL}/api/v1/users/verify_pin`, {
      params: data.data,
    });
    const { success, message } = response.data;
    if (success) {
      yield put(actions.verifyPinSuccess(response.data.data));
      handleVerifySuccess();
    } else {
      yield put(actions.verifyPinError(response.data.data));
      handleVerifyFailed();
      AntdAlert.error(message);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateOperator({ data }) {
  const { handleSuccess, fetchFourthLevelData } = data;
  try {
    const response = yield axios.post(`${baseURL}/api/v1/operator_activities`, {
      ...data.data,
    });
    const { success, message } = response.data;
    if (success) {
      const { get_item_detail, ...rest } = response.data.data;
      const { items_processing_step_id } = rest;
      yield put(actions.updateOperatorSuccess(rest));
      yield put(actions.updateThirdLevelOnUpdateFourthLevel(get_item_detail));
      AntdAlert.success(message);
      fetchFourthLevelData({
        ...get_item_detail,
        id: items_processing_step_id,
      });
      handleSuccess();
    } else {
      yield put(actions.updateOperatorError(response.data.data));
      AntdAlert.error(message);
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleStopOperator({ data }) {
  const { handleSuccess, fetchFourthLevelData } = data;
  const { id, ...rest } = data.data;
  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/operator_activities/${id}`,
      rest
    );
    const { success, message } = response.data;
    if (success) {
      const { get_item_detail, ...rest } = response.data.data;
      const { items_processing_step_id } = rest;
      yield put(actions.stopOperatorSuccess(rest));
      yield put(actions.updateThirdLevelOnUpdateFourthLevel(get_item_detail));
      fetchFourthLevelData({
        ...get_item_detail,
        id: items_processing_step_id,
      });
      // yield put(actions.stopOperatorSuccess(response.data.data));
      AntdAlert.success(message);
      handleSuccess();
    } else {
      yield put(actions.stopOperatorError(response.data.data));
      AntdAlert.error(message);
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleAddOperator({ data }) {
  const { handleSuccess } = data;
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/processing_operators`,
      { ...data.data }
    );
    const { success, message } = response.data;
    if (success) {
      const { get_item_detail, ...rest } = response.data.data;
      yield put(actions.addOperatorSuccess(rest));
      yield put(actions.updateThirdLevelOnUpdateFourthLevel(get_item_detail));
      AntdAlert.success(message);
      handleSuccess();
    } else {
      yield put(actions.addOperatorError(response.data.data));
      AntdAlert.error(message);
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchPrevChat({ data }) {
  const { parent } = data;

  // const { handleSuccess } = data;
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/chat?parent[id]=${parent.id}&parent[model]=SoMain`
    );
    const { success, message } = response.data;
    if (success) {
      yield put(actions.fetchPreviousChatSuccess(response.data.data));
      // handleSuccess();
    } else {
      yield put(actions.fetchPreviousChatError(response.data.data));
      AntdAlert.error(message);
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSendMessage({ data }) {
  // const { handleSuccess } = data;
  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/chat/send_message`,
      data
    );
    const { success, message } = response.data;
    if (success) {
      // yield put(actions.sendMessageSuccess());
      // AntdAlert.success(message);
      // handleSuccess();
    } else {
      yield put(actions.sendMessageError(response.data.data));
      AntdAlert.error(message);
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

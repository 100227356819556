import { Button, Row, Col, Input, Form } from "antd";
import UseForgotPassword from "./useForgotPassword";
import logo from "../../assets/login-logo.jpg";
import { Link } from "react-router-dom";
import "../login/index.less";
import "./index.less";

const ForgotPassword = () => {
  const [onFinish, onFinishFailed, loading, form] = UseForgotPassword(Form);
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return (
    <div className="login-most-parent-div">
      <Row justify="center" align="middle" className="parent-row">
        <Col>
          <div className="login-form-parent-div forgot-password-parent-div">
            <Row justify="center" gutter={[0, 30]}>
              <Col>
                <div className="logo-div">
                  <img src={logo} alt="" />
                </div>
              </Col>
              <Col span={24}>
                <div className="login-text">
                  <p>Forgot your password?</p>
                  <p className="span-div">
                    <span>
                      Please enter your email address below to reset your
                      password.
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
            <Form
              form={form}
              name="forgot-password"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              labelCol={{ span: 5 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateTrigger={null}
            >
              <Row justify="center" align="middle">
                <Col span={23}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                      {
                        pattern: mailformat,
                        message: "Email is invalid!",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "95%" }}
                      className="email-input forgot-email-input"
                      placeholder="Enter Email Address"
                    />
                  </Form.Item>

                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      htmlType="submit"
                      className="send-btn"
                      loading={loading}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <p>
                    <Link className="bottoom-p-text" to="/">
                      or Login
                    </Link>
                  </p>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { Col, Input, Row, message, Select, Checkbox, Form, Spin } from "antd";
import { ReactComponent as SaveIcon } from "../../../assets/save-icon.svg";
import sampleImg from "../../../assets/user-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setClientDetailsIsEdit,
  createClient,
  updateClient,
  sendDetailsToClient,
} from "../redux/actions";
import CreatePasswordModal from "../../UserManager/userDetails/createPasswordModal";
import SpinnerComponent from "../../../components/spinner";
import {
  baseURL,
  isValidNumber,
  isValidNumberWithForwardSlash,
} from "../../../globalUtils/axiosConfig";
import { fetchClientDetailsUtils } from "../redux/actions";
import { handleChangeLevel } from "../utils";
import UpdatePasswordModal from "../../UserManager/userDetails/updatePasswordModal";
import _ from "lodash";

const UserDetailsSection = ({ isClientUpdate, isProfileCase }) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [userProfileImg, setUserProfileImg] = useState(null);
  const [isUserImgSelected, setIsUserImgSelected] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [utils, setUtils] = useState({});
  const [currentClient, setCurrentClient] = useState({});
  const [filteredContactPerson, setFilteredContactPerson] = useState([]);
  const [req, setReq] = useState(false);
  const [req2, setReq2] = useState(false);

  const [search, setSearch] = useState({
    company: "",
    company_id: "",
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;

  const {
    createClientLoading,
    currentClientObj,
    clientDetailsIsEdit,
    utilsData,
    sendDetailLoading,
    fetchCompanyLoading,
    fetchContactPersonLoading,
  } = useSelector((state) => state.clientManagerReducer);

  useEffect(() => {
    if (search.company.trim().length > 2) {
      dispatch(
        fetchClientDetailsUtils({
          search: { company: search.company.trim() },
          fetchCompanyLoading: true,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    if (search.company_id?.length > 1) {
      const { company_id } = search;
      dispatch(
        fetchClientDetailsUtils({
          search: { company_id },
          fetchContactPersonLoading: true,
        })
      );
    }
  }, [req2]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedCallApi2] = useState(() =>
    _.debounce(() => setReq2((prev) => !prev), 1000)
  );

  const onSearch = (e, key) => {
    setSearch({ ...search, [key]: e });
    debouncedCallApi();
  };

  useEffect(() => {
    setCurrentClient(currentClientObj);
    form.setFieldsValue(currentClientObj);
  }, [currentClientObj]);

  useEffect(() => {
    setUtils(utilsData);
    const filtered = utilsData?.contact_person?.filter(
      (el) => el.cusmainid == currentClientObj?.cus_contacts?.cusmainid
    );
    setFilteredContactPerson(filtered);
  }, [utilsData]);

  useEffect(() => {
    setLoading(createClientLoading);
    if (createClientLoading) {
      // setIsUserImgSelected(false);
    }
  }, [createClientLoading]);

  useEffect(() => {
    setIsEdit(clientDetailsIsEdit);
  }, [clientDetailsIsEdit]);

  const changeHandler = (event) => {
    const image = event.target.files[0];
    if (!image) {
      return false;
    }
    if (!image.name.match(/\.(jpg|png)$/)) {
      message.error("Photo should be png or jpg format.");
      return false;
    }
    if (image.size > 5120000) {
      message.error("Photo size should be less than 5MB.");
      return false;
    }
    setUserProfileImg(event.target.files[0]);
    setIsUserImgSelected(true);
  };

  const onChange = (e, name) => {
    handleChangeLevel(e.target.checked, name, form);
  };

  const onFinish = (values) => {
    if (password?.length < 6 && !isClientUpdate) {
      return message.error("Password is required");
    }

    const { level1, level2, level3, ...res } = values;

    if (level1 === undefined && level2 === undefined && level3 === undefined) {
      return message.error("Permissions required!");
    }

    let permissions = {
      level1,
      level2,
      level3,
    };

    isClientUpdate
      ? dispatch(
          updateClient({
            ...res,
            ...(password.length > 5 && { password }),
            ...(userProfileImg && { profile_pic: userProfileImg }),
            permissions,
            id: currentClientObj.id,
          })
        )
      : dispatch(
          createClient({
            ...res,
            password,
            profile_pic: userProfileImg,
            permissions,
          })
        );
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleSave = () => {
    form.submit();
  };

  const capitalizeFirstLetter = (str) =>
    str && str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      {isClientUpdate ? (
        <UpdatePasswordModal
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          email={currentClientObj.email}
          isClient={true}
        />
      ) : (
        <CreatePasswordModal
          setPassword={setPassword}
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          isUpdate={isClientUpdate}
        />
      )}

      <Row>
        <Col span={24} className="heading-col">
          <Row align="middle" justify="space-between">
            <Col>
              <div>
                <span className="heading-span">Client Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon onClick={handleSave} />
                )
              ) : (
                <EditIcon
                  onClick={() => dispatch(setClientDetailsIsEdit(true))}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section ${!isEdit && "is-edit"}`}
        >
          <Row>
            <Col md={5} lg={5} xl={5} xxl={4} className="photo-col">
              <div>
                <span>LOGO</span>
                <div className={`img-parent-div ${isEdit && "edit-image"}`}>
                  <div className="img-nested-div">
                    <img
                      width="100%"
                      height="100%"
                      src={
                        isUserImgSelected
                          ? URL.createObjectURL(userProfileImg)
                          : currentClient?.profile_picture
                          ? `${baseURL}${currentClientObj?.profile_picture}`
                          : sampleImg
                      }
                      alt="user-profile"
                    />
                  </div>
                  {isEdit && (
                    <div className="edit-icon-div">
                      <input
                        readOnly={loading}
                        disabled={!isEdit}
                        type="file"
                        name="profileImg"
                        id="profileImg"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={changeHandler}
                      />
                      <label htmlFor="profileImg">
                        <EditIcon height={14} width={14} fill="#eb078e" />
                      </label>
                    </div>
                  )}
                </div>
                {isEdit && (
                  <div className="text-div">
                    Upload a JPG or PNG file. Recommended image size 800 × 800:
                    Max 5 MB
                  </div>
                )}
              </div>
            </Col>
            <Col md={isEdit ? 19 : 15} lg={15} xl={15} xxl={16}>
              <Form
                {...formItemLayout}
                form={form}
                name="clientForm"
                onFinish={onFinish}
                initialValues={currentClient}
                scrollToFirstError
                labelWrap={true}
                layout="horizontal"
                className="user-detail-form"
                requiredMark={false}
              >
                <Row gutter={[30, 0]}>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Company Name!",
                        },
                      ]}
                      name="company_name"
                      label="Company Name"
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit || isProfileCase}
                        // readOnly={true}
                        className="department-select"
                        dropdownClassName="select-dropdown-custom"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        showSearch
                        loading={fetchCompanyLoading}
                        placeholder="Company Name"
                        optionFilterProp="children"
                        onSearch={(e) => onSearch(e, "company")}
                        onChange={(e, opt) => {
                          const { key } = opt;
                          setSearch({
                            // ...search,
                            company_id: key,
                          });
                          // const filtered = utils?.contact_person?.filter(
                          //   (el) => el.cusmainid == key
                          // );
                          // setFilteredContactPerson(filtered);
                          let values = form.getFieldsValue();
                          form.setFieldsValue({
                            ...values,
                            cuscontactid: null,
                          });
                          debouncedCallApi2();
                        }}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {utils?.companies?.map(({ company, cusid }) => {
                          return (
                            <Option key={cusid} value={company}>
                              {company}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Contact Person!",
                        },
                      ]}
                      name="cuscontactid"
                      label="Contact Person"
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit || isProfileCase}
                        // readOnly={true}
                        loading={fetchContactPersonLoading}
                        className="department-select"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        dropdownClassName="select-dropdown-custom"
                        // onSearch={(e) => onSearchContactPerson(e, "listname")}
                        showSearch
                        placeholder="Contact Person"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {utilsData?.contact_person?.map(
                          ({ listname, cuscontactid }) => {
                            return (
                              listname && (
                                <Option key={cuscontactid} value={cuscontactid}>
                                  {listname}
                                </Option>
                              )
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Contact Number!",
                        },
                        { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="contact_no"
                      label="Contact Number"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || isProfileCase}
                        placeholder="Contact Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Username!",
                        },
                      ]}
                      name="username"
                      label="username"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || isProfileCase} // || isClientUpdate
                        placeholder="Username"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <label>password</label>
                    <div
                      className="password-div"
                      style={{
                        cursor: !isEdit && "text",
                      }}
                    >
                      <span
                        onClick={() => isEdit && setPasswordModalOpen(true)}
                      >
                        {`${isClientUpdate ? "Change" : "Create"} Password`}
                      </span>
                    </div>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      name="email"
                      label="Email Address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid Email!",
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || isProfileCase} // || isClientUpdate
                        placeholder="Email Address"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Registration Number!",
                        },
                        {
                          pattern: isValidNumberWithForwardSlash,
                          message: "Invalid",
                        },
                      ]}
                      name="registration_no"
                      label="Registration Number"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || isProfileCase}
                        placeholder="Registration Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input Vat Number!",
                        },
                        { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="vat_no"
                      label="VAT Number"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || isProfileCase}
                        placeholder="Vat Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={12} xl={12} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select status!",
                        },
                      ]}
                      name="status"
                      label="status"
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit || isProfileCase}
                        // readOnly={true}
                        className="department-select"
                        dropdownClassName="select-dropdown-custom"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        showSearch
                        placeholder="Status"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {utils?.status?.map(({ key }) => {
                          return (
                            <Option key={key} value={key}>
                              {capitalizeFirstLetter(key || "")}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select address!",
                        },
                      ]}
                      name="address"
                      label="Address"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || isProfileCase}
                        placeholder="Company Address"
                      />
                    </Form.Item>
                  </Col>

                  <Col>
                    <Row gutter={[30, 0]} className="permissions-div">
                      <Col span={24}>
                        <label>Permissions</label>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="level1" valuePropName="checked">
                          <Checkbox
                            disabled={!isEdit || isProfileCase}
                            onChange={(e) => onChange(e, "level1")}
                          >
                            Level 1 - Order Details
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="level2" valuePropName="checked">
                          <Checkbox
                            disabled={!isEdit || isProfileCase}
                            onChange={(e) => onChange(e, "level2")}
                          >
                            Level 2 - Order Item Details
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="level3" valuePropName="checked">
                          <Checkbox
                            disabled={!isEdit || isProfileCase}
                            onChange={(e) => onChange(e, "level3")}
                          >
                            Level 3 - Production Departments Details
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>

            {currentClientObj?.id && !isEdit && !isProfileCase && (
              <Col span={4}>
                <div className="send-details-parent-div">
                  <Spin
                    spinning={sendDetailLoading}
                    className={`send-details-div`}
                  >
                    <div
                      className={`send-details-div ${
                        sendDetailLoading && "disabled-onclick"
                      }`}
                      onClick={() =>
                        dispatch(sendDetailsToClient(currentClient.id))
                      }
                    >
                      <span>Send Login</span>
                      <span>Details to Client</span>
                    </div>
                  </Spin>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <div className="empty-div"></div>
    </>
  );
};

export default UserDetailsSection;

import * as constants from "./constants";

export const fetchActivityTypes = (data) => {
  return {
    type: constants.FETCH_ACTIVITY_TYPES,
    data,
  };
};

export const fetchActivityTypesSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITY_TYPES_SUCCESS,
    data: data,
  };
};

export const updateActivityType = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY_TYPE,
    data: data,
  };
};

export const updateActivityTypeSuccess = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY_TYPE_SUCCESS,
    data: data,
  };
};

export const updateActivityTypeError = () => {
  return {
    type: constants.UPDATE_ACTIVITY_TYPE_ERROR,
  };
};

export const deleteActivityType = (data) => {
  return {
    type: constants.DELETE_ACTIVITY_TYPE,
    data: data,
  };
};

export const deleteActivityTypeSuccess = (data) => {
  return {
    type: constants.DELETE_ACTIVITY_TYPE_SUCCESS,
    data: data,
  };
};

export const deleteActivityTypeError = () => {
  return {
    type: constants.DELETE_ACTIVITY_TYPE_ERROR,
  };
};

export const createActivityType = (data) => {
  return {
    type: constants.CREATE_ACTIVITY_TYPE,
    data: data,
  };
};

export const createActivityTypeSuccess = (data) => {
  return {
    type: constants.CREATE_ACTIVITY_TYPE_SUCCESS,
    data: data,
  };
};

export const createActivityTypeError = () => {
  return {
    type: constants.CREATE_ACTIVITY_TYPE_ERROR,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

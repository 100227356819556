import { takeLatest, all, takeEvery } from "redux-saga/effects";
import * as usersManager from "../modules/UserManager/redux/sagas/handleUserManager";
import * as constants from "../modules/UserManager/redux/constants";
import * as departConstants from "../modules/DepartmentEmails/redux/constants";
import * as departEmails from "../modules/DepartmentEmails/redux/sagas/handleDepartEmails";
import * as activityTypesConstants from "../modules/ActivityTypes/redux/constants";
import * as activityTypes from "../modules/ActivityTypes/redux/sagas/handleActivityTypes";
import * as clientManager from "../modules/ClientsManager/redux/sagas/handleClientManager";
import * as clientManagerConstants from "../modules/ClientsManager/redux/constants";
import * as handleSales from "../modules/Sales/redux/sagas/handleSales";
import * as handleSalesConstants from "../modules/Sales/redux/constants";
import * as activities from "../modules/Activities/redux/sagas/activitiesSagas";
import * as activitiesConstants from "../modules/Activities/redux/constants";
import * as authSagas from "../modules/login/redux/sagas/handleAuth";
import * as authConstants from "../modules/login/redux/constants";
import * as productionSagas from "../modules/Production/redux/sagas/handleProduction";
import * as productionConstants from "../modules/Production/redux/constants";
import * as notificationsSagas from "../modules/notifications/notificationsRedux/saga";
import * as notificationsConstants from "../modules/notifications/notificationsRedux/constant";

function* watcherSaga() {
  yield takeLatest(
    notificationsConstants.FETCH_NOTIFICATIONS_LIST,
    notificationsSagas.handleFetchAllNotifications
  );
  yield takeLatest(
    notificationsConstants.FETCH_NOTIFICATIONS,
    notificationsSagas.handleFetchUnreadNotificationCount
  );
  yield takeLatest(
    notificationsConstants.UPDATE_NOTIFICATION,
    notificationsSagas.handleUpdateNotification
  );

  yield takeLatest(constants.CREATE_USER, usersManager.handleCreateUser);
  yield takeLatest(constants.FETCH_ALL_USERS, usersManager.handleFetchAllUsers);
  yield takeLatest(
    constants.FETCH_USERS_UTIL_DATA,
    usersManager.handleFetchUserDetailsUtils
  );
  yield takeEvery(constants.DELETE_USER, usersManager.handleDeleteUser);

  yield takeLatest(constants.SAVE_DEPARTMENT, usersManager.handleAddDepartment);
  yield takeLatest(constants.FETCH_USER, usersManager.handleFetchUser);
  yield takeEvery(constants.UPDATE_USER, usersManager.handleUpdateUser);
  yield takeEvery(
    constants.UPDATE_USER_SETTINGS,
    usersManager.handleUpdateUserSettings
  );
  yield takeLatest(
    constants.SEND_DETAILS_TO_USER,
    usersManager.handleSendDetailsToUser
  );

  yield takeLatest(
    departConstants.FETCH_DEPART_EMAILS,
    departEmails.handleFetchDepartEmails
  );
  yield takeLatest(
    departConstants.UPDATE_DEPART_EMAIL,
    departEmails.handleUpdateDepartEmail
  );
  yield takeLatest(
    activityTypesConstants.FETCH_ACTIVITY_TYPES,
    activityTypes.handleFetchActivityTypes
  );
  yield takeEvery(
    activityTypesConstants.UPDATE_ACTIVITY_TYPE,
    activityTypes.handleUpdateActivityTypes
  );
  yield takeEvery(
    activityTypesConstants.DELETE_ACTIVITY_TYPE,
    activityTypes.handleDeleteActivityTypes
  );
  yield takeEvery(
    activityTypesConstants.CREATE_ACTIVITY_TYPE,
    activityTypes.handleCreateActivityType
  );
  yield takeLatest(
    clientManagerConstants.FETCH_ALL_CLIENTS,
    clientManager.handleFetchAllClients
  );
  yield takeEvery(
    clientManagerConstants.DELETE_CLIENT,
    clientManager.handleDeleteClient
  );
  yield takeLatest(
    clientManagerConstants.FETCH_CLIENT_UTIL_DATA,
    clientManager.handleFetcClientDetailsUtils
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES,
    handleSales.handleFetchSalesData
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES_SEARCH_UTILS,
    handleSales.handleFetchSalesUtilsData
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES_SECOND_LEVEL,
    handleSales.handleFetchSalesSecondLevelData
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES_GRAPH,
    handleSales.handleFetchSalesGraphData
  );
  yield takeLatest(
    clientManagerConstants.CREATE_CLIENT,
    clientManager.handleCreateClient
  );
  yield takeLatest(
    clientManagerConstants.FETCH_CLIENT,
    clientManager.handleFetchClient
  );
  yield takeEvery(
    clientManagerConstants.UPDATE_CLIENT,
    clientManager.handleUpdateClient
  );
  yield takeLatest(
    clientManagerConstants.SEND_DETAILS_TO_CLIENT,
    clientManager.handleSendDetailsToClient
  );
  yield takeEvery(
    clientManagerConstants.UPDATE_CLIENT_SETTINGS,
    clientManager.handleUpdateClientSettings
  );

  yield takeEvery(
    activitiesConstants.CREATE_ACTIVITIES,
    activities.handleCreateActivities
  );
  yield takeEvery(
    activitiesConstants.DELETE_ACTIVITY,
    activities.handleDeleteActivity
  );
  yield takeEvery(
    activitiesConstants.FETCH_ACTIVITIES,
    activities.handleFetchActivities
  );
  yield takeEvery(
    activitiesConstants.FETCH_ACTIVITIES_SEARCH_DATA,
    activities.handleFetchActivitiesUtilsData
  );
  yield takeEvery(
    activitiesConstants.UPDATE_ACTIVITY,
    activities.handleUpdateActivity
  );
  yield takeEvery(
    activitiesConstants.ADD_ACTIVITY_ATTACH,
    activities.handleAddActivityAttach
  );
  yield takeEvery(
    activitiesConstants.DELETE_ACTIVITY_ATTACH,
    activities.handleDeleteActivityAttach
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_SEARCH_UTILS,
    productionSagas.handleFetchProdSearchUtils
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_FIRST_LEVEL,
    productionSagas.handleFetchProdFirstLevel
  );
  yield takeLatest(
    productionConstants.FETCH_PROD_GRAPH_DATA,
    productionSagas.handleFetchProdGraphData
  );
  yield takeLatest(
    productionConstants.FIRST_LEVEL_ADD_COMMENT,
    productionSagas.handleAddFirstLevelComment
  );
  yield takeEvery(
    productionConstants.ADD_PRODUCTION_ATTACH,
    productionSagas.handleAddProdAttach
  );
  yield takeEvery(
    productionConstants.DELETE_PROD_ATTACH,
    productionSagas.handleDeleteProdAttach
  );
  yield takeLatest(
    productionConstants.FETCH_PROD_SECOND_LEVEL,
    productionSagas.handleFetchProdSecondLevel
  );

  yield takeEvery(
    productionConstants.CREATE_SECOND_LEVEL,
    productionSagas.handleCreateProdSecondLevel
  );

  yield takeEvery(
    productionConstants.UPDATE_SECOND_LEVEL,
    productionSagas.handleUpdateProdSecondLevel
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_THIRD_LEVEL,
    productionSagas.handleFetchProdThirdLevel
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_FOURTH_LEVEL,
    productionSagas.handleFetchProdFourthLevel
  );

  yield takeEvery(
    productionConstants.VERIFY_PIN,
    productionSagas.handleVerifyPin
  );

  yield takeEvery(
    productionConstants.UPDATE_OPERATOR,
    productionSagas.handleUpdateOperator
  );

  yield takeEvery(
    productionConstants.STOP_OPERATOR,
    productionSagas.handleStopOperator
  );

  yield takeEvery(
    productionConstants.ADD_OPERATOR,
    productionSagas.handleAddOperator
  );

  yield takeEvery(
    productionConstants.SEND_MESSAGE,
    productionSagas.handleSendMessage
  );

  yield takeEvery(
    productionConstants.FETCH_PREV_CHAT,
    productionSagas.handleFetchPrevChat
  );

  yield takeEvery(authConstants.LOGIN, authSagas.handleLoginUser);
  yield takeEvery(authConstants.LOGOUT, authSagas.handleLogoutUser);
}

export function* rootSaga() {
  yield all([watcherSaga()]);
}

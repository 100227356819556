import React from "react";
import { Button, Checkbox, Col, Row, Select } from "antd";
import { ReactComponent as CircularTick } from "../assets/circular-tick-black.svg";

const { Option } = Select;

const AMultiSelectControlledOutside = (props) => {
  const {
    selectTags,
    onSelectChange,
    data,
    debouncedCallApi,
    placeholder,
    open,
    setOpen,
  } = props;

  const handleOnClick = () => {
    setTimeout(() => {
      setOpen(false);
    }, 500);
    debouncedCallApi();
  };

  return (
    <div className="" style={{ width: "100%" }}>
      <Select
        {...props}
        className={`${props?.isProcessSteps && `select-align-center`}`}
        onClick={(e) => {
          if (!open) {
            setOpen(true);
          }
          e.stopPropagation();
          e.preventDefault();
        }}
        onChange={(e) => {
          onSelectChange(e);
        }}
        getPopupContainer={() =>
          document.getElementsByClassName(
            "ant-layout-content layout-content site-layout-background custom-textarea"
          )[0]
        }
        optionLabelProp="label"
        open={open}
        placeholder={placeholder}
        mode="multiple"
        maxTagCount={0}
        size="small"
        defaultValue={selectTags}
        value={selectTags}
        maxTagPlaceholder={() =>
          props?.isProcessSteps ? (
            <CircularTick width={16} />
          ) : (
            `${selectTags.length}`
          )
        }
        menuItemSelectedIcon=""
        style={{
          width: "100%",
          marginBottom: "1px",
          // minWidth: "140px"
        }}
        listHeight={540}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Row justify="space-around" align="middle">
                <Col>
                  <Button className="done-btn" onClick={handleOnClick}>
                    Done
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
        dropdownClassName="multi-select-custom"
      >
        {data.length > 0 && (
          <Option
            label={"Select All"}
            value={selectTags.length === data.length ? "not-all" : "all"}
          >
            <div className="multiselect-checkbox">
              <Checkbox
                checked={selectTags && selectTags.length === data.length}
              >
                <div className="select-all-div">
                  <div>Select All</div>
                </div>
              </Checkbox>
            </div>
          </Option>
        )}

        {data.map((item) => {
          return (
            <Option label={item.label} value={item.value} key={item.value}>
              <div className="multiselect-checkbox">
                <Checkbox
                  checked={selectTags && selectTags.includes(item.value)}
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                        fontSize: "13px",
                        color: "#231f20",
                        fontWeight: 400,
                      }}
                    >
                      {item.label}
                    </div>
                  </div>
                </Checkbox>
              </div>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default AMultiSelectControlledOutside;

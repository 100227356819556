import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ActivitiesReporting from "./activitiesReporting";
import ActivityTable from "./activityTable";
import { fetchActivityTypes } from "../ActivityTypes/redux/actions";
import { fetchActivitiesSearchUtils } from "./redux/actions";

const Activities = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActivityTypes({ page: 1, limit: 100, search: {} }));
    dispatch(fetchActivitiesSearchUtils());
  }, []);

  return (
    <div className="page-top-div">
      <span className="text-span">Activities</span>
      <ActivitiesReporting />
      <ActivityTable />
    </div>
  );
};

export default Activities;

import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useSelector } from "react-redux";

const keys = ["hour"];

const ValueOutside = ({ bars, yScale }) => {
  return bars.map((bar) => {
    const { key, width, x, data } = bar;
    const { value } = data;
    const labelMargin = 20;
    return (
      <g
        key={key}
        transform={`translate(${x}, ${yScale(value) - labelMargin})`}
        fill="#757575"
      >
        <text
          // textAnchor="top"
          fontSize="11px"
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {/* {value} */}
          {value && value > 0
            ? `${Math.trunc(value)}h${Math.trunc((value % 1) * 60)}m`
            : null}
        </text>
      </g>
    );
  });
};

const CustomLeftLabel = (data) => {
  const { y, value } = data;
  return (
    <g>
      <text x={-20} y={y + 4} fontSize="12px" fill="#757575">
        {value}
      </text>
    </g>
  );
};

const HoursByActivityGraph = () => {
  const [data, setData] = useState([]);
  const { calculate_activity_time, colorsObjArr } = useSelector(
    (state) => state.activitiesReducer
  );

  const getColor = (bar) =>
    colorsObjArr?.filter((el) => el?.key === bar?.indexValue)[0]?.color;

  useEffect(() => {
    let updated = calculate_activity_time.map((el) => ({
      ...el,
      label: el.key,
      hour: el.hour > 0 ? (el.hour / 60).toFixed(2) : 0,
    }));
    setData(updated);
  }, [calculate_activity_time]);

  return (
    <div style={{ height: "400px" }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="label"
        margin={{
          top: 50,
          right: 0,
          bottom: 140,
          left: 50,
        }}
        colors={getColor}
        padding={0.4}
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={false}
        enableGridY={true}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: null,
              },
            },
            domain: {
              line: {
                stroke: "", // verticle line
                strokeWidth: 2,
              },
            },
            legend: {
              text: {
                fontWeight: "bold",
                letterSpacing: "2px",
              },
            },
          },
          grid: {
            line: {
              stroke: `rgb(231 232 236 / 80%)`,
              strokeWidth: 2,
              strokeDasharray: "2, 4",
            },
          },
        }}
        layers={["grid", "axes", "bars", "markers", ValueOutside]}
        legends={[
          {
            dataFrom: "keys",
            // data: keys.map((key, index) => ({
            //   color: colors[index],
            //   key,
            //   // label: key === "ordered" ? 1 : 2,
            //   label: key,
            // })),
            anchor: "bottom",
            direction: "row",
            translateY: 63,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: "left-to-right",
            symbolSize: 20,
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Hours",
          legendPosition: "middle",
          legendOffset: -40,
          renderTick: CustomLeftLabel,
          tickValues: 3,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 60,
          legend: "Activity",
          legendPosition: "middle",
          legendOffset: 120,
          // renderTick: CustomBottomLabel,
        }}
      />
    </div>
  );
};

export default HoursByActivityGraph;

import React from 'react';
import { Row, Col } from 'antd';
import ProductionManagerTable from './ProductionManagerTable';
import GetSyncData from '../../components/getSyncDataComponent';

const ProductionManager = () => {
  return (
    <>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row justify='space-between' align='middle'>
            <Col span={16}>
              <span className='heading-span'>Production Register</span>
            </Col>
            <Col>
              <GetSyncData />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ProductionManagerTable />
        </Col>
      </Row>
    </>
  );
};

export default ProductionManager;

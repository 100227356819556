import React, { useEffect, useState } from "react";
import { Table, Input, Tag, Space, Popconfirm } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  fetchAllUsers,
  setUserDetailsIsEdit,
} from "./redux/actions";
import SpinnerComponent from "../../components/spinner";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import CustomPagination from "../../components/customPagination/CustomPagination";

const UsersRegisterTable = () => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);

  const { allUsers, fetchUsersLoading, deleteUserLoading, totalRecords } =
    useSelector((state) => state.userManagerReducer);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllUsers({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllUsers({ page: paramsPage, limit: resultPerPage, search })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allUsers);
  }, [allUsers]);

  useEffect(() => {
    setFetchLoading(fetchUsersLoading);
  }, [fetchUsersLoading]);

  // useEffect(() => {
  //   setFetchLoading(deleteUserLoading);
  // }, [deleteUserLoading]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteUser(record));
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleNavigate = (id) =>
    navigate(`/settings/users-manager/update-user/${id}`);

  const columns = [
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">First name</div>
            <div>{searchInput("first_name")}</div>
          </div>
        );
      },
      dataIndex: "first_name",
      key: "first_name",
      // sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "first_name" && sortedInfo?.order,
      ellipsis: true,
      width: "15%",
      render: (first_name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{first_name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">last name</div>
            <div>{searchInput("surname")}</div>
          </div>
        );
      },
      dataIndex: "surname",
      key: "surname",
      // sorter: (a, b) => a.surname.localeCompare(b.surname),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "surname" && sortedInfo?.order,
      ellipsis: true,
      width: "20%",
      render: (surname, { id }) => (
        <div onClick={() => handleNavigate(id)}>{surname}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">username</div>
            <div>{searchInput("username")}</div>
          </div>
        );
      },
      dataIndex: "username",
      key: "username",
      // sorter: (a, b) => (a.username || "").localeCompare(b.username || ""),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "username" && sortedInfo?.order,
      ellipsis: true,
      width: "15%",
      render: (username, { id }) => (
        <div onClick={() => handleNavigate(id)}>{username}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">user type</div>
            <div>{searchInput("user_type")}</div>
          </div>
        );
      },
      dataIndex: "user_type",
      key: "user_type",
      // sorter: (a, b) => a.user_type.localeCompare(b.user_type),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "user_type" && sortedInfo?.order,
      ellipsis: true,
      width: "15%",
      render: (user_type, { id }) => (
        <div onClick={() => handleNavigate(id)}>{user_type}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">department</div>
            <div>{searchInput("department")}</div>
          </div>
        );
      },
      dataIndex: "departments_with_steps",
      key: "departments_with_steps",
      // sorter: (a, b) =>
      //   (a.departments_with_steps?.length || 0) -
      //   (b.departments_with_steps?.length || 0),
      sorter: null, // true
      sortOrder:
        sortedInfo?.columnKey === "departments_with_steps" && sortedInfo?.order,
      ellipsis: true,
      width: "15%",
      render: (departments_with_steps, { id }) => {
        return (
          <div onClick={() => handleNavigate(id)}>
            {departments_with_steps.map((depart) => {
              return <div>{`${depart.name} - ${depart.position}`}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">status</div>
            <div>{searchInput("status")} </div>
          </div>
        );
      },
      dataIndex: "status",
      key: "status",
      // sorter: (a, b) => a.status.localeCompare(b.status),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "status" && sortedInfo?.order,
      ellipsis: true,
      width: "10%",
      align: "center",
      render: (status, { id }) => {
        let color;
        let text;
        switch (status) {
          case "active":
            color = "#7cd175";
            text = "Active";
            break;
          case "inactive":
            color = "#fa7470";
            text = "Inactive";
            break;
          default:
            break;
        }
        return (
          <Tag
            onClick={() => handleNavigate(id)}
            color={color}
            style={{
              color: "#231f20",
              width: "70px",
              height: "20px",
              textAlign: "center",
            }}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: null,
      dataIndex: "",
      key: "action",
      //   width: "15%",
      align: "center",
      render: (_, record) => {
        return deleteUserLoading && record.id === currentDelRecord.id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align="baseline">
            <EditIcon
              width={18}
              height={18}
              className="custom-icon"
              onClick={() => {
                handleNavigate(record.id);
                dispatch(setUserDetailsIsEdit(true));
              }}
            />
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon width={18} height={18} className="custom-icon" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className="special-table"
        columns={columns}
        scroll={{ x: true }}
        onChange={handleChange}
        dataSource={dataSource}
        pagination={false}
        sortUpIcon={<UpOutlined />}
        sortDownIcon={<DownOutlined />}
        loading={fetchLoading}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        resultPerPage={resultPerPage}
        setResultPerPage={setResultPerPage}
        debouncedCallApi={debouncedCallApi}
        totalRecord={totalRecords}
      />
    </>
  );
};

export default UsersRegisterTable;

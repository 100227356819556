export const sortClientDataByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.id.localeCompare(b.id));
  } else {
    return data.sort((a, b) => b.id.localeCompare(a.id));
  }
};

export const sortClientDataByAmount = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.Customers - b.Customers);
  } else {
    return data.sort((a, b) => b.Customers - a.Customers);
  }
};

export const sortSalesRepDataByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.id.localeCompare(b.id));
  } else {
    return data.sort((a, b) => b.id.localeCompare(a.id));
  }
};

export const sortSalesRepDataByAmount = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.Actual - b.Actual);
  } else {
    return data.sort((a, b) => b.Actual - a.Actual);
  }
};

export const sortClients = (clients) =>
  clients.sort((a, b) => (a.company || "").localeCompare(b.company || ""));

export const sortSalesRep = (clients) =>
  clients.sort((a, b) => (a.listname || "").localeCompare(b.listname || ""));

export const sortSoNumber = (soNumber) =>
  soNumber.sort((a, b) => (a.soid || 0) - (b.soid || 0));

export const getOptions = (all, checkedList) => {
  let checkedlist = [];
  let unCheckedlist = [];

  all.forEach((el) =>
    checkedList.includes(el?.value)
      ? checkedlist.push(el)
      : unCheckedlist.push(el)
  );

  return [...checkedlist.sort(), ...unCheckedlist];
};

export const sortContactPerson = (clients) => {
  let filtered = clients.filter(el => el.listname !== null)
  return filtered.sort((a, b) => (a.listname || "").localeCompare(b.listname || ""));
}

import * as constants from "./constants";

const initialState = {
  secondLevelData: [],
  loading: false,
};

const salesSecondLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_SALES_SECOND_LEVEL:
      return {
        ...state,
        loading: true,
        secondLevelData: [],
      };
    case constants.FETCH_SALES_SECOND_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        secondLevelData: action.data,
      };
    case constants.FETCH_SALES_SECOND_LEVEL_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default salesSecondLevelReducer;

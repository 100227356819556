const NEW_NOTIFICATION_ADDED = "NEW_NOTIFICATION_ADDED";
const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";
const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
const FETCH_NOTIFICATIONS_LIST = "FETCH_NOTIFICATIONS_LIST";
const FETCH_NOTIFICATIONS_LIST_SUCCESS = "FETCH_NOTIFICATIONS_LIST_SUCCESS";
const FETCH_NOTIFICATIONS_LIST_ERROR = "FETCH_NOTIFICATIONS_LIST_ERROR";
const NOTIFICATION_HAS_READ = "NOTIFICATION_HAS_READ";
const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";

export {
  NEW_NOTIFICATION_ADDED,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  NOTIFICATIONS_LOADING,
  FETCH_NOTIFICATIONS_LIST,
  FETCH_NOTIFICATIONS_LIST_SUCCESS,
  FETCH_NOTIFICATIONS_LIST_ERROR,
  NOTIFICATION_HAS_READ,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
};

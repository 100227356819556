import React, { useRef } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Popconfirm, Popover, Row, message, Button } from "antd";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as AttachIcon } from "../../assets/attach.svg";
import { baseURL } from "../../globalUtils/axiosConfig";
import { useDispatch } from "react-redux";
import { addActivityAttach, deleteActivityAttach } from "./redux/actions";

const NonEditRowAttachments = ({
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  setCurrentUpdateObj,
  currentUpdateObj,
  record,
}) => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const handleDeleteImage = (record) => {
    let filteredImages = images.filter((image) => image.key !== record.key);

    setImages(filteredImages);
    if (!record.isLocal) {
      dispatch(
        deleteActivityAttach({ ...record, recordId: currentUpdateObj.id })
      );
      // setCurrentUpdateObj({ images: filteredImages });
    }
  };

  const handleShowImage = (image) => {
    let url = image.isLocal
      ? URL.createObjectURL(image)
      : `${baseURL}${image.image}`;
    window.open(url, "_blank");
  };

  const hide = () => {
    setAttachPopupVisible(false);
  };

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj["key"] = Math.round(Math.random() * 1000000);
      imgObj["isLocal"] = true;
      if (imgObj) {
        const { user_id, id } = currentUpdateObj;
        dispatch(
          addActivityAttach({
            data: { user_id, id, images: [...images, imgObj] },
            setCurrentUpdateObj,
          })
        );
      }
    } else {
      message.error("This file format is not supported.");
    }
  };

  return (
    <Popover
      placement="bottom"
      overlayClassName="activities-attach-popover"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      content={
        <>
          <Row>
            <Col span={24}>
              <div className="close-icon-div">
                <CloseOutlined onClick={hide} />
              </div>
            </Col>
            {images.map((el) => {
              const { name, key } = el;
              return (
                <Col span={24} key={key}>
                  <Row justify="start" align="middle">
                    <Col span={3}>
                      <AttachIcon
                        className="rotate-icon activities-attach"
                        width={16}
                        fill="#231f20"
                      />
                    </Col>
                    <Col span={18}>
                      <span
                        className="name-span"
                        onClick={() => handleShowImage(el)}
                      >
                        {name}
                      </span>
                    </Col>
                    <Col span={3}>
                      <Popconfirm
                        overlayClassName="activities-popconfirm"
                        title="Are you sure to delete this attachment?"
                        onConfirm={() => handleDeleteImage(el)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon
                          fill="#eb078e"
                          width={18}
                          height={18}
                          className="del-icon"
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Button
            icon={<PlusOutlined />}
            className="add-attach-btn"
            size="middle"
            onClick={() => inputFile.current.click()}
          >
            Attachment
          </Button>
          <input
            type="file"
            id="file"
            multiple
            accept=""
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(e) => handleImage(e.target.files[0], e)}
          />
        </>
      }
      title={null}
      trigger="click"
      visible={attachPopupVisible}
      onVisibleChange={(visible) => {
        setImages(record?.images || []);
        setAttachPopupVisible(visible);
      }}
    >
      <div className="attach-parent-div">
        <AttachIcon
          className="rotate-icon activities-attach"
          width={16}
          fill="#231f20"
        />
        <div>{images?.length || 0}</div>
      </div>
    </Popover>
  );
};

export default NonEditRowAttachments;

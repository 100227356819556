import { sortClients, sortContactPerson, sortSalesRep, sortSoNumber } from "../utils";
import * as constants from "./constants";

const initialState = {
  salesDataObj: {},
  fetchLoading: false,
  totalRecords: 0,
  clientOptions: [],
  salesRepsOptions: [],
  soNumbersOptions: [],
  contactPersonsOptions: [],
  fetchUtilsLoading: false,
};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_SALES:
      return {
        ...state,
        fetchLoading: true,
      };
    case constants.FETCH_SALES_SUCCESS:
      const { total } = action.data;
      return {
        ...state,
        fetchLoading: false,
        salesDataObj: action.data,
        totalRecords: total,
      };
    case constants.FETCH_SALES_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };
    case constants.FETCH_SALES_SEARCH_UTILS:
      return {
        ...state,
        fetchUtilsLoading: true,
      };
    case constants.FETCH_SALES_SEARCH_UTILS_SUCCESS:
      const { clients, sales_reps, so_numbers, order_contacts } = action.data;

      const clientsSorted = sortClients(clients);
      const salesRepSorted = sortSalesRep(sales_reps);
      const soNumberSorted = sortSoNumber(so_numbers);
      const contactPersonSorted = sortContactPerson(order_contacts);

      let clientOptions = clientsSorted.map((el) => {
        const { company, cusid } = el;
        return { value: cusid, label: company };
      });

      let salesRepsOptions = salesRepSorted.map((el) => {
        const { listname, staffid } = el;
        return { value: staffid, label: listname };
      });

      let soNumbersOptions = soNumberSorted.map((el) => {
        const { soid } = el;
        return { value: soid, label: soid };
      });

      let contactPersonsOptions = contactPersonSorted.map((el) => {
        const { listname, cuscontactid } = el;
        return { value: cuscontactid, label: listname };
      });

      return {
        ...state,
        fetchUtilsLoading: false,
        clientOptions,
        salesRepsOptions,
        soNumbersOptions,
        contactPersonsOptions
      };
    case constants.FETCH_SALES_SEARCH_UTILS_ERROR:
      return {
        ...state,
        fetchUtilsLoading: false,
      };

    case constants.SALES_CLEAN_UP_FUNC:
      return {
        ...state,
        salesDataObj: {},
        fetchLoading: false,
        fetchUtilsLoading: false,
      };
    default:
      return state;
  }
};

export default salesReducer;

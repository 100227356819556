import { Comment, List, Input, Popover } from "antd";
import { ReactComponent as SendIcon } from "../../assets/send-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/chat-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross-icon.svg";
import UseGetUserRole from "../../components/useGetUserRole";
import { useDispatch, useSelector } from "react-redux";
import { setChatRecordId, sendMessage } from "./redux/actions";
import MessageChannel from "./actionCable/messageChannel";
import { useEffect, useRef, useState } from "react";
import moment from "moment";

const ChatPopup = ({
  handleChatOnClick,
  chatPopupVisible,
  setChatPopupVisible,
  record,
  setCurrentPopupRecordId,
}) => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const [isSuperAdmin, isAdmin, , isSalesUser, isCleint] = UseGetUserRole();
  const [data, setData] = useState([]);
  const [content, setContent] = useState("");
  const scrollDiv = useRef();
  const bottomRef = useRef(null);
  const { user } = useSelector((state) => state.authReducer);
  const { currentChatRecordId, chatData, fetchChatLoading } = useSelector(
    (state) => state.chatReducer
  );

  const onSearch = (value) => {
    dispatch(
      sendMessage({
        parent: {
          id: currentChatRecordId,
          model: "SoMain",
        },
        sender: isCleint ? "cus_contacts" : "cpy_staff",
        receiver: !isCleint ? "cus_contacts" : "cpy_staff",
        content: value,
      })
    );
    setContent("");
  };

  const handleCrossClick = () => {
    console.log("clicked");
    setCurrentPopupRecordId(null);
    setChatPopupVisible(false);
    dispatch(setChatRecordId({ id: null }));
  };

  useEffect(() => {
    setData(chatData);
    setTimeout(() => {
      scrollToBottom();
    }, 10);
  }, [chatData]);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const isAllow = () => {
    let salesUserCond =
      isSalesUser && record?.cpy_staff_id == user?.quick_easy_id;
    return isSuperAdmin || isAdmin || salesUserCond;
  };

  return (
    <>
      <MessageChannel />
      <Popover
        placement="bottomRight"
        // content={<a onClick={hide}>Close</a>}
        title={
          <>
            <div className="cross-icon-div">
              <CrossIcon onClick={handleCrossClick} />
            </div>
            <List
              className="comment-list"
              // header={`${data.length} replies`}
              itemLayout="horizontal"
              dataSource={data}
              style={{ width: "617px" }}
              ref={scrollDiv}
              loading={fetchChatLoading}
              footer={
                <>
                  {
                    // isSuperAdmin || isAdmin
                    (isSalesUser &&
                      String(user?.quick_easy_id) ===
                        String(record?.cpy_staff_id)) ||
                    (isCleint && user?.cuscontactid === record?.contact_id) ? (
                      <Search
                        placeholder="Type a Message"
                        allowClear
                        enterButton={<SendIcon width={22} height={22} />}
                        size="middle"
                        onSearch={onSearch}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                      />
                    ) : null
                  }
                </>
              }
              renderItem={(item, index) => {
                let diff = moment().diff(item?.created_at, "day");
                let fromNow = moment(item?.created_at).fromNow();
                let dateFormat = moment(item?.created_at).format("DD-MM-YYYY");
                return (
                  <>
                    <li>
                      <div className="comment-list-date-div">
                        {diff < 2 ? fromNow : dateFormat}
                      </div>
                      <Comment
                        className={`${
                          (isCleint && item?.sendable_type === "CusContacts") ||
                          (isSalesUser && item?.sendable_type === "CpyStaff")
                            ? "send"
                            : "received"
                        }`}
                        actions={null} // item.actions
                        author={
                          item?.sendable_type === "CpyStaff"
                            ? record?.sales_rep_name
                            : record?.contact_person_name
                        }
                        avatar={null} // item.avatar
                        content={item.content}
                        datetime={moment(item?.created_at).format("HH:mm")}
                      />
                    </li>
                    {data.length === index + 1 && <div ref={bottomRef} />}
                  </>
                );
              }}
            />
          </>
        }
        trigger="click"
        visible={chatPopupVisible}
        openClassName=""
        overlayClassName="prod-chat-popup"
        // onVisibleChange={handleVisibleChange}
        getPopupContainer={() =>
          document.getElementsByClassName(
            "ant-layout-content layout-content site-layout-background custom-textarea"
          )[0]
        }
        onVisibleChange={(e) => {
          setChatPopupVisible(e);
          // setCurrentObj(record);
        }}
      >
        <ChatIcon
          width={18}
          className="custom-icon"
          onClick={() => handleChatOnClick(record)}
        />
      </Popover>
    </>
  );
};

export default ChatPopup;

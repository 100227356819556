import { useState } from "react";
import { useParams, useNavigate } from "react-router";
import { message as antdAlert } from "antd";
import axios from "axios";
import { baseURL } from "../../globalUtils/axiosConfig";

const UseResetPassword = (Form) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { token } = useParams();
  const navigate = useNavigate();

  const handlePassword = async ({ password_confirmation, password }) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${baseURL}/api/v1/auth/reset_password`,
        {
          reset_password_token: token,
          password,
          password_confirmation,
        }
      );

      const { success, message } = response.data;

      if (success) {
        antdAlert.success(message);
        setLoading(false);
        form?.resetFields();
        navigate("/");
      } else {
        antdAlert.error(message);
        setLoading(false);
      }
    } catch (error) {
      antdAlert.error("Something went wrong!");
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log("values", values);
    handlePassword(values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return [onFinish, onFinishFailed, loading, form];
};

export default UseResetPassword;

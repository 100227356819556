import * as constants from "./constants";

const initialState = {
  departEmailsList: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
};

const departEmailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_DEPART_EMAILS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_DEPART_EMAILS_SUCCESS:
      const { department_emails, total_departments } = action.data;
      return {
        ...state,
        fetchingLoading: false,
        departEmailsList: department_emails,
        totalRecords: total_departments,
      };
    case constants.FETCH_DEPART_EMAILS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };
    case constants.UPDATE_DEPART_EMAIL:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_DEPART_EMAIL_SUCCESS:
      const updated = state.departEmailsList.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        departEmailsList: updated,
        updateLoading: false,
      };
    case constants.UPDATE_DEPART_EMAIL_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    default:
      return state;
  }
};

export default departEmailsReducer;

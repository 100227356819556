import * as constants from "./constants";

export const fetchActivities = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES,
    data,
  };
};

export const fetchActivitiesSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtils = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA,
    data,
  };
};

export const fetchActivitiesSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
    data,
  };
};

export const updateActivity = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY,
    data: data,
  };
};

export const updateActivitySuccess = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY_SUCCESS,
    data: data,
  };
};

export const updateActivityError = () => {
  return {
    type: constants.UPDATE_ACTIVITY_ERROR,
  };
};

export const deleteActivity = (data) => {
  return {
    type: constants.DELETE_ACTIVITY,
    data: data,
  };
};

export const deleteActivitySuccess = (data) => {
  return {
    type: constants.DELETE_ACTIVITY_SUCCESS,
    data: data,
  };
};

export const deleteActivityError = () => {
  return {
    type: constants.DELETE_ACTIVITY_ERROR,
  };
};

export const createActivities = (data) => {
  return {
    type: constants.CREATE_ACTIVITIES,
    data: data,
  };
};

export const createActivitiesSuccess = (data) => {
  return {
    type: constants.CREATE_ACTIVITIES_SUCCESS,
    data: data,
  };
};

export const createActivitiesError = () => {
  return {
    type: constants.CREATE_ACTIVITIES_ERROR,
  };
};

export const deleteActivityAttach = (data) => {
  return {
    type: constants.DELETE_ACTIVITY_ATTACH,
    data: data,
  };
};

export const deleteActivityAttachSuccess = (data) => {
  return {
    type: constants.DELETE_ACTIVITY_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteActivityAttachError = () => {
  return {
    type: constants.DELETE_ACTIVITY_ATTACH_ERROR,
  };
};

export const updateIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_ISADD_ISUPDATE,
    data,
  };
};

export const addActivityAttach = (data) => {
  return {
    type: constants.ADD_ACTIVITY_ATTACH,
    data: data,
  };
};

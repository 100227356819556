import React, { useState, useEffect } from "react";
import {
  BellFilled,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Layout, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
// import { getUserProfileDetailAction } from "./profileRedux/profileActions";
import digitalExpressLogo from "../../../assets/digital-express.png";
// import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import useProfile from "./useSidebar";
import { ReactComponent as DotIcon } from "../../../assets/dot.svg";
import { ReactComponent as UserIcon } from "../../../assets/user-icon.svg";
import collapsedIcon from "../../../assets/collapsed-icon.jpg";
import UseWindowSize from "./useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../globalUtils/axiosConfig";
import { logoutUser } from "../../login/redux/actions";
import Notifications from "../../notifications";
import NotificationChannel from "../../notifications/notificationChannel";
import {
  fetchAllNotifications,
  fetchUnreadNotifications,
} from "../../notifications/notificationsRedux/notificationActions";

const SidebarComponent = (props) => {
  const { Sider } = Layout;
  const [shouldCollapse, setShouldCollapse] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logoutLoading } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const [menuItemsData] = useProfile();
  const screenWidth = UseWindowSize();
  const [notificationModalvisible, setNotificationModalVisible] =
    useState(false);
  const { unreadNotifications } = useSelector(
    (state) => state.notificationsReducer
  );

  let current_role = user?.user_type;

  useEffect(() => {
    if (screenWidth < 992) {
      setShouldCollapse(false);
      setCollapsed(true);
    } else {
      setShouldCollapse(true);
    }
  }, [screenWidth]);

  useEffect(() => {
    dispatch(fetchUnreadNotifications());
  }, []);

  const handleSelectedKey = () => {
    if (location.pathname.includes("/settings/users-manager/create-user")) {
      return "/settings/users-manager";
    }
    if (location.pathname.includes("/settings/users-manager/update-user")) {
      return "/settings/users-manager";
    }

    if (location.pathname.includes("/settings/clients-manager/create-client")) {
      return "/settings/clients-manager";
    }
    if (location.pathname.includes("/settings/clients-manager/update-client")) {
      return "/settings/clients-manager";
    }

    if (location.pathname === "/") {
      let path = current_role !== "client" ? "/profile" : "/client-profile";
      navigate(path);
      return path;
    }

    return location.pathname;
  };

  const handleLogout = () => {
    dispatch(logoutUser({ navigate, id: user.id }));
  };

  return (
    <>
      <NotificationChannel />
      <Notifications
        visible={notificationModalvisible}
        setVisible={setNotificationModalVisible}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={collapsed ? "70px" : `260px`}
        className={`main-sidebar${!collapsed && `-collapsed`} `}
      >
        {shouldCollapse && (
          <span
            onClick={() => setCollapsed(!collapsed)}
            className="sidebar-pin-icon"
          >
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </span>
        )}
        <div
          className="trigger sidebar-child-div"
          onClick={() => shouldCollapse && setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <div
              className="nav-collapsed-img-div"
              style={{
                margin: collapsed ? "10px 0" : "0px 10px",
              }}
              onClick={() => navigate("/")}
            >
              <img src={collapsedIcon} alt="" />
            </div>
          ) : (
            <div className="nav-uncollapsed-img-div">
              <img
                src={digitalExpressLogo}
                alt="ionik Logo"
                style={{
                  margin: collapsed && "0px !important",
                }}
                onClick={() => navigate("/")}
              />
            </div>
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="sidebar-custom"
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[handleSelectedKey()]}
        >
          {menuItemsData
            ?.filter(
              (item) =>
                item.permission.includes(current_role) || item.isAnyManager
            )
            .map((el) => {
              const { key, src, SvgImg, name, link, hasSubItems, subItems } =
                el;

              return hasSubItems ? (
                <SubMenu
                  className="menu-item-for-top-border"
                  key={key}
                  icon={
                    <SvgImg
                      style={{
                        margin: collapsed ? "0px" : "0px 15px 0px 0px",
                      }}
                    />
                  }
                  title={
                    !collapsed && (
                      <span className="settings-title-span">{name}</span>
                    )
                  }
                >
                  {subItems?.map((el) => {
                    const { name, link, hasPermission } = el;
                    return (
                      hasPermission && (
                        <Menu.Item
                          onClick={() => navigate(link)}
                          className="subitems-custom"
                          key={link}
                          style={{
                            padding: collapsed
                              ? `0px !important`
                              : "10px 15px 10px 45px",
                            paddingLeft: collapsed ? "10px" : "45px",
                          }}
                          icon={
                            <DotIcon
                              fill="#ffffff"
                              className="dot-icon"
                              style={{
                                margin: collapsed ? "0px" : "0px 15px 0px 0px",
                              }}
                            />
                          }
                        >
                          {name}
                        </Menu.Item>
                      )
                    );
                  })}
                </SubMenu>
              ) : name === "notification" ? (
                !shouldCollapse && (
                  <Menu.Item
                    className="sidebar-notification-menu-item"
                    key={"/notification"}
                    icon={
                      <Badge count={unreadNotifications || 0} overflowCount={9}>
                        <BellFilled
                          onClick={(e) => {
                            dispatch(fetchAllNotifications());
                            setNotificationModalVisible((prev) => !prev);
                          }}
                        />
                      </Badge>
                    }
                  ></Menu.Item>
                )
              ) : (
                <Menu.Item
                  onClick={() => navigate(link)}
                  key={link}
                  className={
                    link === "/profile" || link === "/client-profile"
                      ? "sidebar-custom-menuitem menu-item-for-top-border"
                      : "menu-item-for-top-border"
                  }
                  icon={
                    link === "/profile" || link === "/client-profile" ? (
                      <div className="sidebar-profile-icon">
                        <Avatar
                          shape={"circle"}
                          style={{
                            margin: collapsed ? "0px" : "0px 15px 0px 0px",
                          }}
                          src={src ? `${baseURL}${src}` : <UserIcon />}
                        />
                      </div>
                    ) : (
                      <Avatar
                        className="profile-avatar"
                        shape={"square"}
                        style={{
                          margin: collapsed ? "0px" : "0px 15px 0px 0px",
                        }}
                        src={
                          SvgImg ? <SvgImg className="profile-svg-icon" /> : src
                        }
                      />
                    )
                  }
                >
                  {link === "/profile" || link === "/client-profile" ? (
                    <div className="profile-menu-div">
                      <div className="profile-username-div">
                        <p>{name}</p>
                        <span>{el.role}</span>
                      </div>
                      {!collapsed && (
                        <div
                          style={{ paddingRight: 12, zIndex: 9999999999999 }}
                        >
                          <Badge
                            count={unreadNotifications || 0}
                            overflowCount={9}
                          >
                            <BellFilled
                              className="notification-icon"
                              onClick={(e) => {
                                setNotificationModalVisible(
                                  !notificationModalvisible
                                );
                                dispatch(fetchAllNotifications());
                                e.stopPropagation();
                              }}
                            />
                          </Badge>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="profile-menu-div">{name}</div>
                  )}
                </Menu.Item>
              );
            })}
          <Menu.Item
            className="sidebar-custom-menuitem menu-item-for-top-border"
            style={{
              cursor: "default",
            }}
            key={"none"}
          ></Menu.Item>
          <Menu.Item
            onClick={() => !logoutLoading && handleLogout()}
            className="sidebar-custom-menuitem menu-item-for-top-border logout-menu-item"
            key={"logout"}
            icon={<LogoutOutlined />}
          >
            Logout {logoutLoading && "..."}
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

export default SidebarComponent;

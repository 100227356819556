import * as constants from "./constants";

export const setClientDetailsIsEdit = (data) => {
  return {
    type: constants.CLIENT_DETAILS_IS_EDIT,
    data: data,
  };
};

export const createClient = (data) => {
  return {
    type: constants.CREATE_CLIENT,
    data: data,
  };
};

export const createClientSuccess = (data) => {
  return {
    type: constants.CREATE_CLIENT_SUCCESS,
    data: data,
  };
};

export const createClientError = (data) => {
  return {
    type: constants.CREATE_CLIENT_ERROR,
  };
};

export const updateClient = (data) => {
  return {
    type: constants.UPDATE_CLIENT,
    data: data,
  };
};

export const updateClientSuccess = (data) => {
  return {
    type: constants.UPDATE_CLIENT_SUCCESS,
    data: data,
  };
};

export const updateClientError = (data) => {
  return {
    type: constants.UPDATE_CLIENT_ERROR,
  };
};

export const fetchClientDetailsUtils = (data) => {
  return {
    type: constants.FETCH_CLIENT_UTIL_DATA,
    data,
  };
};

export const fetchCLientDetailsUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_CLIENT_UTIL_DATA_SUCCESS,
    data,
  };
};

export const fetchAllClients = (data) => {
  return {
    type: constants.FETCH_ALL_CLIENTS,
    data,
  };
};

export const fetchAllClientsSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_CLIENTS_SUCCESS,
    data,
  };
};

export const fetchAllClientsError = (data) => {
  return {
    type: constants.FETCH_ALL_CLIENTS_ERROR,
    data,
  };
};

export const deleteClient = (data) => {
  return {
    type: constants.DELETE_CLIENT,
    data,
  };
};

export const deleteClientSuccess = (data) => {
  return {
    type: constants.DELETE_CLIENT_SUCCESS,
    data,
  };
};

export const deleteClientError = (data) => {
  return {
    type: constants.DELETE_CLIENT_ERROR,
    data,
  };
};

export const fetchClient = (data) => {
  return {
    type: constants.FETCH_CLIENT,
    data: data,
  };
};

export const fetchClientSuccess = (data) => {
  return {
    type: constants.FETCH_CLIENT_SUCCESS,
    data,
  };
};

export const fetchClientError = (data) => {
  return {
    type: constants.FETCH_CLIENT_ERROR,
  };
};

export const sendDetailsToClient = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_CLIENT,
    data: data,
  };
};

export const sendDetailsToClientSuccess = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_CLIENT_SUCCESS,
  };
};

export const sendDetailsToClientError = (data) => {
  return {
    type: constants.SEND_DETAILS_TO_CLIENT_ERROR,
  };
};

export const updateClientSettings = (data) => {
  return {
    type: constants.UPDATE_CLIENT_SETTINGS,
    data,
  };
};

export const updateClientSettingsSuccess = (data) => {
  return {
    type: constants.UPDATE_CLIENT_SETTINGS_SUCCESS,
    data,
  };
};

export const updateClientSettingsError = (data) => {
  return {
    type: constants.UPDATE_CLIENT_SETTINGS_ERROR,
    data,
  };
};

export const clientCleanupFunc = (data) => {
  return {
    type: constants.CLIENT_CLEANUP_FUNC,
    data,
  };
};

import { Col, Row } from "antd";
import "./index.less";
import LogInForm from "./loginForm";

const UserLogIn = () => {
  return (
    <div className="login-most-parent-div">
      <Row justify="center" align="middle" className="parent-row">
        <Col>
          <LogInForm />
        </Col>
      </Row>
    </div>
  );
};

export default UserLogIn;

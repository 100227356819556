import * as constants from "./constant";

const INITIAL_STATE = {
  allNotifications: [],
  unreadNotifications: 0,
  notificationLoading: false,
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.NEW_NOTIFICATION_ADDED:
      let updatedList = [action.data, ...state.allNotifications];
      return {
        ...state,
        allNotifications: updatedList,
        unreadNotifications: state.unreadNotifications + 1,
      };
    case constants.NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationLoading: action.payload,
      };
    case constants.FETCH_NOTIFICATIONS:
      return {
        ...state,
        // unreadNotifications: action.payload,
      };
    case constants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        // unreadNotifications: action.data,
      };
    case constants.FETCH_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationLoading: true,
      };
    case constants.FETCH_NOTIFICATIONS_LIST_SUCCESS:
      const { notifications, count } = action.data;
      return {
        ...state,
        allNotifications: notifications,
        unreadNotifications: count,
        notificationLoading: false,
      };
    case constants.UPDATE_NOTIFICATION:
      return {
        ...state,
        // allNotifications: action.payload,
        // unreadNotifications: 0,
      };
    case constants.UPDATE_NOTIFICATION_SUCCESS:
      const updated = state.allNotifications.map((el) =>
        el.id === action.data?.id ? action.data : el
      );

      return {
        ...state,
        allNotifications: updated,
        unreadNotifications:
          state?.unreadNotifications > 0 ? state.unreadNotifications - 1 : 0,
        notificationLoading: false,
      };
    case constants.NOTIFICATION_HAS_READ:
      return {
        ...state,
        unreadNotifications:
          state?.unreadNotifications > 0 ? state.unreadNotifications - 1 : 0,
      };
    default:
      return state;
  }
};

export default notificationsReducer;

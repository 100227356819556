import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./calendar.less";

const { RangePicker } = DatePicker;

const CustomCalendar = ({ date, format, handleDateInput, setDate }) => {
  return (
    <RangePicker
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      size={"small"}
      placeholder={["", ""]} // ["From", "To"]
      onChange={(e) => handleDateInput(e)}
      value={date}
      format={format}
      locale={{
        dateFormat: format,
      }}
      onCalendarChange={(e) => {
        if (e !== null && e[0] === null && e[1] !== null) {
          setDate([moment(new Date()), e[1]]);
        }
      }}
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      suffixIcon={null}
      separator={<span>-</span>}
    />
  );
};

export default CustomCalendar;

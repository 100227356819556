import * as constants from "./constants";
import { sortActivityTypes } from "../../Activities/utils";

const initialState = {
  allActivityTypes: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const activityTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_ACTIVITY_TYPE:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_ACTIVITY_TYPE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allActivityTypes: [action.data, ...state.allActivityTypes],
        isAdding: false,
      };
    case constants.CREATE_ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.FETCH_ACTIVITY_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_ACTIVITY_TYPES_SUCCESS:
      const { activities, total_activities } = action.data;
      return {
        ...state,
        fetchingLoading: false,
        allActivityTypes: sortActivityTypes(activities),
        totalRecords: total_activities,
        isAdding: false,
      };
    case constants.FETCH_ACTIVITY_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_ACTIVITY_TYPE:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_ACTIVITY_TYPE_SUCCESS:
      const updated = state.allActivityTypes.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allActivityTypes: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_ACTIVITY_TYPE:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_ACTIVITY_TYPE_SUCCESS:
      const updatedData = state.allActivityTypes.filter(
        (el) => el.id !== action.data.id
      );
      return {
        ...state,
        allActivityTypes: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default activityTypesReducer;

import { Row, Col, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.less";
import { ReactComponent as BackIcon } from "../../../assets/back-icon.svg";
import ClientDetailsSection from "./clientDetailsSection";
import SettingsSection from "./settingsSection";
import { useDispatch, useSelector } from "react-redux";
import { clientCleanupFunc, fetchClient } from "../redux/actions";
import UseGetUserRole from "../../../components/useGetUserRole";

const ClientDetails = () => {
  const [isClientUpdate, setIsClientUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isProfileCase, setIsProfileCase] = useState();

  const { currentClientObj, fetchClientDetailLoading } = useSelector(
    (state) => state.clientManagerReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const [isSuperAdmin, isAdmin, , , isCleint] = UseGetUserRole();

  useEffect(() => {
    setIsProfileCase(pathname === "/client-profile");
  }, [pathname]);

  useEffect(() => {
    if (currentClientObj?.id) {
      setIsClientUpdate(true);
      setIsProfileCase(currentClientObj.id === user.id);
    }
  }, [currentClientObj]);

  useEffect(() => {
    if (params?.id) {
      setIsClientUpdate(true);
      dispatch(fetchClient(params?.id));
    } else if (pathname === "/client-profile") {
      setIsClientUpdate(true);
      dispatch(fetchClient(user.id));
    }

    return () => {
      dispatch(clientCleanupFunc());
    };
  }, [params.id]);

  return (
    <div className="page-top-div heading-parent-div">
      <Row justify="space-between">
        <Col>
          <span className="text-span">
            {isProfileCase
              ? `CLient - ${user?.username}`
              : isClientUpdate
              ? "Update CLient"
              : "Add CLient"}
          </span>
        </Col>
        <Col>
          {(isSuperAdmin || isAdmin) && !isCleint && (
            <Button
              onClick={() => navigate("/settings/clients-manager")}
              icon={<BackIcon width={24} height={14} />}
              className="back-btn"
              size="middle"
            >
              BACK
            </Button>
          )}
        </Col>
      </Row>
      <Spin spinning={fetchClientDetailLoading}>
        <div className="user-manager-parent-div">
          <ClientDetailsSection
            isClientUpdate={isClientUpdate}
            isProfileCase={isProfileCase}
          />
          {isClientUpdate && (
            <SettingsSection isClientUpdate={isClientUpdate} />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ClientDetails;

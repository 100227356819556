import { combineReducers } from "redux";
import userManagerReducer from "../modules/UserManager/redux/userManagerReducer";
import departEmailsReducer from "../modules/DepartmentEmails/redux/departEmailsReducer";
import activityTypesReducer from "../modules/ActivityTypes/redux/actitvityTypesReducer";
import clientManagerReducer from "../modules/ClientsManager/redux/clientManagerReducer";
import salesReducer from "../modules/Sales/redux/salesReducer";
import salesGraphReducer from "../modules/Sales/redux/graphReducer";
import activitiesReducer from "../modules/Activities/redux/actitvitiesReducer";
import authReducer from "../modules/login/redux/authReducer";
import productionReducer from "../modules/Production/redux/productionReducer";
import secondLevelReducer from "../modules/Production/redux/secondLevelReducer";
import thirdLevelReducer from "../modules/Production/redux/thirdLevelReducer";
import fourthLevelReducer from "../modules/Production/redux/fourthLevelReducer";
import salesSecondLevelReducer from "../modules/Sales/redux/salesSecondLevelReducer";
import chatReducer from "../modules/Production/redux/chatReducer";
import notificationsReducer from "../modules/notifications/notificationsRedux/notificationReducer";

export default combineReducers({
  userManagerReducer,
  departEmailsReducer,
  activityTypesReducer,
  clientManagerReducer,
  salesReducer,
  activitiesReducer,
  authReducer,
  productionReducer,
  secondLevelReducer,
  thirdLevelReducer,
  fourthLevelReducer,
  salesGraphReducer,
  salesSecondLevelReducer,
  chatReducer,
  notificationsReducer,
});

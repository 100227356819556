import { useSelector } from "react-redux";

const UseClientPermission = () => {
  const { user } = useSelector((state) => state.authReducer);

  const { permissions } = user;

  let first = permissions?.level1 ? permissions?.level1 : false;
  let second = permissions?.level2 ? permissions?.level2 : false;
  let third = permissions?.level3 ? permissions?.level3 : false;

  return user?.user_type === "client"
    ? [first, second, third]
    : [false, false, false];
};

export default UseClientPermission;

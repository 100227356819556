import * as constants from "./constants";

export const fetchDepartEmailData = (data) => {
  return {
    type: constants.FETCH_DEPART_EMAILS,
    data,
  };
};

export const fetchDepartEmailSuccess = (data) => {
  return {
    type: constants.FETCH_DEPART_EMAILS_SUCCESS,
    data: data,
  };
};

export const updateDepartEmail = (data) => {
  return {
    type: constants.UPDATE_DEPART_EMAIL,
    data: data,
  };
};

export const updateDepartEmailSuccess = (data) => {
  return {
    type: constants.UPDATE_DEPART_EMAIL_SUCCESS,
    data: data,
  };
};

export const updateDepartEmailError = () => {
  return {
    type: constants.UPDATE_DEPART_EMAIL_ERROR,
  };
};

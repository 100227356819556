import React, { useEffect, useState } from "react";
import { Row, Col, Button, Popover } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as MessageIcon } from "../../assets/message-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { firstLevelAddComment } from "./redux/actions";
import UseGetUserRole from "../../components/useGetUserRole";

const FirstLevelAddComment = ({
  record,
  setCurrentObj,
  currentObj,
  isVisibleAddComment,
  setIsVisibleAddComment,
}) => {
  const [feedback, setFeedback] = useState({});
  const [text, setText] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const dispatch = useDispatch();
  const { commentLoading } = useSelector((state) => state.productionReducer);
  const [isSuperAdmin, isAdmin, , isSalesUser, isCleint] = UseGetUserRole();
  const { user } = useSelector((state) => state.authReducer);

  const { id } = record;
  let key = `feedback_${id}`;

  useEffect(() => {
    if (record?.fetch_comment !== null) {
      setText(record.fetch_comment?.body);
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, []);

  const handleSuccess = () => {
    // handleClose();
    // setFeedback({ ...feedback, [key]: "" });
    // setText("");
    setReadOnly(true);
  };

  const handleSave = () => {
    if (text?.length > 0) {
      //   setFeedback(text);
      //   handleClose();
      console.log("text");
    }

    const { soid } = record;
    const body = feedback[`feedback_${id}`];

    dispatch(firstLevelAddComment({ soid, body, handleSuccess }));
  };

  const handleFeedBack = (text) => {
    setText(text);
    setFeedback({ ...feedback, [key]: text });
  };

  const handleClose = () => {
    setIsVisibleAddComment(false);
  };

  const isAllow = () => {
    let salesUserCond =
      isSalesUser && record?.cpy_staff_id == user?.quick_easy_id;
    // return isSuperAdmin || isAdmin || salesUserCond;
    return salesUserCond;
  };

  return (
    <Popover
      overlayClassName="add-comment-popover"
      placement="top"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      content={
        <Row justify="center" gutter={[0, 10]}>
          <Col span={24}>
            <TextArea
              readOnly={readOnly || isCleint || !isAllow()}
              rows={3}
              value={text}
              onChange={(e) => {
                handleFeedBack(e.target.value);
              }}
              className={`${text?.length > 0 ? "valid-text" : "invalid-text"}`}
            />
          </Col>
          {isAllow() && (
            <Col>
              {readOnly ? (
                <Button
                  loading={commentLoading}
                  disabled={text?.length < 1}
                  onClick={() => setReadOnly(false)}
                  className={`${
                    text?.length > 0 ? "valid" : "invalid"
                  }-text-btn`}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  loading={commentLoading}
                  disabled={text?.length < 1}
                  onClick={handleSave}
                  className={`${
                    text?.length > 0 ? "valid" : "invalid"
                  }-text-btn`}
                >
                  Save
                </Button>
              )}
            </Col>
          )}
        </Row>
      }
      title={
        <Row justify="space-between">
          {/* <Col className="title">{!readOnly ? "ADD" : ""} COMMENT</Col> */}
          <Col className="title">
            {isAllow() && text?.length > 0 ? "ADD" : ""} COMMENT
          </Col>
          <Col>
            <CloseOutlined onClick={handleClose} />
          </Col>
        </Row>
      }
      trigger="click"
      visible={
        isVisibleAddComment
        //  && id === currentObj.id
      }
      onVisibleChange={(e) => {
        setIsVisibleAddComment(e);
        setCurrentObj(record);
      }}
    >
      <MessageIcon className="custom-icon" />
    </Popover>
  );
};

export default FirstLevelAddComment;

import React, { useEffect } from "react";
import "./App.less";
import { Routes, Route, useNavigate } from "react-router-dom";
import LayoutComponent from "./modules/container";
import Login from "./modules/login";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser } from "./modules/login/redux/actions";
import ForgotPassword from "./modules/forgotPassword";
import ResetPassword from "./modules/forgotPassword/ResetPassword";

const App = () => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const localStorageUser = JSON.parse(localStorage.getItem("user"));
    if (localStorageUser) {
      axios.defaults.headers.common["Authorization"] =
        localStorageUser.auth_token;
      dispatch(setUser(localStorageUser));
    } //  else navigate("/");
  }, []);

  return (
    <>
      <Routes>
        {user === null && (
          <>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </>
        )}
        {user !== null && <Route path="*" element={<LayoutComponent />} />}
      </Routes>
    </>
  );
};

export default App;

import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { convertStrToMinutes } from "../../../../globalUtils/convertStrToMinutes";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchActivities({ data }) {
  const { search, ...rest } = data;

  if (search["time_spent"]) {
    // search["time_spent"] = String(convertStrToMinutes(search["time_spent"]));
    search["time_spent"] = String(search["time_spent"].replace("m", ""));
  }

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/sales_activities`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    });
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchActivitiesSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchActivitiesUtilsData({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/sales_activities/search_lists`
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchActivitiesSearchUtilsSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateActivity({ data }) {
  const { id, debouncedDefaultPageOneCallApi, ...res } = data;

  const fd = new FormData();
  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined) {
      Array.from(value).forEach((image) => {
        const { isLocal } = image;
        if (isLocal) {
          fd.append(`images[]`, image);
        }
        // else fd.append("image_ids[]", key);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/sales_activities/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateActivitySuccess(response.data.data));
      debouncedDefaultPageOneCallApi();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateActivityError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateActivityError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteActivity({ data }) {
  const { id, onDeleteSuccess } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/sales_activities/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteActivitySuccess(response.data.data));
      onDeleteSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteActivityError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteActivityError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleCreateActivities({ data }) {
  const fd = new FormData();

  const { debouncedDefaultPageOneCallApi, ...res } = data;

  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined) {
      Array.from(value).forEach((image) => {
        const { key } = image;
        if (image?.isCopy) {
          fd.append("image_ids[]", key);
        } else fd.append(`images[]`, image);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/sales_activities`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createActivitiesSuccess(response.data.data));
      debouncedDefaultPageOneCallApi();
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createActivitiesError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.createActivitiesError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleDeleteActivityAttach({ data }) {
  const { key, recordId } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v1/images/${key}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.deleteActivityAttachSuccess({
          recordId,
          imgItem: response.data.data,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteActivityAttachError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteActivityAttachError(response.data.data));
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleAddActivityAttach({ data }) {
  const { setCurrentUpdateObj } = data;

  const { id, images } = data.data;

  const fd = new FormData();
  Array.from(images).forEach((image) => {
    const { isLocal } = image;
    if (isLocal) {
      fd.append(`images[]`, image);
    }
  });

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/sales_activities/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateActivitySuccess(response.data.data));
      setCurrentUpdateObj(response.data.data);
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateActivityError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateActivityError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

import { useSelector } from "react-redux";

const UseIsManagerOfAnyDepart = () => {
  const { user } = useSelector((state) => state.authReducer);

  let isManagerArray = user?.departments_with_steps?.findIndex(
    (el) => el.position.toLowerCase() === "manager"
  );

  return isManagerArray !== -1 ? true : false;
};

export default UseIsManagerOfAnyDepart;

import moment from "moment";

const convertStrToMinutes = (str) => {
  let splitStr = str.split(" ");
  let hoursMin = splitStr[0].slice(0, -1);
  let minutes = splitStr[1].slice(0, -1);
  return Number(hoursMin) * 60 + Number(minutes);
};

const convertStrToMinutesIncludingSeconds = (str) => {
  let splitStr = str.split(" ");
  let hoursMin = splitStr[0].slice(0, -1);
  let minutes = splitStr[1].slice(0, -1);
  let seconds = splitStr[2].slice(0, -1);

  let res =
    Number(hoursMin) * 60 +
    Number(minutes) +
    Number(Number(seconds / 60).toFixed(2));
  return Number(res);
};

const convertMinutesToStr = (total_time) => {
  return `${Math.trunc(total_time / 60)}h ${total_time % 60}m`;
};

const convertMinutesToStrIncludingSeconds = (total_time) => {
  let hours = Math.trunc(total_time / 60);
  let minutes = Math.trunc(total_time % 60);
  let seconds = Math.round((total_time % 1) * 60);
  let resStr = `${hours}h ${minutes}m ${seconds}s`;
  return resStr;
};

const thousandsSeparators = (num) => {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

function priceFormatTwoDecimal(num) {
  return Number(parseFloat(num).toFixed(2))
    .toLocaleString("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .replace(/,/g, " ");
}

const getDiff = (start_time, stop_time) => {
  const duration = moment.duration(moment(stop_time).diff(moment(start_time)));
  let str = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  return str;
};

const getTotalTime = (arr) => {
  let onlyCompleted = arr.filter((el) => el.stop_time !== null);

  let res = convertMinutesToStrIncludingSeconds(
    onlyCompleted.reduce(
      (acc, obj) =>
        acc +
        convertStrToMinutesIncludingSeconds(
          getDiff(obj.start_time, obj.stop_time)
        ),
      0
    )
  );
  return res;
};

export {
  convertStrToMinutes,
  convertMinutesToStr,
  thousandsSeparators,
  priceFormatTwoDecimal,
  getTotalTime,
};

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.less";
import { fetchSalesUtilsData, salesCleanupFunc } from "./redux/actions";
import SalesManager from "./salesRegister";
import SalesReporting from "./SalesReporting";

const Sales = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchSalesGraphData({ search: {} }));
    dispatch(fetchSalesUtilsData({}));

    return () => {
      dispatch(salesCleanupFunc());
    };
  }, []);

  return (
    <div className="page-top-div">
      <span className="text-span">Sales</span>
      <SalesReporting />
      <SalesManager />
    </div>
  );
};

export default Sales;

import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useSelector } from "react-redux";

const keys = ["total_hours"];
const colors = ["#EB078E"];

const ValueOutside = ({ bars, yScale }) => {
  return bars.map((bar) => {
    const {
      key,
      width,
      x,
      data: { value },
    } = bar;
    const labelMargin = 20;

    return (
      <g
        key={key}
        transform={`translate(${x}, ${yScale(value) - labelMargin})`}
        fill="#757575"
      >
        <text
          fontSize="11px"
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {/* {value.toFixed(2)} */}
          {value && value > 0
            ? `${Math.trunc(value)}h ${Math.trunc((value % 1) * 60)}m`
            : null}
        </text>
      </g>
    );
  });
};

const CustomLeftLabel = (data) => {
  const { x, y, value } = data;

  return (
    <g>
      <text x={-15} y={y + 5} fontSize="12px" fill="#757575">
        {value}
      </text>
    </g>
  );
};

const TotalHours = () => {
  const [data, setData] = useState([]);

  const { total_hours } = useSelector((state) => state.activitiesReducer);

  useEffect(() => {
    setData([
      {
        id: 1,
        total_hours: total_hours / 60,
        label: "",
      },
    ]);
  }, [total_hours]);

  return (
    <div style={{ height: "400px", marginTop: "10px" }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="label"
        margin={{
          top: 30,
          right: 0,
          bottom: 110,
          left: 40,
        }}
        colors={colors}
        padding={0.5}
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={false}
        enableGridY={true}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: null,
              },
            },
            domain: {
              line: {
                stroke: "", // verticle line
                strokeWidth: 2,
              },
            },
            legend: {
              text: {
                fontWeight: "bold",
                letterSpacing: "2px",
              },
            },
          },
          grid: {
            line: {
              stroke: `rgb(231 232 236 / 80%)`,
              strokeWidth: 2,
              strokeDasharray: "2, 4",
            },
          },
        }}
        layers={["grid", "axes", "bars", "markers", ValueOutside]}
        legends={[
          {
            dataFrom: "keys",
            data: keys.map((id, index) => ({
              color: colors[index],
              id,
              label: id === "ordered" ? 1 : 2,
            })),
            anchor: "bottom",
            direction: "row",
            translateY: 63,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: "left-to-right",
            symbolSize: 20,
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -40,
          renderTick: CustomLeftLabel,
          tickValues: 5,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          legend: "Total Hours",
          legendPosition: "middle",
          legendOffset: 30,
          // renderTick: CustomBottomLabel,
        }}
      />
    </div>
  );
};

export default TotalHours;

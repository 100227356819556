import { useState, useEffect } from "react";
import { List, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./index.less";
import moment from "moment";
import {
  fetchAllNotifications,
  updateNotification,
} from "./notificationsRedux/notificationActions";

const Notifications = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const { notificationLoading, allNotifications } = useSelector(
    (state) => state.notificationsReducer
  );

  useEffect(() => {
    dispatch(fetchAllNotifications());
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const linkHandler = ({ is_read, id }) => {
    // setVisible(!visible);
    if (!is_read) {
      dispatch(updateNotification({ id }));
    }
  };

  return (
    <>
      <Modal
        className="notification-modal"
        title={<div>Notifications</div>}
        visible={visible}
        width={206} // 260
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <List
          // style={{ backgroundColor: "#fff", width: "206px" }}
          size="small"
          // header={<div>All Notifications</div>}
          footer={<></>}
          loading={notificationLoading}
          bordered
          dataSource={allNotifications}
          className="notifications-list"
          renderItem={(item) => {
            const { body, is_read, created_at } = item;
            return (
              // <Link to={link} onClick={() => linkHandler(item)}>
              <List.Item
                onClick={() => linkHandler(item)}
                className={`${!is_read && "unread-listitem"}`}
              >
                <div className="time-div">
                  {moment(created_at).format("HH:MM")}
                </div>
                <div className={`message-body ${!is_read && "unread-message"}`}>
                  {body}
                </div>
              </List.Item>
              // </Link>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default Notifications;

import React from "react";
import "./index.less";
import ActivityTable from "./activityTypesTable";

const ActivityTypes = () => {
  return (
    <div className="page-top-div">
      <span className="text-span">Activity types</span>
      <ActivityTable />
    </div>
  );
};

export default ActivityTypes;

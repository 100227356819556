import * as constants from "./constant";

export const fetchAllNotifications = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_LIST,
    data,
  };
};

export const fetchAllNotificationsSuccess = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_LIST_SUCCESS,
    data,
  };
};

export const fetchAllNotificationsError = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_LIST_ERROR,
    data,
  };
};

export const fetchUnreadNotifications = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS,
    data,
  };
};

export const fetchUnreadNotificationsSuccess = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_SUCCESS,
    data,
  };
};

export const fetchUnreadNotificationsError = (data) => {
  return {
    type: constants.FETCH_NOTIFICATIONS_ERROR,
    data,
  };
};

export const updateNotification = (data) => {
  return {
    type: constants.UPDATE_NOTIFICATION,
    data,
  };
};

export const updateNotificationSuccess = (data) => {
  return {
    type: constants.UPDATE_NOTIFICATION_SUCCESS,
    data,
  };
};

export const updateNotificationError = (data) => {
  return {
    type: constants.UPDATE_NOTIFICATION_ERROR,
    data,
  };
};

export const newNotificationAdded = (data) => {
  return {
    type: constants.NEW_NOTIFICATION_ADDED,
    data,
  };
};

// export const fetchUnreadNotifications = () => async (dispatch) => {
//   dispatch({
//     type: NOTIFICATIONS_LOADING,
//     payload: true,
//   });
//   let token = JSON.parse(localStorage.getItem("token"));
//   try {
//     await axios
//       .get(baseURL + `api/v1/users/fetch_unread_notifications`, {
//         headers: {
//           Authorization: token,
//         },
//       })
//       .then((response) => {
//         errorHandler(response);
//         console.log(`response.data`, response.data);
//         const {
//           data: { total_unread_notifications, success },
//         } = response;
//         if (success) {
//           dispatch({
//             type: FETCH_NOTIFICATIONS,
//             payload: total_unread_notifications,
//           });
//           dispatch({
//             type: NOTIFICATIONS_LOADING,
//             payload: false,
//           });
//         } else if (!success) {
//           dispatch({
//             type: NOTIFICATIONS_LOADING,
//             payload: false,
//           });
//         }
//         // setLoading(false);
//       });
//     // setLoading(false);
//   } catch (error) {
//     console.log(`error`, error);
//     dispatch({
//       type: NOTIFICATIONS_LOADING,
//       payload: false,
//     });
//   }
// };

// export const fetchNotificationsList = () => async (dispatch) => {
//   dispatch({
//     type: NOTIFICATIONS_LOADING,
//     payload: true,
//   });
//   let token = JSON.parse(localStorage.getItem("token"));
//   try {
//     await axios
//       .get(baseURL + `api/v1/users/fetch_user_notifications`, {
//         headers: {
//           Authorization: token,
//         },
//       })
//       .then((response) => {
//         errorHandler(response);
//         const {
//           data: { data, total_unread_notifications, success },
//         } = response;
//         if (success) {
//           dispatch({
//             type: FETCH_NOTIFICATIONS_LIST,
//             payload: data,
//           });
//           dispatch({
//             type: FETCH_NOTIFICATIONS,
//             payload: total_unread_notifications,
//           });
//           dispatch({
//             type: NOTIFICATIONS_LOADING,
//             payload: false,
//           });
//         } else if (!success) {
//           dispatch({
//             type: NOTIFICATIONS_LOADING,
//             payload: false,
//           });
//         }
//         // setLoading(false);
//       });
//     // setLoading(false);
//   } catch (error) {
//     console.log(`error`, error);
//     dispatch({
//       type: NOTIFICATIONS_LOADING,
//       payload: false,
//     });
//   }
// };

// export const updateNotification = (id) => async (dispatch) => {
//   let token = JSON.parse(localStorage.getItem("token"));
//   try {
//     const response = await axios.get(
//       baseURL + `api/v1/users/update_notification_status?id=${id}`,
//       {
//         headers: {
//           Authorization: token,
//         },
//       }
//     );

//     const { success } = response.data;
//     if (success) {
//       dispatch({
//         type: NOTIFICATION_HAS_READ,
//       });
//     }
//   } catch (error) {
//     console.log(`error`, error);
//   }
// };

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Settings } from "../../../assets/settings.svg";
import { ReactComponent as Activities } from "../../../assets/activities.svg";
import { ReactComponent as Production } from "../../../assets/production.svg";
import { ReactComponent as Sales } from "../../../assets/sales.svg";
// import { capitalizeFirstLetterofEachWord } from "../../../globalUtils/capitalizeFirstLetterofEachWord";
import UseIsManagerOfAnyDepart from "../../../components/useIsManagerOfAnyDepart";
import UseGetUserRole from "../../../components/useGetUserRole";

const useSidebar = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [data, setData] = useState();
  const isManagerOfAnyDepartment = UseIsManagerOfAnyDepart();
  const [isSuperAdmin] = UseGetUserRole();

  useEffect(() => {
    setData(user);
  }, [user]);

  const hanldeRoleLength = (role) =>
    role.length > 20 ? `${role.substring(0, 18) + " ..."}` : role;

  const hanldeNameLength = (role) =>
    role.length > 15 ? role.substring(0, 15) : role;

  const menuItemsData = [
    {
      key: "1",
      src: data?.profile_picture?.length > 1 ? data?.profile_picture : null,
      SvgImg: false,
      alt: "",
      name: hanldeNameLength(data?.username || ""),
      role: hanldeRoleLength(data?.job_title || ""),
      link: "/profile",
      hasSubItems: false,
      permission: ["super_admin", "admin", "production_user", "sales_user"],
    },
    {
      key: "13",
      src: data?.profile_picture?.length > 1 ? data?.profile_picture : null,
      SvgImg: false,
      alt: "",
      name: hanldeNameLength(data?.username || ""),
      role: hanldeRoleLength(data?.company_name || ""),
      link: "/client-profile",
      hasSubItems: false,
      permission: ["client"],
    },
    {
      key: "2",
      SvgImg: Sales,
      alt: "Sales Icon",
      name: "Sales",
      link: "/sales",
      hasSubItems: false,
      permission: ["super_admin", "admin", "sales_user"],
      // isAnyManager: isManagerOfAnyDepartment,
    },
    {
      key: "3",
      SvgImg: Activities,
      alt: "Activities",
      name: "Activities",
      link: "/activities",
      hasSubItems: false,
      permission: ["super_admin", "admin", "sales_user"],
      // isAnyManager: isManagerOfAnyDepartment,
    },
    {
      key: "4",
      SvgImg: Production,
      alt: "Production",
      name: "Production",
      link: "/production",
      hasSubItems: false,
      permission: ["super_admin", "admin", "production_user", "sales_user"],
      isAnyManager: isManagerOfAnyDepartment,
    },
    {
      key: "6",
      SvgImg: Settings,
      alt: "settings",
      name: "Settings",
      link: "/settings",
      hasSubItems: true,
      permission: ["super_admin", "admin"],
      subItems: [
        {
          key: "7",
          SvgImg: Settings,
          alt: "Users Manager",
          name: "Users Manager",
          link: "/settings/users-manager",
          hasSubItems: false,
          hasPermission: isSuperAdmin || user?.permissions?.user_manager,
        },
        {
          key: "8",
          SvgImg: Settings,
          alt: "Clients Manager",
          name: "Clients Manager",
          link: "/settings/clients-manager",
          hasSubItems: false,
          hasPermission: isSuperAdmin || user?.permissions?.client_manager,
        },
        // {
        //   key: "8",
        //   SvgImg: Settings,
        //   alt: "User Types",
        //   name: "User Types",
        //   link: "/settings/user-types",
        //   hasSubItems: false,
        // },
        {
          key: "9",
          SvgImg: Settings,
          alt: "Activity Types",
          name: "Activity Types",
          link: "/settings/activity-types",
          hasSubItems: false,
          hasPermission: isSuperAdmin || user?.permissions?.activity_type,
        },
        // {
        //   key: "10",
        //   SvgImg: Settings,
        //   alt: "Activity Statuses",
        //   name: "Activity Statuses",
        //   link: "/settings/activity-statuses",
        //   hasSubItems: false,
        // },
        // {
        //   key: "11",
        //   SvgImg: Settings,
        //   alt: "Production Statuses",
        //   name: "Production Statuses",
        //   link: "/settings/production-statuses",
        //   hasSubItems: false,
        // },
        {
          key: "12",
          SvgImg: Settings,
          alt: "Department Emails",
          name: "Department Emails",
          link: "/settings/department-emails",
          hasSubItems: false,
          hasPermission: isSuperAdmin || user?.permissions?.department_emails,
        },
      ],
    },
  ];

  return [menuItemsData];
};

export default useSidebar;

import { useSelector } from "react-redux";
import UseGetUserRole from "./useGetUserRole";

const UseCanAddOperator = () => {
  const { user } = useSelector((state) => state.authReducer);
  const { processing_step_name } = useSelector(
    (state) => state.fourthLevelReducer
  );

  const [isSuperAdmin, isAdmin, isProdUser, isSalesUser, isCleint] =
    UseGetUserRole();

  if (isSuperAdmin || isAdmin) {
    return true;
  }

  if (isProdUser) {
    let filteredDep = user.departments_with_steps.filter(
      (el) =>
        el.name.includes(processing_step_name) && el.position === "manager"
    );

    return filteredDep.length > 0 ? true : false;
  }

  if (isCleint || isSalesUser) {
    return false;
  }
};

export default UseCanAddOperator;

const FETCH_DEPART_EMAILS = "FETCH_DEPART_EMAILS";
const FETCH_DEPART_EMAILS_SUCCESS = "FETCH_DEPART_EMAILS_SUCCESS";
const FETCH_DEPART_EMAILS_ERROR = "FETCH_DEPART_EMAILS_ERROR";
const UPDATE_DEPART_EMAIL = "UPDATE_DEPART_EMAIL";
const UPDATE_DEPART_EMAIL_SUCCESS = "UPDATE_DEPART_EMAIL_SUCCESS";
const UPDATE_DEPART_EMAIL_ERROR = "UPDATE_DEPART_EMAIL_ERROR";

export {
  FETCH_DEPART_EMAILS,
  FETCH_DEPART_EMAILS_SUCCESS,
  FETCH_DEPART_EMAILS_ERROR,
  UPDATE_DEPART_EMAIL,
  UPDATE_DEPART_EMAIL_SUCCESS,
  UPDATE_DEPART_EMAIL_ERROR,
};

import React from "react";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import UsersRegisterTable from "./usersRegisterTable";
import { setUserDetailsIsEdit } from "./redux/actions";
import { useDispatch } from "react-redux";
import "./index.less";

const UserManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="page-top-div">
      <span className="text-span">users Manager</span>
      <Row className="production-manager-parent-row">
        <Col span={24} className="heading-col">
          <Row align="middle" gutter={[30,0]}>
            <Col  xl={5} xxl={3}>
              <div>
                <span className="heading-span">Users Register</span>
              </div>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  navigate("/settings/users-manager/create-user");
                  dispatch(setUserDetailsIsEdit(true));
                }}
                icon={<PlusOutlined />}
                className="add-user-btn"
                size="middle"
              >
                User
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <UsersRegisterTable />
        </Col>
      </Row>
    </div>
  );
};

export default UserManager;

import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Space, DatePicker, Popover } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import CustomCalendar from "../../components/calendar";
import { ReactComponent as AttachIcon } from "../../assets/attach.svg";
import { ReactComponent as ChatIcon } from "../../assets/chat-icon.svg";
import { ReactComponent as MessageIcon } from "../../assets/message-icon.svg";
// import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import ChatPopup from "./chatPopupComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPreviousChat,
  fetchProdFirstLevel,
  fetchProdGraphData,
  fetchProdSecondLevel,
  fetchProdSecondLevelSuccess,
} from "./redux/actions";
import _ from "lodash";
import CustomPagination from "../../components/customPagination/CustomPagination";
import SecondLevelComponent from "./secondLevel";
import AMultiSelect from "../../components/AMultiSelect";
import { getOptions } from "../Sales/utils";
import UseGetUserRole from "../../components/useGetUserRole";
import { isValidNumber } from "../../globalUtils/axiosConfig";
import UseClientPermission from "../../components/useClientPermissions";
import AddCommentPopup from "./firstLevelAddComment";
import AttachmentsPopup from "./attachmentsPopup";

const ProductionManagerTable = () => {
  const [sortedInfo, setSortedInfo] = useState(null);
  // const [expandedSortedInfo, setExpandedSortedInfo] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [salesRepCheckedList, setSalesRepCheckedList] = useState([]);
  const [clientCheckedList, setClientCheckedList] = useState([]);
  const [soNumCheckedList, setSoNumCheckedList] = useState([]);
  const [formatCheckedList, setFormatCheckedList] = useState([]);
  const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [, , , , isCleint] = UseGetUserRole();
  const [date, setDate] = useState(null);
  const [chatPopupVisible, setChatPopupVisible] = useState(false);
  const [currentPopupRecordId, setCurrentPopupRecordId] = useState();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const dispatch = useDispatch();
  const [, isSecondLevel] = UseClientPermission();
  const [currentObj, setCurrentObj] = useState({});
  const [isVisibleAddComment, setIsVisibleAddComment] = useState(false);
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [currentImgUpdateObj, setCurrentImgUpdateObj] = useState({});
  const [firstTimeFetching, setfirstTimeFetching] = useState(true);
  const [contactPersonCheckedList, setContactPersonCheckedList] = useState([]);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);

  const firstLevelData = useSelector(
    (state) => state.productionReducer.firstLevelData
  );

  const { fetchLoading, searchUtilsLoading } = useSelector(
    (state) => state.productionReducer
  );

  const totalRecords = useSelector(
    (state) => state.productionReducer.totalRecords
  );

  const {
    clientOptions,
    salesRepsOptions,
    soNumbersOptions,
    formatOptions,
    statusOptions,
    contactPersonsOptions,
  } = useSelector((state) => state.productionReducer);

  useEffect(() => {
    if (
      page &&
      resultPerPage &&
      page > 0 &&
      resultPerPage > 0 &&
      !firstTimeFetching
    ) {
      dispatch(
        fetchProdFirstLevel({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
    }
    setfirstTimeFetching(false);
  }, [req]);

  // useEffect(() => {
  //   if (
  //     page &&
  //     resultPerPage &&
  //     page > 0 &&
  //     resultPerPage > 0 &&
  //     !firstTimeFetching
  //   ) {
  //     dispatch(
  //       fetchProdGraphData({ page: page, limit: resultPerPage, search })
  //     );
  //   }
  // }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (
      paramsPage &&
      resultPerPage &&
      paramsPage > 0 &&
      resultPerPage > 0 &&
      !firstTimeFetching
    ) {
      dispatch(
        fetchProdFirstLevel({
          page: paramsPage,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
      setPage(1);
    }
    setfirstTimeFetching(false);
  }, [defaultPageOneReq]);

  useEffect(() => {
    let paramsPage = 1;

    if (
      paramsPage &&
      resultPerPage &&
      paramsPage > 0 &&
      resultPerPage > 0
      // &&!firstTimeFetching
    ) {
      dispatch(
        fetchProdGraphData({ page: paramsPage, limit: resultPerPage, search })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]); // defaultPageOneReq

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const dispatchFun = () => {
    // dispatch(fetchProdGraphData({ page: page, limit: resultPerPage, search }));
  };

  const handleChatOnClick = (record) => {
    const { soid } = record;
    // fectch previous chat
    dispatch(fetchPreviousChat({ parent: { id: soid, model: "SoMain" } }));
    setCurrentPopupRecordId(record.id);
    setChatPopupVisible(true);
  };

  const handleVisibleChange = (visible) => {
    setChatPopupVisible(visible);
  };

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        sodate_start: "",
        sodate_end: "",
      });
    } else {
      setSearch({
        ...search,
        sodate_start: value[0],
        sodate_end: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedCallApi();
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        if (isValidNumber.test(value)) {
          setSearch({
            ...search,
            [key]: Number(value),
          });
          debouncedDefaultPageOneCallApi();
        }
      }}
    />
  );

  const dateInput = (key) => (
    <DatePicker
      placeholder=""
      onClick={(e) => e.stopPropagation()}
      className="activities-time-picker prod-due-date"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      suffixIcon={null}
      format="D MMM YYYY"
      // popupClassName="activities-time-picker"
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes("all")) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes("not-all")) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const handleExpandOnCloumnClick = (record) => {
    const { id } = record;
    if (isCleint && !isSecondLevel) {
      return;
    }

    if (expandedRowKeys.includes(id)) {
      // dispatch(fetchProdSecondLevelSuccess([]));
      setExpandedRowKeys([]);
    } else {
      dispatch(fetchProdSecondLevel(record));
      const keys = [];
      keys.push(record.id);
      setExpandedRowKeys(keys);
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>Date</div>
              </div>
            </div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={"D MMM YYYY"}
                handleDateInput={handleDateInput}
              />
            </div>
          </div>
        );
      },
      dataIndex: "sodate",
      key: "sodate",
      // sorter: (a, b) =>
      //   moment(a.expire_sodate).unix() - moment(b.expire_sodate).unix(),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "sodate" && sortedInfo?.order,
      ellipsis: true,
      width: 225,
      render: (date, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>
          {moment(date).format("DD MMMM YYYY")}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div>
                    <div style={{ color: "transparent" }}>_</div>
                    <div>Client</div>
                  </div>
                  <div>
                    <AMultiSelect
                      debouncedCallApi={debouncedDefaultPageOneCallApi}
                      disabled={false}
                      placeholder=""
                      selectTags={clientCheckedList}
                      onSelectChange={(value) =>
                        handleSelectOnchange(
                          value,
                          clientOptions,
                          "cusid", // clients
                          setClientCheckedList
                        )
                      }
                      data={getOptions(clientOptions, clientCheckedList) || []}
                      filterOption={(input, option) => {
                        return (
                          String(option?.label)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{ color: "transparent" }}>_</div>
                    <div>(Contact Person)</div>
                  </div>
                  <div>
                    <AMultiSelect
                      debouncedCallApi={debouncedDefaultPageOneCallApi}
                      disabled={false}
                      placeholder=""
                      selectTags={contactPersonCheckedList}
                      onSelectChange={(value) =>
                        handleSelectOnchange(
                          value,
                          contactPersonsOptions,
                          "cuscontactid",
                          setContactPersonCheckedList
                        )
                      }
                      data={
                        getOptions(
                          contactPersonsOptions,
                          contactPersonCheckedList
                        ) || []
                      }
                      filterOption={(input, option) => {
                        return (
                          option?.label
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
      dataIndex: "client_name",
      key: "client_name",
      // sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "client_name" && sortedInfo?.order,
      ellipsis: true,
      width: 240,
      render: (_, record) => {
        const { client_name, contact_person_name } = record;
        return (
          <div
            onClick={() => handleExpandOnCloumnClick(record)}
            style={{ whiteSpace: "normal" }}
          >
            <div>{client_name}</div>
            <div>({contact_person_name})</div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>So Number</div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={soNumCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    soNumbersOptions,
                    "soid",
                    setSoNumCheckedList
                  )
                }
                data={getOptions(soNumbersOptions, soNumCheckedList) || []}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "soid",
      key: "soid",
      // sorter: (a, b) => a.soid - b.soid,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "soid" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
      render: (soid, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>{soid}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>Format</div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={formatCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    formatOptions,
                    "format",
                    setFormatCheckedList
                  )
                }
                data={getOptions(formatOptions, formatCheckedList) || []}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "format",
      key: "format",
      // sorter: (a, b) => a.format.localeCompare(b.format),
      sorter: false, // true
      sortOrder: sortedInfo?.columnKey === "format" && sortedInfo?.order,
      ellipsis: true,
      width: 125,
      align: "center",
      render: (format, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>{format}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>Sales Rep</div>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={salesRepCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    salesRepsOptions,
                    "staffidrep",
                    setSalesRepCheckedList
                  )
                }
                data={getOptions(salesRepsOptions, salesRepCheckedList) || []}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "sales_rep_name",
      key: "sales_rep_name",
      // sorter: (a, b) => a.sales_rep_name.localeCompare(b.sales_rep_name),
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === "sales_rep_name" && sortedInfo?.order,
      ellipsis: true,
      width: 155,
      render: (sales_rep_name, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>
          {sales_rep_name}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>attach</div>
              </div>
            </div>

            <div style={{ marginBottom: "1px" }}>{searchInput("attach")}</div>
          </div>
        );
      },
      dataIndex: "fetch_attachments",
      key: "fetch_attachments",
      // sorter: (a, b) => a.fetch_attachments?.length - b.fetch_attachments?.length,
      sorter: null, // true
      sortOrder:
        sortedInfo?.columnKey === "fetch_attachments" && sortedInfo?.order,
      ellipsis: true,
      width: 75,
      render: (fetch_attachments, record) => {
        if (currentImgUpdateObj.id !== record.id) {
          return (
            <div
              className={`attach-parent-div`}
              onClick={() => {
                setCurrentImgUpdateObj(record);
                setAttachPopupVisible(true);
              }}
            >
              <AttachIcon
                className="rotate-icon activities-attach"
                width={16}
                fill="#231f20"
              />
              <div>{record?.fetch_attachments?.length || 0}</div>
            </div>
          );
        }

        return (
          <AttachmentsPopup
            images={record?.fetch_attachments || []}
            attachPopupVisible={attachPopupVisible}
            setAttachPopupVisible={setAttachPopupVisible}
            record={record}
            setCurrentUpdateObj={setCurrentImgUpdateObj}
            currentUpdateObj={currentImgUpdateObj}
          />
        );
      },
    },
    {
      title: () => {
        return (
          <div align="top" className="select-parent-div">
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>status</div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=""
                selectTags={statusCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    statusOptions,
                    "status",
                    setStatusCheckedList
                  )
                }
                data={statusOptions}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: "status",
      key: "status",
      // sorter: (a, b) => a.status.localeCompare(b.status),
      sorter: null, // true
      sortOrder: sortedInfo?.columnKey === "status" && sortedInfo?.order,
      ellipsis: true,
      width: 105,
      align: "center",
      className: "first-level-status-td",
      render: (_, record) => {
        const { fetch_order_detail, total_quantity } = record;

        let color =
          fetch_order_detail.completed_qty >= total_quantity
            ? "#7cd175"
            : "#94d4f0";
        let text =
          fetch_order_detail.completed_qty >= total_quantity
            ? "Completed"
            : "In Progress";

        return (
          text && (
            <Tag
              onClick={() => handleExpandOnCloumnClick(record)}
              color={color}
              // className="status-tag"
              style={{
                color: "#383838",
                marginRight: "0px",
                width: "70px",
                fontSize: "11px",
                padding: "0px",
              }}
            >
              {text && text}
            </Tag>
          )
        );
      },
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">
              <div>
                <div>total Qty</div>
                <div>Ordered</div>
              </div>
            </div>
            <div style={{ marginBottom: "1px" }}>
              {searchInput("total_qty")}
            </div>
          </div>
        );
      },
      dataIndex: "total_quantity",
      key: "total_quantity",
      // sorter: (a, b) => a.total_quantity - b.total_quantity,
      sorter: false, // true
      sortOrder:
        sortedInfo?.columnKey === "total_quantity" && sortedInfo?.order,
      ellipsis: true,
      width: 90,
      align: "right",
      render: (total_quantity, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>
          {total_quantity}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">
              <div>
                <div>total Qty</div>
                <div>completed</div>
              </div>
            </div>
            <div style={{ marginBottom: "1px" }}>
              {searchInput("total_qty_completed")}
            </div>
          </div>
        );
      },
      dataIndex: "total_qty_completed",
      key: "total_qty_completed",
      // sorter: (a, b) => a.total_qty_completed - b.total_qty_completed,
      sorter: false, // true
      sortOrder:
        sortedInfo?.columnKey === "total_qty_completed" && sortedInfo?.order,
      ellipsis: true,
      width: 100,
      align: "right",
      render: (total_qty_completed, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>
          {total_qty_completed}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align="top">
            <div className="special-table-header-text">
              <div>
                <div style={{ color: "transparent" }}>_</div>
                <div>Due Date</div>
              </div>
            </div>
            <div>{dateInput("duedate")}</div>
          </div>
        );
      },
      dataIndex: "duedate",
      key: "duedate",
      // sorter: (a, b) => moment(a.duedate).unix() - moment(b.duedate).unix(),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "duedate" && sortedInfo?.order,
      ellipsis: true,
      width: 100,
      render: (date, record) => (
        <div onClick={() => handleExpandOnCloumnClick(record)}>
          {date ? moment(date).format("DD MMM YYYY") : ""}
        </div>
      ),
    },
    {
      title: null,
      dataIndex: "",
      key: "action",
      width: 90,
      render: (_, record) => {
        return (
          <Space size={15} align="baseline">
            {currentPopupRecordId === record.id ? (
              <AddCommentPopup
                record={record}
                setCurrentObj={setCurrentObj}
                currentObj={currentObj}
                setCurrentPopupRecordId={setCurrentPopupRecordId}
                isVisibleAddComment={isVisibleAddComment}
                setIsVisibleAddComment={setIsVisibleAddComment}
              />
            ) : (
              <MessageIcon
                width={18}
                className="custom-icon"
                onClick={() => {
                  setCurrentPopupRecordId(record.id);
                  setIsVisibleAddComment(true);
                }}
              />
            )}
            {currentPopupRecordId === record.id ? (
              <ChatPopup
                handleChatOnClick={handleChatOnClick}
                chatPopupVisible={chatPopupVisible}
                setChatPopupVisible={setChatPopupVisible}
                record={record}
                setCurrentPopupRecordId={setCurrentPopupRecordId}
              />
            ) : (
              <ChatIcon
                width={18}
                className="custom-icon"
                onClick={() => handleChatOnClick(record)}
              />
            )}
            {/* <EditIcon width={18} className="custom-icon" /> */}
          </Space>
        );
      },
    },
  ];

  // const onTableRowExpand = (expanded, record) => {
  //   if (expanded) {
  //     dispatch(fetchProdSecondLevel(record));
  //   } else {
  //     dispatch(fetchProdSecondLevelSuccess([]));
  //   }
  //   const keys = [];
  //   if (expanded) {
  //     keys.push(record.id);
  //   }
  //   setExpandedRowKeys(keys);
  // };

  return (
    <>
      <Table
        className="special-table"
        columns={columns}
        scroll={{ x: 1425 }}
        expandable={{
          expandedRowRender: () => (
            <SecondLevelComponent
              dispatchFun={dispatchFun}
              firstLevelDebounce={debouncedDefaultPageOneCallApi}
              soid={expandedRowKeys[0] || ""}
            />
          ),
          // expandRowByClick: true,
          expandIcon: null,
          showExpandColumn: false,
        }}
        onRow={({ id }) =>
          expandedRowKeys.includes(id) && { className: "expand-parent" }
        }
        onChange={handleChange}
        dataSource={firstLevelData}
        pagination={false}
        sortUpIcon={<UpOutlined />}
        sortDownIcon={<DownOutlined />}
        expandedRowKeys={expandedRowKeys}
        // onExpand={onTableRowExpand}
        loading={fetchLoading || searchUtilsLoading}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        resultPerPage={resultPerPage}
        setResultPerPage={setResultPerPage}
        debouncedCallApi={debouncedCallApi}
        totalRecord={totalRecords}
      />
    </>
  );
};

export default ProductionManagerTable;

import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tooltip } from "antd";
// import SalesActualVsTarget from "./ProductionActualVsTarget";
// import ProdRepByOperator from "./ProdRepByOperator";
import { ReactComponent as SortAlphabetically } from "../../assets/sortAlphabetically.svg";
import { ReactComponent as SortByAmount } from "../../assets/sortByAmount.svg";
import TotalProductionGraph from "./totalProductionGraph";
import TotalActualVsTargetGraph from "./TotalActualVsTargetGraph";
import {
  sortClientDataByAmount,
  sortSalesRepDataByAlpha,
  sortProdDataByAlpha,
  sortOperatorDataByAmount,
} from "./utils";
import ProdQtybyClientGraph from "./ProdQtybyClientGraph";
import { useSelector } from "react-redux";
import SpinnerComponent from "../../components/spinner";

const ProductionReporting = () => {
  const [productionByClientGraph, setProductionByClientGraph] = useState(true);
  const [productionData, setProductionData] = useState([]);
  const [salesRepData, setSalesRepData] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    productionAscAplha: true,
    productionAscAmount: false,
    salesRepAlpha: true,
    salesRepAmount: false,
  });

  const {
    clientGraphData,
    operatorGraphData,
    colorsObjArr,
    operatorsColorsObjArr,
  } = useSelector((state) => state.productionReducer.graphData);

  const { fetchGraphLoading } = useSelector((state) => state.productionReducer);

  useEffect(() => {
    setProductionData(clientGraphData);
    setSalesRepData(operatorGraphData);
  }, []);

  const handleSortClientByAlpha = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      productionAscAplha: !prev.productionAscAplha,
    }));
    let res = sortProdDataByAlpha(
      clientGraphData,
      sortOrder.productionAscAplha
    );
    setProductionData(res);
  };

  const handleSortClientByAmount = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      productionAscAmount: !prev.productionAscAmount,
    }));
    let res = sortClientDataByAmount(
      clientGraphData,
      sortOrder.productionAscAmount
    );
    setProductionData(res);
  };

  const handleSortSalesRepByAlpha = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      salesRepAlpha: !prev.salesRepAlpha,
    }));
    let res = sortSalesRepDataByAlpha(
      operatorGraphData,
      sortOrder.salesRepAlpha
    );
    setProductionData(res);
  };

  const handleSortSalesRepByAmount = () => {
    setSortOrder((prev) => ({
      ...sortOrder,
      salesRepAmount: !prev.salesRepAmount,
    }));
    let res = sortOperatorDataByAmount(
      operatorGraphData,
      sortOrder.salesRepAmount
    );
    setProductionData(res);
  };

  return (
    <Row className="production-manager-parent-row">
      <Col span={24} className="heading-col">
        <Row>
          <Col md={10} lg={8} xl={6} xxl={4}>
            <span className="heading-span">Production Reporting</span>
          </Col>
          <Col md={14} lg={16} xl={18} xxl={20}>
            <Button
              onClick={() =>
                !fetchGraphLoading && setProductionByClientGraph(true)
              }
              className={`custom-btn1 client-btn ${
                productionByClientGraph && "selected"
              }`}
            >
              By Client
            </Button>
            <Button
              onClick={() =>
                !fetchGraphLoading && setProductionByClientGraph(false)
              }
              className={`custom-btn1 sales-rep-btn ${
                !productionByClientGraph && "selected"
              }`}
            >
              By Operator
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="graph-section">
        {fetchGraphLoading ? (
          <div className="spinner-div">
            <SpinnerComponent fontSize={50} />
          </div>
        ) : productionByClientGraph ? (
          <Row gutter={[50, 50]}>
            <Col md={24} lg={24} xl={24} xxl={17}>
              <div className="production-by-client-div">
                <span className="graph-text-span">
                  Production Quantities by Client by Format
                </span>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title="Sort by Alphabetically"
                >
                  <SortAlphabetically
                    className="sort-icons"
                    onClick={handleSortClientByAlpha}
                  />
                </Tooltip>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Amount`}
                >
                  <SortByAmount
                    className="sort-icons"
                    onClick={handleSortClientByAmount}
                  />
                </Tooltip>
              </div>
              <div className="graph-label-with-text">
                <div className="actual-div" style={{ fontWeight: 600 }}>
                  Format:
                </div>
                <Row gutter={[10, 5]} justify="start" align="top">
                  {colorsObjArr.map((el) => {
                    const { key, color } = el;
                    return (
                      <Col key={key}>
                        <div className="actual-div">
                          <span
                            className="dot"
                            style={{
                              backgroundColor: color,
                            }}
                          ></span>
                          {key}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <ProdQtybyClientGraph
                data={clientGraphData}
                colorsArr={colorsObjArr}
                text={"Client Name"}
              />
              {/* <ProdRepByOperator data={productionData} /> */}
            </Col>
            <Col md={24} lg={24} xl={24} xxl={7}>
              <span className="graph-text-span">
                Ordered and Completed by Format
              </span>
              <div className="ordered-compl-label-with-text">
                <div className="ordered-div">
                  <span className="ordered-dot"></span>Ordered
                </div>
                <div className="completed-div">
                  <span className="completed-dot"></span>
                  Completed
                </div>
              </div>
              <TotalProductionGraph />
            </Col>
          </Row>
        ) : (
          <Row gutter={[50, 50]}>
            <Col md={24} lg={24} xl={24} xxl={17}>
              <div className="production-by-client-div">
                <span className="graph-text-span">
                  Production Quantities by Operator by Format
                </span>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Alphabetically`}
                >
                  <SortAlphabetically
                    className="sort-icons"
                    onClick={handleSortSalesRepByAlpha}
                  />
                </Tooltip>
                <Tooltip
                  arrowPointAtCenter
                  placement="top"
                  title={`Sort by Amount`}
                >
                  <SortByAmount
                    className="sort-icons"
                    onClick={handleSortSalesRepByAmount}
                  />
                </Tooltip>
              </div>
              <div className="graph-label-with-text">
                <div className="actual-div" style={{ fontWeight: 600 }}>
                  Format:
                </div>
                <Row gutter={[10, 5]} justify="start" align="top">
                  {operatorsColorsObjArr.map((el) => {
                    const { key, color } = el;
                    return (
                      <Col key={key}>
                        <div className="actual-div">
                          <span
                            className="dot"
                            style={{
                              backgroundColor: color,
                            }}
                          ></span>
                          {key}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              {/* <SalesActualVsTarget data={salesRepData} /> */}
              <ProdQtybyClientGraph
                data={operatorGraphData}
                text="Operator Name"
                colorsArr={operatorsColorsObjArr}
              />
            </Col>
            <Col md={24} lg={24} xl={24} xxl={7}>
              <span className="graph-text-span">
                Totals Completed by Format
              </span>
              {/* <div className="graph-label-with-text">
                <div className="actual-div">
                  <span className="blue-dot"></span>
                  Production Qty
                </div>
                <div className="target-div">
                  <span className="red-dot"></span>
                  Completed
                </div>
              </div> */}
              <TotalActualVsTargetGraph />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ProductionReporting;

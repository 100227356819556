import React, { useEffect, useState } from "react";
import {
  Table,
  Form,
  Select,
  Row,
  Col,
  Avatar,
  Popover,
  Checkbox,
  message,
  Button,
  Space,
  Input,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ReactComponent as StartIcon } from "../../assets/startIcon.svg";
import { ReactComponent as StopIcon } from "../../assets/stopIcon.svg";
import { ReactComponent as MessageIcon } from "../../assets/messageIcon.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/crossIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./redux/actions";
import { Option } from "antd/lib/mentions";
// import SpinnerComponent from "../../components/spinner";
import { baseURL, isValidNumber } from "../../globalUtils/axiosConfig";
import TextArea from "antd/lib/input/TextArea";
import EnterPinModal from "./enterPinModal";
import AddCommentPopup from "./addCommentPopup";
import UseTimer from "./useTimer";
import RecordedTimesPopover from "./recordedTimesPopOver";
import SpinnerComponent from "../../components/spinner";
import UseGetUserRole from "../../components/useGetUserRole";
import { getTotalTime } from "../../globalUtils/convertStrToMinutes";
import UseCanAddOperator from "../../components/useCanAddOperatorProdLevelFour";

const FourthLevelComponent = ({
  renderOtherCol,
  firstLevelDebounce,
  soid,
  soitemid,
}) => {
  const [expandedSortedInfo, setExpandedSortedInfo] = useState(null);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [pinModalVisible, setPinModalVisible] = useState(false);
  const [pin, setPin] = useState("");
  const [currentObj, setCurrentObj] = useState({});
  const [isVisibleAddComment, setIsVisibleAddComment] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [quantity, setQuantity] = useState({});
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [, , ,] = UseGetUserRole();
  const canAddOperator = UseCanAddOperator();

  const fourthLevelData = useSelector(
    (state) => state.fourthLevelReducer.fourthLevelData
  );
  const items_processing_step_id = useSelector(
    (state) => state.fourthLevelReducer.items_processing_step_id
  );

  const department_users = useSelector(
    (state) => state.fourthLevelReducer.department_users
  );

  const fetchLoading = useSelector(
    (state) => state.fourthLevelReducer.fetchLoading
  );
  const updateLoading = useSelector(
    (state) => state.fourthLevelReducer.updateLoading
  );

  useEffect(() => {
    setData(fourthLevelData);
  }, [fourthLevelData]);

  const isEditing = (record) => record.id === editingKey;

  const handleClose = () => {
    setIsVisibleAddComment(false);
  };

  const handleStart = (record) => {
    setCurrentObj(record);
    setPinModalVisible(true);
  };

  const handleStop = (record) => {
    let values = form.getFieldsValue();
    let qty = values[`qty_${record.id}`];
    let value = Number(qty);

    if (!isValidNumber.test(value)) {
      return message.error("Quantity is invalid!");
    }

    if (value < 1) {
      message.error("Please Insert Quantity");
    } else {
      setQuantity(value);
      setCurrentObj(record);
      setPinModalVisible(true);
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              paddingLeft: "50px",
            }}
          >
            Name
          </div>
        );
      },
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder:
        expandedSortedInfo?.columnKey === "description" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      width: 370,
      editable: true,
      render: (_, record) => {
        const { user_first_name, user_surname, user_profile_picture } = record;
        return (
          <Row justify="start" align="middle">
            <Col span={3}>
              {!record?.isAdd &&
                (user_profile_picture ? (
                  <Avatar src={`${baseURL}${user_profile_picture}`} />
                ) : (
                  <Avatar />
                ))}
            </Col>
            <Col span={12}>{user_first_name + " " + user_surname}</Col>
          </Row>
        );
      },
    },
    {
      title: () => (
        <div className="ant-table-column-title-without-input">
          <div className="ant-table-column-title">start</div>
        </div>
      ),
      dataIndex: "start",
      key: "start",
      // sorter: (a, b) => a?.start.localeCompare(b?.start),
      // sortOrder:
      //   expandedSortedInfo?.columnKey === "start" && expandedSortedInfo?.order,
      ellipsis: true,
      width: 120,
      align: "center",
      render: (_, record) => {
        const { ongoing_activity } = record;
        if (record?.isAdd) {
          return <StartIcon width={41} height={41} className="disable-icon" />;
        }
        return (
          <StartIcon
            width={41}
            height={41}
            onClick={() => ongoing_activity === null && handleStart(record)}
          />
        );
      },
    },
    {
      title: () => (
        <div className="ant-table-column-title-without-input">
          <div className="ant-table-column-title">qty</div>
        </div>
      ),
      key: "qty",
      dataIndex: "qty",
      // sorter: (a, b) => a.processSteps.localeCompare(b.processSteps),
      // sortOrder:
      //   expandedSortedInfo?.columnKey === "qty" && expandedSortedInfo?.order,
      ellipsis: true,
      align: "center",
      width: 125,
      render: (_, record) => {
        const { ongoing_activity } = record;
        if (record?.isAdd) {
          return (
            <Input
              style={{
                height: "32px",
                fontsize: "13px",
              }}
              readOnly={true}
              className="disable-icon"
            />
          );
        }
        return (
          <Form.Item
            name={`qty_${record.id}`}
            rules={[{ pattern: isValidNumber, message: "" }]}
            className="quatity-form-input"
          >
            <Input readOnly={ongoing_activity === null} />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      width: 40,
      align: "center",
      render: (_, record) => {
        const { ongoing_activity } = record;
        return ongoing_activity === null ? (
          <ReadOnlyPopOver content={""} />
        ) : (
          <AddCommentPopOver record={record} />
        );
      },
    },
    {
      title: () => (
        <div className="ant-table-column-title-without-input">
          <div className="ant-table-column-title">stop</div>
        </div>
      ),
      dataIndex: "stop",
      key: "stop",
      // sorter: (a, b) => a?.start.localeCompare(b?.start),
      // sortOrder:
      //   expandedSortedInfo?.columnKey === "start" && expandedSortedInfo?.order,
      ellipsis: true,
      width: 80,
      align: "center",
      render: (_, record) => {
        const { ongoing_activity } = record;
        if (record?.isAdd) {
          return <StopIcon width={35} height={35} className="disable-icon" />;
        }
        return ongoing_activity === null ? (
          <StopIcon
            width={35}
            height={35}
            onClick={() => message.error("Not started yet")}
          />
        ) : (
          <StopIcon width={35} height={35} onClick={() => handleStop(record)} />
        );
      },
    },
    {
      title: () => (
        <div className="ant-table-column-title-without-input">
          <div className="ant-table-column-title">timer</div>
        </div>
      ),
      dataIndex: "timer",
      key: "timer",
      width: 140,
      // sorter: (a, b) => a?.timer.localeCompare(b?.timer),
      // sortOrder:
      //   expandedSortedInfo?.columnKey === "timer" && expandedSortedInfo?.order,
      ellipsis: true,
      align: "center",
      render: (_, record) => {
        const { ongoing_activity } = record;
        if (record?.isAdd) {
          return <>00h 00m 0s</>;
        }
        return (
          ongoing_activity !== null && (
            <UseTimer date={ongoing_activity.start_time} />
          )
        );
      },
    },
    {
      title: "Total Time",
      dataIndex: "total_time",
      key: "total_time",
      sorter: (a, b) => a?.total_time - b?.total_time,
      sortOrder:
        expandedSortedInfo?.columnKey === "total_time" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      align: "center",
      width: 190,
      render: (_, record) => {
        const { operator_activities } = record;
        if (record?.isAdd) {
          return <>00h 00m 0s</>;
        }
        return <span>{getTotalTime(operator_activities)}</span>;
      },
    },
    {
      title: "Qty completed",
      dataIndex: "qtyCompleted",
      key: "qtyCompleted",
      width: 110,
      sorter: (a, b) =>
        a.fetch_item_detail?.completed_qty.localeCompare(
          b.fetch_item_detail?.completed_qty
        ),
      sortOrder:
        expandedSortedInfo?.columnKey === "qtyCompleted" &&
        expandedSortedInfo?.order,
      ellipsis: true,
      align: "right",
      render: (_, record) => {
        const { operator_activities } = record;
        if (record?.isAdd) {
          return <>0</>;
        }
        return (
          <div>
            {operator_activities?.reduce((acc, obj) => acc + obj.quantity, 0)}
          </div>
        );
      },
    },
    {
      title: () => (
        <div
          className="ant-table-column-title"
          style={{ justifyContent: "start", marginBottom: "5px" }}
        >
          Recorded Times
        </div>
      ),
      dataIndex: "recorded_times",
      key: "recorded_times",
      ellipsis: true,
      width: 110,
      align: "center",
      render: (_, record) => {
        const { operator_activities } = record;
        return <RecordedTimesPopover activities={operator_activities} />;
      },
    },
    {
      title: "",
      ellipsis: true,
      width: 80,
      align: "right",
      render: (_, record) => {
        if (record?.isAdd) {
          return loading && editingKey == record.id ? (
            <SpinnerComponent fontSize={18} />
          ) : (
            <Space size={20}>
              <SaveIcon
                className="custom-icon"
                onClick={() => save(record.id)}
              />
              <CrossIcon
                className="custom-icon"
                onClick={() => cancel(record)}
              />
            </Space>
          );
        }
      },
    },
  ];

  const handleSuccess = (id) => {
    let fbKey = `feedback_${id}`;
    setCurrentObj({});
    setIsPinVerified(false);
    setQuantity({});
    setFeedback({ ...feedback, [fbKey]: "" });
    setEditingKey("");
  };

  const operationalCaseHandleSuccess = (id) => {
    let values = form.getFieldsValue();
    let key = `qty_${id}`;
    let fbKey = `feedback_${id}`;
    form.setFieldsValue({ ...values, [key]: null });
    setCurrentObj({});
    setFeedback({ ...feedback, [fbKey]: "" });
    setPin("");
    setIsPinVerified(false);
    setQuantity({});
    setEditingKey("");
  };

  const fetchFourthLevelData = () => {
    dispatch(actions.fetchProdThirdLevel({ soitemid }));
    dispatch(actions.fetchProdSecondLevel({ soid }));
    // firstLevelDebounce();
  };

  const handelPinVerified = () => {
    const { id } = currentObj;
    if (renderOtherCol) {
      // this is checkBox row Case
      dispatch(
        actions.updateOperator({
          data: {
            processing_operator_id: id,
            activity_type: "Approval",
            is_approved: true,
            feedback: feedback[`feedback_${id}`],
          },
          handleSuccess,
          fetchFourthLevelData,
        })
      );
    } else if (currentObj.ongoing_activity === null) {
      // this is start time case
      const startCaseParams = {
        start_time: new Date(),
        processing_operator_id: id,
        activity_type: "Operational",
      };
      dispatch(
        actions.updateOperator({
          data: startCaseParams,
          handleSuccess: () => operationalCaseHandleSuccess(id),
          fetchFourthLevelData: () => {},
        })
      );
    } else {
      // this is stop time case
      const stopCaseParams = {
        id: currentObj.ongoing_activity?.id,
        feedback: feedback[`feedback_${id}`],
        quantity,
        stop_time: new Date(),
      };
      dispatch(
        actions.stopOperator({
          data: stopCaseParams,
          handleSuccess: () => operationalCaseHandleSuccess(id),
          fetchFourthLevelData,
        })
      );
    }
  };

  const onChange = (record) => {
    setPinModalVisible(true);
    setCurrentObj(record);
  };

  const ReadOnlyPopOver = ({ content }) => (
    <Popover
      overlayClassName="add-comment-popover"
      placement="top"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      content={
        <Row justify="center" gutter={[0, 10]}>
          <Col span={24}>
            <TextArea rows={3} readOnly={true} value={content} />
          </Col>
        </Row>
      }
      trigger="click"
    >
      <MessageIcon fill="#eb078e" width={18} height={18} />
    </Popover>
  );

  const AddCommentPopOver = ({ record }) => {
    const { id } = record;
    let key = `feedback_${id}`;
    const handleFeedBack = (text) => {
      setFeedback({ ...feedback, [key]: text });
    };

    return (
      <Popover
        overlayClassName="add-comment-popover"
        placement="top"
        getPopupContainer={() =>
          document.getElementsByClassName(
            "ant-layout-content layout-content site-layout-background custom-textarea"
          )[0]
        }
        content={
          <AddCommentPopup
            setFeedback={handleFeedBack}
            feedback={feedback[key]}
            handleClose={handleClose}
          />
        }
        title={
          <Row justify="space-between">
            <Col className="title">ADD COMMENT</Col>
            <Col>
              <CloseOutlined onClick={handleClose} />
            </Col>
          </Row>
        }
        trigger="click"
        visible={isVisibleAddComment && id === currentObj.id}
        onVisibleChange={(e) => {
          setIsVisibleAddComment(e);
          setCurrentObj(record);
        }}
      >
        <MessageIcon fill="#eb078e" width={18} height={18} />
      </Popover>
    );
  };

  const otherColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder:
        expandedSortedInfo?.columnKey === "name" && expandedSortedInfo?.order,
      ellipsis: true,
      width: 850,
      editable: true,
      render: (_, record) => {
        const { user_profile_picture, user_first_name, user_surname } = record;
        return (
          <Row justify="start" align="middle" gutter={[10, 10]}>
            <Col>
              {!record?.isAdd &&
                (user_profile_picture ? (
                  <Avatar src={`${baseURL}${user_profile_picture}`} />
                ) : (
                  <Avatar />
                ))}
            </Col>
            <Col span={6}>{user_first_name + " " + user_surname}</Col>
          </Row>
        );
      },
    },
    {
      title: "",
      width: 325,
      align: "right",
      render: (_, record) => {
        const { operator_activities } = record;
        if (record?.isAdd) {
          return (
            <Checkbox
              style={{
                flexDirection: "row-reverse",
              }}
              checked={false}
              disabled={true}
            >
              Notification of Layout complete
            </Checkbox>
          );
        }
        return (
          <Checkbox
            style={{
              flexDirection: "row-reverse",
            }}
            checked={operator_activities.length > 0}
            disabled={operator_activities.length > 0 || updateLoading}
            onChange={(e) => onChange(record)}
          >
            Notification of Layout complete
          </Checkbox>
        );
      },
    },
    {
      title: "",
      width: 110,
      align: "center",
      render: (_, record) => {
        const { operator_activities } = record;
        if (record?.isAdd) {
          return <MessageIcon fill="#eb078e" width={18} height={18} />;
        }
        return operator_activities.length > 0 ? (
          <ReadOnlyPopOver content={operator_activities[0].feedback} />
        ) : (
          <AddCommentPopOver record={record} />
        );
      },
    },
    {
      name: "",
      width: 80,
      render: (_, record) => {
        if (record?.isAdd) {
          return loading && editingKey == record.id ? (
            <SpinnerComponent fontSize={18} />
          ) : (
            <Space size={20}>
              <SaveIcon
                width={18}
                height={18}
                fill="#eb078e"
                onClick={() => save(record.id)}
              />
              <CrossIcon
                width={18}
                height={18}
                onClick={() => cancel(record)}
              />
            </Space>
          );
        }
      },
    },
  ];

  const handleExpendedChange = (pagination, filters, sorter) => {
    setExpandedSortedInfo(sorter);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    switch (dataIndex) {
      case "name":
        inputNode = (
          <Select
            showSearch
            className="activities-selector"
            placeholder="Select Operator"
            getPopupContainer={() =>
              document.getElementsByClassName(
                "ant-layout-content layout-content site-layout-background custom-textarea"
              )[0]
            }
            dropdownClassName="select-dropdown-custom"
            size="small"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {department_users.map(({ id, first_name, surname }) => (
              <Option key={id} value={id}>
                {first_name + " " + surname}
              </Option>
            ))}
          </Select>
        );
        break;
      default:
        break;
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const otherMergedColumns = otherColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const handleAddOperatorSuccess = () => {
    form.resetFields();
    setCurrentUpdateObj({});
    setEditingKey("");
    setLoading(false);
  };

  const hanldeFinish = (item) => {
    const { name } = item;
    setLoading(true);
    dispatch(
      actions.addOperator({
        data: {
          user_id: Number(name),
          items_processing_step_id,
        },
        handleSuccess: handleAddOperatorSuccess,
      })
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = (record) => {
    setData((data) => data.filter((el) => el.id !== record.id));
    form.resetFields();
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = () => {
    let id = Math.floor(Math.random() * 1122123456);
    const newData = {
      id,
      items_processing_step_id: "",
      ongoing_activity: null,
      operator_activities: [],
      user_first_name: "",
      user_id: "",
      user_profile_picture: "",
      user_surname: "",
      isAdd: true,
    };
    setData((data) => [...data, newData]);
    setEditingKey(id);
    edit(newData);
  };

  return (
    <>
      <EnterPinModal
        isVisible={pinModalVisible}
        setVisible={setPinModalVisible}
        setPin={setPin}
        pin={pin}
        record={currentObj}
        setIsPinVerified={setIsPinVerified}
        handelPinVerified={handelPinVerified}
      />
      <Form form={form} component={false} onFinish={hanldeFinish}>
        <Table
          className={`custom-expanded-table table-second-level`}
          dataSource={data}
          pagination={false}
          loading={fetchLoading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          onChange={handleExpendedChange}
          columns={renderOtherCol ? otherMergedColumns : mergedColumns}
          sortUpIcon={<UpOutlined />}
          sortDownIcon={<DownOutlined />}
        />
        {!fetchLoading && canAddOperator && (
          <div className="add-operator-div">
            <Button
              className="add-operator-btn"
              size="middle"
              onClick={handleAdd}
              icon={<PlusOutlined />}
              disabled={editingKey > 0}
            >
              Operator
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default FourthLevelComponent;

import * as constants from "./constants";

export const fetchSalesUtilsData = (data) => {
  return {
    type: constants.FETCH_SALES_SEARCH_UTILS,
    data,
  };
};

export const fetchSalesUtilsDataSuccess = (data) => {
  return {
    type: constants.FETCH_SALES_SEARCH_UTILS_SUCCESS,
    data: data,
  };
};

export const fetchSalesUtilsDataError = (data) => {
  return {
    type: constants.FETCH_SALES_SEARCH_UTILS_ERROR,
  };
};

export const fetchSalesData = (data) => {
  return {
    type: constants.FETCH_SALES,
    data,
  };
};

export const fetchSalesDataSuccess = (data) => {
  return {
    type: constants.FETCH_SALES_SUCCESS,
    data: data,
  };
};

export const fetchSalesDataError = (data) => {
  return {
    type: constants.FETCH_SALES_ERROR,
  };
};

export const fetchSalesSecondLevelData = (data) => {
  return {
    type: constants.FETCH_SALES_SECOND_LEVEL,
    data,
  };
};

export const fetchSalesSecondLevelDataSuccess = (data) => {
  return {
    type: constants.FETCH_SALES_SECOND_LEVEL_SUCCESS,
    data: data,
  };
};

export const fetchSalesSecondLevelDataError = (data) => {
  return {
    type: constants.FETCH_SALES_SECOND_LEVEL_ERROR,
  };
};

export const fetchSalesGraphData = (data) => {
  return {
    type: constants.FETCH_SALES_GRAPH,
    data,
  };
};

export const fetchSalesGraphDataSuccess = (data) => {
  return {
    type: constants.FETCH_SALES_GRAPH_SUCCESS,
    data: data,
  };
};

export const fetchSalesGraphDataError = (data) => {
  return {
    type: constants.FETCH_SALES_GRAPH_ERROR,
  };
};

export const salesCleanupFunc = () => {
  return {
    type: constants.SALES_CLEAN_UP_FUNC,
  };
};

import React from "react";
import { Button, Col, Row } from "antd";
import "./index.less";
import { PlusOutlined } from "@ant-design/icons";
import ClientManagerTable from "./clientManagerTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setClientDetailsIsEdit } from "./redux/actions";

const ClientsManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="page-top-div">
      <span className="text-span">Clients Manager</span>
      <Row className="production-manager-parent-row">
        <Col span={24} className="heading-col">
          <Row align="middle" gutter={[30, 0]}>
            <Col xl={5} xxl={3}>
              <div>
                <span className="heading-span">Clients Register</span>
              </div>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  navigate("/settings/clients-manager/create-client");
                  dispatch(setClientDetailsIsEdit(true));
                }}
                icon={<PlusOutlined />}
                className="add-user-btn"
                size="middle"
              >
                Client
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ClientManagerTable />
        </Col>
      </Row>
    </div>
  );
};

export default ClientsManager;

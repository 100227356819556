import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { priceFormatTwoDecimal } from "../../globalUtils/convertStrToMinutes";

const SalesActualVsTarget = ({ data }) => {
  const keys = ["Actual", "Target"];
  const colors = ["#118DFF", "#eb078e"];

  const ValueOutside = ({ bars }) => {
    return bars.map((bar) => {
      const {
        key,
        width,
        height,
        x,
        y,
        data: { value },
      } = bar;
      return (
        <g key={key} transform={`translate(${x + 10}, ${y})`}>
          <text
            transform={`translate(${width + 20}, ${height / 2 + 4})`}
            textAnchor="middle"
            fontSize="11px"
            fill="#757575"
          >
            {`R${priceFormatTwoDecimal((value / 1000).toFixed(2))}K`}
          </text>
        </g>
      );
    });
  };

  const CustomBottomLabel = (data) => {
    const { x, value } = data;

    return (
      <g>
        {/* <rect x={x + 15 * 3} y={0} width={3} height="10" fill="#B1B1B1" /> */}
        {/* <rect x={x - 80 / 2} y={4} width={80} height="3" fill="#B1B1B1" /> */}

        <text x={x - 20 / 2} y={20} fontSize="12px" fill="#757575">
          {`R${priceFormatTwoDecimal((value / 1000).toFixed(2))}K`}
        </text>
      </g>
    );
  };

  // const CustomLeftLabel = (data) => {
  //   const { x, y, value } = data;
  //   // console.log("x, value", x, value);

  //   return (
  //     <g>
  //       {/* <rect x={x + 15 * 3} y={0} width={3} height="10" fill="#B1B1B1" /> */}
  //       {/* <rect x={x - 80 / 2} y={4} width={80} height="3" fill="#B1B1B1" /> */}

  //       <text x={-130} y={y} fontSize="12px" fill="#757575">
  //         {value}
  //       </text>
  //     </g>
  //   );
  // };

  // const getHeight = (length) => {
  //   if (length < 20) {
  //     return 600;
  //   }
  //   return length * 30;
  // };

  return (
    // <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
    <div style={{ height: "600px" }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="id"
        margin={{
          top: 20,
          right: 100,
          bottom: 100,
          left: 160,
        }}
        // indexScale={(props) => console.log('props', props)}
        colors={colors}
        padding={0.3}
        groupMode="grouped"
        layout="horizontal"
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={true}
        enableGridY={false}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: null,
              },
            },
            domain: {
              line: {
                stroke: "",
                strokeWidth: 2,
              },
            },
            legend: {
              text: {
                fontWeight: "bold",
                letterSpacing: "1px",
              },
            },
          },
          grid: {
            line: {
              stroke: `rgb(231 232 236 / 80%)`,
              strokeWidth: 2,
              strokeDasharray: "2, 4",
            },
          },
        }}
        labelTextColor="white"
        layers={["grid", "axes", "bars", "markers", ValueOutside]}
        legends={[
          {
            dataFrom: "keys",
            data: keys.map((id, index) => ({
              color: colors[index],
              id,
              label: id === "id" ? 1 : 2,
            })),
            anchor: "bottom",
            direction: "row",
            translateY: 63,
            itemWidth: 50,
            itemHeight: 20,
            itemDirection: "left-to-right",
            symbolSize: 20,
          },
        ]}
        // tooltip={(props) => {
        //   const { id, Target , "} = props.data;
        //   return <div>{`${id} - ${Target}, "K`}</div>;
        // }}
        // label={(props) => {
        //   const { Target , "} = props.data;
        //   return `${Target}, "K`;
        // }}
        // legendLabel={(props) => {
        //   const { Target , "} = props.data;
        //   return <div>Target<, "/div>;
        // }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Sales Rep",
          legendPosition: "middle",
          legendOffset: -150,
          // renderTick: CustomLeftLabel,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Actual and Target",
          legendPosition: "middle",
          legendOffset: 60,
          renderTick: CustomBottomLabel,
          tickValues: 6,
        }}
        // labelFormat={(d) => {
        //   console.log("d", d);
        //   return <tspan y={0}>{d}</tspan>;
        // }}
      />
      {/* </div> */}
    </div>
  );
};

export default SalesActualVsTarget;

const FETCH_SALES = "FETCH_SALES";
const FETCH_SALES_SUCCESS = "FETCH_SALES_SUCCESS";
const FETCH_SALES_ERROR = "FETCH_SALES_ERROR";
const FETCH_SALES_SECOND_LEVEL = "FETCH_SALES_SECOND_LEVEL";
const FETCH_SALES_SECOND_LEVEL_SUCCESS = "FETCH_SALES_SECOND_LEVEL_SUCCESS";
const FETCH_SALES_SECOND_LEVEL_ERROR = "FETCH_SALES_SECOND_LEVEL_ERROR";
const FETCH_SALES_GRAPH = "FETCH_SALES_GRAPH";
const FETCH_SALES_GRAPH_SUCCESS = "FETCH_SALES_GRAPH_SUCCESS";
const FETCH_SALES_GRAPH_ERROR = "FETCH_SALES_GRAPH_ERROR";
const FETCH_SALES_SEARCH_UTILS = "FETCH_SALES_SEARCH_UTILS";
const FETCH_SALES_SEARCH_UTILS_SUCCESS = "FETCH_SALES_SEARCH_UTILS_SUCCESS";
const FETCH_SALES_SEARCH_UTILS_ERROR = "FETCH_SALES_SEARCH_UTILS_ERROR";
const SALES_CLEAN_UP_FUNC = "SALES_CLEAN_UP_FUNC";

export {
  FETCH_SALES,
  FETCH_SALES_SUCCESS,
  FETCH_SALES_ERROR,
  FETCH_SALES_GRAPH,
  FETCH_SALES_SECOND_LEVEL,
  FETCH_SALES_SECOND_LEVEL_SUCCESS,
  FETCH_SALES_SECOND_LEVEL_ERROR,
  FETCH_SALES_GRAPH_SUCCESS,
  FETCH_SALES_GRAPH_ERROR,
  SALES_CLEAN_UP_FUNC,
  FETCH_SALES_SEARCH_UTILS,
  FETCH_SALES_SEARCH_UTILS_SUCCESS,
  FETCH_SALES_SEARCH_UTILS_ERROR,
};

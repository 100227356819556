import React, { useState, useEffect } from "react";
import { Table, Input, InputNumber, Form, Col } from "antd";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartEmailData, updateDepartEmail } from "./redux/actions";
import SpinnerComponent from "../../components/spinner";
import CustomPagination from "../../components/customPagination/CustomPagination";
import _ from "lodash";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const DepartmentEmailsTable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [sortedInfo, setSortedInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingLoading, setEditingLoading] = useState(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchDepartEmailData({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === "ascend"
              ? "asc"
              : "desc"
            : null,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchDepartEmailData({ page: paramsPage, limit: resultPerPage, search })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const isEditing = (record) => record.id === editingKey;

  const { departEmailsList, fetchingLoading, updateLoading, totalRecords } =
    useSelector((state) => state.departEmailsReducer);

  useEffect(() => {
    setLoading(fetchingLoading);
  }, [fetchingLoading]);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey("");
    }
  }, [updateLoading]);

  useEffect(() => {
    setData(departEmailsList);
  }, [departEmailsList]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const edit = (record) => {
    form.setFieldsValue({
      email: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        dispatch(updateDepartEmail({ ...item, ...row }));
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align="top" className="top">
            <div className="special-table-header-text">No.</div>
            <div>{searchInput("id")}</div>
          </div>
        );
      },
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.id - b.id,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "id" && sortedInfo?.order,
      ellipsis: true,
      //   editable: false,
    },
    {
      title: () => {
        return (
          <div align="top" className="top">
            <div className="special-table-header-text">Department</div>
            <div>{searchInput("name")}</div>
          </div>
        );
      },
      dataIndex: "name",
      key: "name",
      width: "20%",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "name" && sortedInfo?.order,
      ellipsis: true,
      editable: false,
    },
    {
      title: () => {
        return (
          <div align="top" className="top">
            <div className="special-table-header-text">Position</div>
            <div>{searchInput("position")}</div>
          </div>
        );
      },
      dataIndex: "position",
      key: "position",
      // sorter: (a, b) => a.position.localeCompare(b.position),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "position" && sortedInfo?.order,
      ellipsis: true,
      width: "20%",
      editable: false,
    },
    {
      title: () => {
        return (
          <div align="top" className="top">
            <div className="special-table-header-text">Email Address</div>
            <div>{searchInput("email")}</div>
          </div>
        );
      },
      dataIndex: "email",
      key: "email",
      width: "25%",
      // sorter: (a, b) => a.email.localeCompare(b.email),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "email" && sortedInfo?.order,
      ellipsis: true,
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record.id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <SaveIcon className="custom-icon" onClick={() => save(record.id)} />
          )
        ) : (
          <EditIcon
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
            className="custom-icon"
          />
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        // inputType: col.dataIndex === "age" ? "number" : "text",
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          className="special-table department-emails-table"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: true }}
          loading={loading}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          onChange={handleChange}
        />
      </Form>
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </>
  );
};

export default DepartmentEmailsTable;
